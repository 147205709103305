import { Component } from '@angular/core';
import 'moment/locale/id';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { MarketingSupportRestService } from '../../marketing-support-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: 'list.component.html',
  styleUrls: ['./list.component.scss']
})
export class CustomDesignReportListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.column.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.customDesign.entity.programTitle',
      field: 'programTitle'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picName',
      field: 'picName'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picPhone',
      field: 'picPhone'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picEmail',
      field: 'picEmail'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designType',
      field: 'designType'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designSize',
      field: 'designSize'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designContent',
      field: 'designContent'
    },
    {
      i18nLabel: 'ui.customDesign.entity.file',
      field: '',
      name: 'file'
    },
    {
      i18nLabel: 'ui.customDesign.entity.link',
      field: '',
      name: 'link'
    },
    {
      i18nLabel: 'ui.customDesign.entity.maxRevise',
      field: 'maxRevise'
    },
    {
      i18nLabel: 'ui.customDesign.entity.status',
      field: '',
      name: 'status'
    }
  ];

  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  formDialogReject: FormGroup;
  showDialogReject = false;

  formDialogComplete: FormGroup;
  showDialogComplete = false;

  formDialogRevise: FormGroup;
  isShowDialogRevise = false;

  selectedRecord: any = null;
  isShowDialogResult = false;

  fileData: any = {
    previewBase64Image: null,
    name: null,
    size: null
  };

  // prettier-ignore
  optionsDesignType: any[] = [
    { value: 'Flyer', id: 'flyer', label: 'Flyer' },
    { value: 'Poster', id: 'poster', label: 'Poster' },
    { value: 'Spanduk', id: 'spanduk', label: 'Spanduk' },
    { value: 'X-Banner/Roll-Up Banner', id: 'x-banner', label: 'X-Banner/Roll-Up Banner' },
    { value: 'Baligho', id: 'baligho', label: 'Baligho' },
    { value: 'Giant Banner', id: 'giant-banner', label: 'Giant Banner' },
    { value: 'Backdrop', id: 'backdrop', label: 'Backdrop' },
    { value: 'Interior', id: 'interior', label: 'Interior' },
    { value: 'Exterior', id: 'exterior', label: 'Exterior' },
    { value: 'Digital Socmed', id: 'digital-socmed', label: 'Digital Socmed' },
    { value: 'Shop Bill', id: 'shop-bill', label: 'Shop Bill' }
  ];

  breadcrumbs: { label: string; url: string }[];

  constructor(private _RestService: MarketingSupportRestService) {
    super();
    this.componentId = 'CustomDesignReportListComponent';
    this.headerTitle = 'ui.customDesignForm.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';

    this.headerButtons = [];

    this.breadcrumbs = [
      {
        label: 'ui.customDesignForm.list.title',
        url: '/product'
      }
    ];

    this.createForm();
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }

        return this._RestService.findAllCustomDesignReport(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map((d: any) => {
              d.designType = d.designType.join(', ');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'CustomDesignReportListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  createForm() {
    this.formDialogReject = new FormGroup({
      id: new FormControl(''),
      reviseNote: new FormControl(''),
      isApproved: new FormControl(''),
      rejectNote: new FormControl('')
    });

    this.formDialogComplete = new FormGroup({
      id: new FormControl('', [Validators.required]),
      resultLink: new FormControl('', [Validators.required])
    });
  }

  addNumber(number: number) {
    return number + 1;
  }

  actionApprove(id: string) {
    this.isShowDialogRevise = false;

    Swal.fire({
      title: this.comp._translate.instant(
        'ui.customDesignReport.list.confirm.approve'
      ),
      text: this.comp._translate.instant(
        'ui.customDesignReport.list.confirmDesc.approve'
      ),
      icon: 'question',
      showCancelButton: true
    }).then(async result => {
      if (result.value) {
        this.reviewCustomDesign(id, true);
      } else {
        return;
      }
    });
  }

  actionReject(id: string) {
    this.formDialogReject.controls['id'].setValue(id);
    this.showDialogReject = true;
    this.isShowDialogRevise = false;
  }

  saveDialogReject() {
    Swal.fire({
      title: this.comp._translate.instant(
        'ui.customDesignReport.list.confirm.reject'
      ),
      text: this.comp._translate.instant(
        'ui.customDesignReport.list.confirmDesc.reject'
      ),
      icon: 'question',
      showCancelButton: true
    }).then(async result => {
      if (result.value) {
        this.reviewCustomDesign(
          this.formDialogReject.value.id,
          false,
          this.formDialogReject.value.rejectNote
        );
      } else {
        return;
      }
    });
  }

  showDialogRevise(data: any) {
    this.isShowDialogRevise = true;
    this.selectedRecord = data;
  }

  actionComplete(id: string) {
    this.formDialogComplete.controls['id'].setValue(id);
    this.showDialogComplete = true;
  }

  saveDialogComplete(id: string) {
    Swal.fire({
      title: this.comp._translate.instant(
        'ui.customDesignReport.list.confirm.done'
      ),
      text: this.comp._translate.instant(
        'ui.customDesignReport.list.confirmDesc.done'
      ),
      icon: 'question',
      showCancelButton: true
    }).then(async result => {
      if (result.value) {
        this.completeCustomDesign(this.formDialogComplete.value);
      } else {
        return;
      }
    });
  }

  showDialogResult(data: any) {
    console.log(data);
    this.isShowDialogResult = true;
    this.selectedRecord = data;
  }

  async reviewCustomDesign(
    id: string,
    isApprove: boolean,
    rejectNote?: string
  ) {
    await this._RestService
      .reviewCustomDesign({
        id: id,
        is_approve: isApprove,
        reject_note: rejectNote
      })
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              isApprove
                ? 'ui.customDesignReport.list.successMessage.approve'
                : 'ui.customDesignReport.list.successMessage.reject'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.showDialogReject = false;
          this.formDialogReject.reset();
          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant(
            isApprove
              ? 'ui.customDesignReport.list.errorMessage.approve'
              : 'ui.customDesignReport.list.errorMessage.reject'
          ),
          type: 'error',
          scroll: true
        });
      });
  }

  async completeCustomDesign(payload: any) {
    await this._RestService
      .completeCustomDesign({
        id: payload.id,
        result_link: payload.resultLink
      })
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'ui.customDesignReport.list.successMessage.done'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.showDialogComplete = false;
          this.formDialogComplete.reset();
          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant(
            'ui.customDesignReport.list.errorMessage.done'
          ),
          type: 'error',
          scroll: true
        });
      });
  }

  openFile(file: any) {
    const payload = { storageFileName: file?.storageFileName };
    this._RestService.getPresignedUrl(payload).subscribe(data => {
      window.open(data.url, '_blank');
    });
  }

  closeDialogReject() {
    this.showDialogReject = false;
    this.formDialogReject.reset();
  }

  getStatusColor(status: string) {
    if (status == 'submitted') return 'tosca';
    if (status == 'on_progress') return 'green';
    if (status == 'request_revised') return 'orange';
    if (status == 'rejected') return 'red';
    if (status == 'done') return 'blue';
    if (status == 'closed') return 'gray';
  }
}
