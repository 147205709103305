import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class ProductRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/products`;
  baseFileUrl = `${APP_CONST.API_FILE}`;

  request = this._request.new(this.baseURL);
  requestFile = this._request.new(this.baseFileUrl);

  constructor(private _request: RequestService) {}
  findAll(queryParams: any = {}) {
    return this.request.get<any>(``, { params: queryParams });
  }

  createProduct(payload: any) {
    return this.request.post<any>(`/`, payload);
  }

  deleteProduct(productId: string) {
    return this.request.delete<any>(`${productId}`);
  }

  loadProduct(productId: string) {
    return this.request.get<any>(`${productId}`);
  }

  updateProduct(productId: string, payload: any) {
    return this.request.put<any>(`${productId}`, payload);
  }

  updateAllVariantProduct(productId: string, payload: any) {
    return this.request.put<any>(`${productId}/all-variant`, payload);
  }

  getProductFeature(productId: string) {
    return this.request.get<any>(`${productId}/feature`);
  }

  getProductDetail(productId: string) {
    return this.request.get<any>(`${productId}/detail`);
  }

  createProductFeature(productId: string, payload: any) {
    return this.request.post<any>(`${productId}/feature`, payload);
  }

  deleteProductFeature(productId: string, featureId: string) {
    return this.request.delete<any>(`${productId}/feature/${featureId}`);
  }

  createProductDetail(productId: string, payload: any) {
    return this.request.post<any>(`${productId}/detail`, payload);
  }

  deleteProductDetail(productId: string, detailId: string) {
    return this.request.delete<any>(`${productId}/detail/${detailId}`);
  }

  findAllVariant(productId: string, queryParams: any = {}) {
    return this.request.get<any>(`${productId}/variant`, {
      params: queryParams
    });
  }

  findVariantById(productId: string, variantId: string, queryParams: any = {}) {
    return this.request.get<any>(`${productId}/variant/${variantId}`, {
      params: queryParams
    });
  }

  createProductVariant(productId: string, payload: any) {
    return this.request.post<any>(`${productId}/variant`, payload);
  }

  getProductVariant(productId: string, variantId: string) {
    return this.request.get<any>(`${productId}/variant/${variantId}`);
  }

  updateProductVariant(productId: string, payload: any, variantId: string) {
    return this.request.put<any>(`${productId}/variant/${variantId}`, payload);
  }

  deleteProductVariant(productId: string, variantId: string) {
    return this.request.delete<any>(`${productId}/variant/${variantId}`);
  }

  findAllPrice(productId: string, variantId: string, queryParams: any = {}) {
    return this.request.get<any>(`${productId}/variant/${variantId}/price`, {
      params: queryParams
    });
  }

  createProductPrice(productId: string, variantId: string, payload: any) {
    return this.request.post<any>(`${productId}/variant/${variantId}/price`, payload);
  }

  updateProductPrice(productId: string, variantId: string, priceId: string, payload: any) {
    return this.request.put<any>(`${productId}/variant/${variantId}/price/${priceId}`, payload);
  }

  deleteProductPrice(productId: string, variantId: string, priceId: string) {
    return this.request.delete<any>(`${productId}/variant/${variantId}/price/${priceId}`);
  }

  createProductPhoto(productId: string, variantId: string, payload: any) {
    return this.request.post<any>(`${productId}/variant/${variantId}/photo`, payload);
  }

  deleteProductPhoto(productId: string, variantId: string, photoId: string) {
    return this.request.delete<any>(`${productId}/variant/${variantId}/photo/${photoId}`);
  }

  getAllProductsRegion(productId: string) {
    return this.request.get<any>(`/${productId}/regions`);
  }

  updateAllProductPriceData(variantId: string, payload: any) {
    return this.request.patch<any>(`${variantId}/variant-price-by-region`, payload);
  }

  updateProductFeature(productId: string, featureId: string, payload) {
    return this.request.patch(`${productId}/feature/${featureId}`, payload);
  }

  updateProductDetail(productId: string, detailId: string, payload) {
    return this.request.patch(`${productId}/detail/${detailId}`, payload);
  }

  highlightPrice(productId: string, variantId: string, priceId: string, payload: any) {
    return this.request.patch<any>(`${productId}/variant/${variantId}/price/${priceId}/highlight`, payload);
  }

  getAllRegionFromProductVariant() {
    return this.request.get<any>('regions/all');
  }

  updateAllProductVariantsRegionDealer(variantRegion: string, payload) {
    return this.request.patch<any>(`all/variant/${variantRegion}/region-dealer`, payload);
  }

  getProductType() {
    return this.request.get<any>(`/admin/product-type`, {
      baseURL: APP_CONST.API_MASTER
    });
  }

  bulkUploadPrice(productId: number, payload: any) {
    return this.request.post<any>(`${productId}/all-variant/upload-excel`, payload);
  }
}
