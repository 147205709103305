import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/libraries.module';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-detail-unit-modal',
  templateUrl: 'detail-unit-modal.component.html',
  styleUrls: ['../spk-lite-to-reguler-component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class DetailUnitModal {
  @Input() spk: any;

  constructor(
    public _spkListComponent: SPKListComponent
  ) {}

  formEditShippingAddress: FormGroup;

  isOpen: boolean = false;
  isEditingShipingAddress: boolean = false;
  loadingEditShippingAddress: boolean = false;

  isEditingUnits: boolean = false;

  initFormEditShippingAddress() {
    this.formEditShippingAddress = new FormGroup({
      shippingAddress: new FormControl('', [Validators.required]),
    });
  }

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
    this.onCloseUpdateUnits();
    this.onCloseUpdateShippingAddress();
  }

  onUpdateUnits() {
    this.isEditingUnits = true;
  }

  onCloseUpdateUnits() {
    this.isEditingUnits = false;
  }

  onUpdateShippingAddress() {
    this.formEditShippingAddress.patchValue({ shippingAddress: this.spk?.shippingAddress });
    this.isEditingShipingAddress = true;
  }

  onCloseUpdateShippingAddress() {
    this.formEditShippingAddress.reset();
    this.isEditingShipingAddress = false;
  }

  editShippingAddress() {
    const payload = {
      spkId: this.spk?._id,
      shippingAddress: this.formEditShippingAddress.value.shippingAddress
    };

    if (!this.formEditShippingAddress.valid) {
      CommonService.markAsDirty(this.formEditShippingAddress);
    } else {
      this.loadingEditShippingAddress = true;
      this._spkListComponent._SPKRest
        .updateShippingAddress(payload)
        .toPromise()
        .then((response: any) => {
          this._spkListComponent.callHookDirectly('init');
          Swal.fire(
            'Update Shipping Address Successfully!',
            '',
            'success'
          )
        })
        .catch(error => {
          const errorData = { ...error };
          Swal.fire(
            'Gagal',
            errorData?.response?.data?.message,
            'error'
          )
        })
        .finally (() => {
          this.loadingEditShippingAddress = false;
          this.onCloseUpdateShippingAddress();
        })
    }
  }

  ngOnInit(): void {
    this.initFormEditShippingAddress();
  }
}
