import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { MFormgenSchemaField } from '../../../libraries/formgen/formgen.model';
import { RoleRestService } from './../../role/role-rest.service';
import { DealerRestService } from '../../dealer/dealer-rest.service';

@Component({
  selector: 'app-user-form',
  templateUrl: 'user-form.component.html'
})
export class UserFormComponent extends BaseFormBComponent {
  constructor(
    private _roleRest: RoleRestService,
    public _dealerRest: DealerRestService
  ) {
    super();

    this.componentId = 'UserFormComponent';
  }

  showDealer: boolean = false;

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.user.entity.name',
            name: 'fullName',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            }
          },
          {
            label: 'ui.user.entity.email',
            name: 'email',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            },
            templateConfig: {
              type: 'email'
            }
          },
          {
            label: 'ui.user.entity.role',
            name: 'role',
            field: 'autocomplete',
            validations: {
              required: this.formType === 'create' || this.formType === 'update'
            },
            templateConfig: {
              autocomplete: {
                dropdown: true,
                field: 'name',
                key: '_id',
                remoteParams: (event, type) => {
                  return [
                    {
                      take: 30,
                      skip: 0,
                      keyword: type === 'search' ? event.query : null
                    },
                    {}
                  ];
                },
                remoteRequest: this._roleRest.findAll.bind(this._roleRest),
                remoteResponseTransform: response => response.data
              }
            },
            fieldEvents: {
              onSelect: (
                value: any,
                field: MFormgenSchemaField,
                form: FormGroup
              ) => {
                form.patchValue({
                  roleId: value._id
                });

                if (value.name.toLowerCase() == 'dealership') {
                  form.get('dealerId')?.setValidators([Validators.required]);
                  this.showDealer = true;
                } else {
                  form.patchValue({ dealerId: null })
                  form.get('dealerId')?.clearValidators();
                  this.showDealer = false;
                }

                // Update the validity of the 'dealerId' control
                form.get('dealerId')?.updateValueAndValidity();
              }
            }
          },
          {
            label: 'ui.user.entity.dealer',
            name: 'dealerId',
            field: 'select',
            validations: {
              // required: this.formType === 'create' || this.formType === 'update'
            },
            templateConfig: {
              select: {
                options: []
              },
            },
            fieldEvents: {
              onInit: (value, field: any) => {
                this._dealerRest.findAll().subscribe((response) => {
                  const options = response.data.map((item) => ({
                    value: item._id,
                    directLabel: `${item.name} - (${item.email})`,
                  })).sort((a, b) => (a.directLabel < b.directLabel ? -1 : a.directLabel > b.directLabel ? 1 : 0));
                  field.templateConfig.select.options = options;
                });
              },
              onChange: (
                value: any,
                field: MFormgenSchemaField,
                form: FormGroup
              ) => {
                form.patchValue({
                  dealerId: value
                });
              }
            }
          },
          {
            name: 'roleId',
            ignore: true
          },
          {
            name: 'dealer',
            ignore: true
          },
          {
            label: 'ui.user.entity.password',
            name: 'password',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'reset'
            },
            templateConfig: {
              type: 'password'
            }
          },
          {
            label: 'ui.user.entity.passwordConfirmation',
            name: 'confirmPassword',
            field: 'text',
            validations: {
              required: this.formType === 'create' || this.formType === 'reset'
            },
            templateConfig: {
              type: 'password'
            },
            syncValidators: this.matchPasswords()
          }
        ];
      }
    });
  }

  matchPasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (this.form.get('password').value !== control.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }
}
