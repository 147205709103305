import { Component, Input } from '@angular/core';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../spk-component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class StatusInfoModal {

  constructor(
    public _spkListComponent: SPKListComponent
  ){}

  isOpen: boolean = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
