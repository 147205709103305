import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-product-list',
  templateUrl: 'product-list.component.html'
})
export class ProductMotorcycleListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.nameProduct',
      field: 'name'
    },
    {
      i18nLabel: 'ui.product.entity.typeProduct',
      field: 'type',
      sort: false
    },
    {
      i18nLabel: 'ui.product.entity.status',
      name: 'isPublish',
      field: ''
    },
    {
      i18nLabel: 'ui.product.entity.updateAt',
      field: 'updatedAt'
    }
  ];
  productTypeOptions = [];

  bundleOption = [
    {
      text: 'Semua Bundle',
      value: ''
    },
    {
      text: 'Lite',
      value: 'lite'
    },
    {
      text: 'Reguler',
      value: 'reguler'
    },
    {
      text: 'S',
      value: 's'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  formModalCreate: FormGroup;
  breadcrumbs: { label: string; url: string }[];
  typeSparepart: boolean = false;
  productType: { text: string; value: string }[];

  constructor(private _ProductRest: ProductRestService) {
    super();
    this.createFormProduct();
    this.componentId = 'ProductMotorcycleListComponent';
    this.headerTitle = 'ui.product.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.qParams.motorType = '';
    this.qParams.bundle = '';
    this.qParams.isPublish = '';
    this.productType = [
      {
        text: 'Semua Tipe Produk',
        value: ''
      },
      {
        text: 'Motorcycle',
        value: 'motorcycle'
      },
      {
        text: 'Sparepart',
        value: 'sparepart'
      }
    ];

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.product.list.button.createProduct',
        color: 'primary-outline',
        onClick: () => this.openDialogCreate()
      }
    ];

    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/product'
      }
    ];

    this.qParams.type = 'motorcycle';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }

        this._ProductRest.getProductType().subscribe(response => {
          this.productTypeOptions = response.data;
        });

        return this._ProductRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data
              ?.map(d => {
                d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY');
                return d;
              })
              .sort((a, b) => a.name.localeCompare(b.name));
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductMotorcycleListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  createFormProduct() {
    this.formModalCreate = new FormGroup({
      code: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      type: new FormControl('motorcycle', [Validators.required]),
      title: new FormControl(''),
      caption: new FormControl(''),
      isPublish: new FormControl(true)
    });
  }

  setProductCode(event) {
    const valueFormatted = event.target.value.toLowerCase();
    const codeProduct = valueFormatted.replace(/\s+/g, '-');
    this.formModalCreate.controls['code'].setValue(codeProduct);
  }

  getSelect(event) {
    const typeSelect = event.target.value;
    if (typeSelect === 'sparepart') {
      this.typeSparepart = true;
    } else {
      this.typeSparepart = false;
    }
  }

  actionDeleteProduct(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteProductData(value);
      } else {
        return;
      }
    });
  }

  async createProductData(value) {
    const result = await this._ProductRest
      .createProduct(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.create'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductData(value) {
    const result = await this._ProductRest
      .deleteProduct(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.delete'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    this.createProductData(formValue);
    this.showModalCreate = false;
    this.formModalCreate.reset();
    this.callHookDirectly('init');
  }

  closeModalCreate() {
    this.typeSparepart = false;
    this.showModalCreate = false;
    this.formModalCreate.reset();
    this.formModalCreate.controls['type'].setValue('motorcycle');
    this.formModalCreate.controls['isPublish'].setValue(true);
  }
}
