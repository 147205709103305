import { Component, ViewEncapsulation } from '@angular/core';
import { AdminLayoutService } from './admin-layout.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: 'admin-layout.component.html',
  styleUrls: ['admin-layout.component.scss']
})
export class AdminLayoutComponent {
  constructor(
    private _adminLayout: AdminLayoutService
  ) { }

  showSidebar: boolean = true;

  layout() {
    return [
      this._adminLayout.sidebar.visible ? 'sidebar-visible' : '',
      this._adminLayout.sidebar.offcanvas ? 'sidebar-offcanvas' : '',
      this._adminLayout.sidebar.offcanvasVisible ? 'offcanvas-visible' : ''
    ].join(' ');
  }

  closeSidebar() {
    this._adminLayout.sidebar.visible = false;
  }

  isShowSidebar() {
    return !this.showSidebar ? 'sidebar-hide' : '';
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }
}
