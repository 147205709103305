import { NgModule } from '@angular/core';
import { BaseModule } from './base/base.module';
import { FieldModule } from './field/field.module';
import { FieldsetAccordionModule } from './fieldset-accordion/fieldset-accordion.module';
import { FieldsetCollapsibleModule } from './fieldset-collapsible/fieldset-collapsible.module';
import { FormgenModule } from './formgen/formgen.module';
import { GridTableModule } from './grid-table/grid-table.module';
import { IdleGuardModule } from './idle-guard/idle-guard.module';
import { ImgUploadModule } from './img-upload/img-upload.module';
import { RetryDialogModule } from './retry-dialog/retry-dialog.module';
import { SpinnerModule } from './spinner/spinner.module';
import { SystemMessageDisplayModule } from './system-message-display/system-message-display.module';
import { TextareaAutosizeModule } from './textarea-autosize/textarea-autosize.module';
import { ViewUtilityModule } from './view-utility/view-utility.module';
import { WrapperAutocompleteModule } from './wrapper-autocomplete/wrapper-autocomplete.module';

@NgModule({
  exports: [
    BaseModule,
    FieldModule,
    FormgenModule,
    IdleGuardModule,
    ImgUploadModule,
    SpinnerModule,
    FieldsetAccordionModule,
    FieldsetCollapsibleModule,
    GridTableModule,
    RetryDialogModule,
    SystemMessageDisplayModule,
    TextareaAutosizeModule,
    ViewUtilityModule,
    WrapperAutocompleteModule
  ]
})
export class SharedModule {}
