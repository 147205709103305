// user-modal.component.ts
import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SPKRestService } from '../spk-battery-rest.service';
import { CommonService } from '../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-create-modal',
  templateUrl: 'create-modal.component.html'
})
export class CreateModalComponent {
  formModalCreate: FormGroup;
  invoiceImageBase64: any;
  selectedFileName: string;
  fileSize: any;
  showModalCreate: boolean = false;
  loadingCreate: boolean = false;
  batteryList: any[] = [];
  dealerItems: any[] = [];
  batteryTypes = [
    {
      title: 'Online',
      value: 'online'
    },
    {
      title: 'Offline',
      value: 'offline'
    },
  ];

  constructor(
    private _SPKRest: SPKRestService,
    public spkListComponent: SPKListComponent
  ) {}

  ngOnInit(): void {
    this.initFormSPK();
  }

  async getBatteries() {
    await this._SPKRest.getBatteries().subscribe((data: any) => {
      console.log(data)
      this.batteryList = data;
    });
  }

  getDealers() {
    this._SPKRest.getDealers().subscribe((data: any) => {
      this.dealerItems = data;
    });
  }

  onChangeRegion(e) {
    const dealerId = e.target.value;
    this.formModalCreate.patchValue({ dealerId });
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const isPdf = event.target.files[0]?.type === 'application/pdf';
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';

      this.formModalCreate.patchValue({
        invoice: { file }
      });

      if (isPdf) {
        this.invoiceImageBase64 = 'pdf';
        return;
      }

      reader.onload = () => {
        this.invoiceImageBase64 = reader.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  clearFile(event: Event) {
    event.preventDefault();
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formModalCreate.patchValue({
      invoice: { file: null }
    });
  }

  resetFileImg() {
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  async createSPK() {
    const values = this.formModalCreate.value;
    const payload = new FormData();

    Object.keys(values).forEach(key => {
      if (key === 'invoice') {
        payload.append('invNumber', values[key]?.invNumber);
        payload.append('invFile', values[key]?.file);
      } else if (key === 'batteries') {
        payload.append(key, JSON.stringify(values[key]))
      } else {
        payload.append(key, values[key]);
      }
    })

    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
    } else {
      this.loadingCreate = true;
      await this._SPKRest
      .createSPK(payload)
      .toPromise()
      .then((response: any) => {
        if (!!response?.invalid) {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid Battery Stock',
            html: response?.invalidStockBattery?.map(item => `
            <div class="text-left">
                <div>Battery Type: ${item.batteryType}</div>
                <div>Total: ${item.total}</div>
                <div>Current Stock: ${item.currentStock}</div>
                <hr/>
            </div>
            `).join(''),
            confirmButtonText: 'OK'
          });
        } else {
          if (response) {
            Swal.fire(
              'Create SPK successfully!',
              '',
              'success'
            )

            this.spkListComponent.callHookDirectly('init');
            this.openCreateSPKModal(false);
          }
        }
      })
      .catch(error => {
        const errorData = { ...error };
        Swal.fire(
          'Gagal',
          errorData?.response?.data?.message,
          'error'
        )
      });
      this.loadingCreate = false;
    }
  }

  initFormSPK() {
    this.formModalCreate = new FormGroup({
      dealerId: new FormControl('', [Validators.required]),
      shippingAddress: new FormControl('', [Validators.required]),
      invoice: new FormGroup({
        invNumber: new FormControl(''),
        file: new FormControl(''),
      }, { validators: this.invoiceValidation }),
      batteries: new FormArray([
        this.createBatteryFormGroup()
      ])
    });
  }

  stockValidator(initialStock: number) {
    return (control: FormControl): { [key: string]: any } | null => {
      const currentStock = control.value;
      if (currentStock > initialStock) {
        return { 'invalidStock': true };
      }
      return null;
    };
  }

  onBatterySelected(battery, index) {
    const batteryId = battery?.value?.batteryId;
    const selectedBattery = this.batteryList?.find(item => item._id == batteryId);
    const selectedBatteryStock = selectedBattery?.stock;
    const batteryType = selectedBattery?.batteryType;

    const batteryFormGroup = this.formModalCreate?.get('batteries')?.get(index.toString()) as FormGroup;
    const totalFormControl = batteryFormGroup.get('total');

    // Set battery type
    batteryFormGroup.get('batteryType')?.setValue(batteryType);

    // Update the initialStock property in the form control
    totalFormControl?.setValidators([Validators.required, this.stockValidator(selectedBatteryStock)]);
    totalFormControl?.updateValueAndValidity();

    console.log(this.formModalCreate.value)
  }

  createBatteryFormGroup(): FormGroup {
    return new FormGroup({
      chassisNumber: new FormControl(''),
      batteryType: new FormControl('', [Validators.required]),
      total: new FormControl('', [Validators.required, this.stockValidator(0)]),
      description: new FormControl(''),
      batteryId: new FormControl('', [Validators.required])
    });
  }

  addBattery() {
    const batteries = this.formModalCreate.get('batteries') as FormArray;
    batteries.push(this.createBatteryFormGroup());
  }

  resetBatteries() {
    const newBatteriesArray = new FormArray([
      this.createBatteryFormGroup()
    ]);
    this.formModalCreate.setControl('batteries', newBatteriesArray);
  }

  removeBattery(index: number) {
    const batteries = this.formModalCreate.get('batteries') as FormArray;
    batteries.removeAt(index);
  }

  get batteriesControls() {
    return (this.formModalCreate.get('batteries') as FormGroup).controls;
  }

  invoiceValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const invNumberControl = control.get('invNumber')?.value;
    const fileControl = control.get('file')?.value;

    if ((fileControl && !invNumberControl) || (!fileControl && invNumberControl)) {
      return { requiredField: true };
    }

    return null;
  }

  openCreateSPKModal(val) {
    this.getBatteries();
    this.getDealers();
    this.showModalCreate = val;
    this.formModalCreate.reset();
    this.resetBatteries();
    this.resetFileImg();
  }
}
