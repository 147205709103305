import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PartnershipGentariRestService } from '../partnership-gentari-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';

@Component({
  selector: 'app-partnership-gentari-list',
  templateUrl: 'partnership-gentari-list.component.html'
})
export class PartnershipGentariListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.partnershipGentari.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.partnershipGentari.entity.motorcycleNumber',
      field: 'motorcycleNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipGentari.entity.gpPointStatus',
      field: '',
      name: 'gpPointStatus',
      sort: false
    },
    {
      i18nLabel: 'ui.partnershipGentari.entity.gpPointNominal',
      field: 'gpPointNominal',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipGentari.entity.feedback',
      field: '',
      name: 'feedback',
      sort: false
    },
    {
      i18nLabel: 'ui.partnershipGentari.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showDialogFeedback: boolean = false;
  showDialogFeedbackData: object = {};
  isLoadingExportExcel: boolean = false;

  constructor(
    public _authentication: AuthenticationService,
    private _RestService: PartnershipGentariRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'PartnershipGentariListComponent';
    this.headerTitle = 'ui.partnershipGentari.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._RestService.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'PartnershipGentariListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  openDialogFeedback(data: any) {
    this.showDialogFeedback = true;
    this.showDialogFeedbackData = data;
  }

  closeDialogFeedback() {
    this.showDialogFeedback = false;
    this.showDialogFeedbackData = {};
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._RestService.findAll({}).toPromise();

      // prettier-ignore
      const records = result.data.map((item: any) => ({
        ...item,
        question1: item.questionAndAnswer ? item.questionAndAnswer[0].question:'',
        answer1: item.questionAndAnswer ? item.questionAndAnswer[0].answer.join(", ") :'',
        question2: item.questionAndAnswer ? item.questionAndAnswer[1].question:'',
        answer2: item.questionAndAnswer ? item.questionAndAnswer[1].answer.join(", ") :'',
        question3: item.questionAndAnswer ? item.questionAndAnswer[2].question:'',
        answer3: item.questionAndAnswer ? item.questionAndAnswer[2].answer :'',
        gpPointStatus: item.gpPointStatus ?this.comp._translate.instant(
          'ui.partnershipGentari.statusLabel.gpPointStatusTrue'
        ):this.comp._translate.instant(
          'ui.partnershipGentari.statusLabel.gpPointStatusFalse'
        ),
        gpPointNominal: item.gpPointNominal.toString(),
        updatedAt: moment(item?.updatedAt).format('DD MMMM YYYY HH:mm')
      }));

      const mapOption = {
        motorcycleNumber: {
          header: this.comp._translate.instant(
            'ui.partnershipGentari.entity.motorcycleNumber'
          )
        },
        gpPointStatus: {
          header: this.comp._translate.instant(
            'ui.partnershipGentari.entity.gpPointStatus'
          )
        },
        gpPointNominal: {
          header: this.comp._translate.instant(
            'ui.partnershipGentari.entity.gpPointNominal'
          )
        },
        question1: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.question'
            ) + ' 1'
        },
        answer1: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.answer'
            ) + ' 1'
        },
        question2: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.question'
            ) + ' 2'
        },
        answer2: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.answer'
            ) + ' 2'
        },
        question3: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.question'
            ) + ' 3'
        },
        answer3: {
          header:
            this.comp._translate.instant(
              'ui.partnershipGentari.additionalEntity.answer'
            ) + ' 3'
        },
        updatedAt: {
          header: this.comp._translate.instant(
            'ui.partnershipGentari.entity.updatedAt'
          )
        }
      };

      this._export.export({
        extension: 'xlsx',
        fileName: 'PartnershipGentari',
        records: records,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
