import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { BuybackListComponent } from './list/buyback-list.component';
import { BuybackRestService } from './buyback-rest.service';
import { BuybackRoutingModule } from './buyback-routing.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { ReviewResultModalComponent } from './review-result-modal/review-result-modal.component';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';
import { StatusInfoModal } from './status-info-modal/status-info-modal.component';
import { ChipComponent } from './components/chip/chip.component';
import { FinanceReviewModalComponent } from './finance-review-modal/finance-review-modal.component';
import { CommissionModalComponent } from './commission-modal/commission-modal.component';
import { AppraisalDocModalComponent } from './appraisal-doc-modal/appraisal-doc-modal.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { StatusComponent } from './components/status/status.component';
import { SubmissionForm } from './submission-form-modal/submission-form.component';
import { DocumentSubmission } from './document-submission-modal/document-submission.component';
import { AssessmentCardComponent } from './components/assessment-card/assessment.component';
import { EditSubmissionForm } from './edit-submission-form-modal/edit-submission-form.component';
import { AssessmentCardEditComponent } from './components/assessment-card-edit/assessment-edit.component';
import { AssessmentCardViewComponent } from './components/assessment-card-view/assessment-view.component';

export const PROVIDERS = [BuybackRestService];

@NgModule({
  imports: [
    BuybackRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    ButtonModule,
    DialogModule
  ],
  declarations: [
    BuybackListComponent,
    ModalComponent,
    AccordionComponent,
    ChipComponent,
    ReviewResultModalComponent,
    ChangeStatusModalComponent,
    StatusInfoModal,
    FinanceReviewModalComponent,
    CommissionModalComponent,
    AppraisalDocModalComponent,
    StatusComponent,
    SubmissionForm,
    DocumentSubmission,
    AssessmentCardComponent,
    EditSubmissionForm,
    AssessmentCardEditComponent,
    AssessmentCardViewComponent
  ]
})
export class BuybackModule {}
