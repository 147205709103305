import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { AdminLayoutService } from '../admin-layout.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-admin-layout-sidebar',
  templateUrl: 'admin-layout-sidebar.component.html',
  styleUrls: ['admin-layout-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutSidebarComponent implements OnInit {
  menu: any[];
  router: Router;
  sidebarOffcanvasVisible: boolean;
  svgSource: SafeHtml[];
  svgIconHelp: SafeHtml;

  constructor(
    public _authentication: AuthenticationService,
    public _adminLayout: AdminLayoutService,
    private _injector: Injector,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.menu = _adminLayout.menuItems;
  }

  ngOnInit() {
    this.router = this._injector.get(Router);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this._adminLayout.sidebar.visible = false;
      });

    // this.loadSvgSources();

    // load svg icon
    const svgUrl = '../../../../assets/img/icons/icon-help.svg';
    this.http.get(svgUrl, { responseType: 'text' }).subscribe(svg => {
      this.svgIconHelp = this.sanitizer.bypassSecurityTrustHtml(svg);
    });

    // Assign SVG icons to menu items
    this.menu.forEach(item => {
      this.loadSvgIcon(item);
    });
  }

  loadSvgIcon(item: any) {
    this.http.get(item.imgpath, { responseType: 'text' }).subscribe(svg => {
      item.svgSource = this.sanitizer.bypassSecurityTrustHtml(svg);
    });
  }

  closeSidebar() {
    this._adminLayout.sidebar.visible = false;
  }

  handleSidebar(event) {
    if (this.isSidebarCollapsed()) {
      const item = this.getItemElement(event);
      // check click is on a tag
      if (!item) {
        return;
      }

      const ele = $(item),
        liparent = ele.parent()[0];

      const lis = ele
        .parent()
        .parent()
        .children(); // markup: ul > li > a
      // remove .active from childs
      lis.find('li').removeClass('active');
      // remove .active from siblings ()
      $.each(lis, function(key, li) {
        if (li !== liparent) {
          $(li).removeClass('active');
        }
      });

      const next = ele.next();
      if (next.length && next[0].tagName === 'UL') {
        ele.parent().toggleClass('active');
        event.preventDefault();
      }
    } else {
      this.toggleSubmenuHover(event);
    }
  }

  // find the a element in click context
  // doesn't check deeply, asumens two levels only
  getItemElement(event) {
    const element = event.target,
      parent = element.parentNode;
    if (element.tagName.toLowerCase() === 'a') {
      return element;
    }
    if (parent.tagName.toLowerCase() === 'a') {
      return parent;
    }
    if (parent.parentNode.tagName.toLowerCase() === 'a') {
      return parent.parentNode;
    }
  }

  isSidebarCollapsed() {
    return this._adminLayout.sidebar.offcanvasVisible;
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  toggleSubmenuHover(event, hasChildren?: boolean) {
    const self = this;
    if (!this.isSidebarCollapsed()) {
      event.preventDefault();
      this.removeFloatingNav();

      const target = $(event.target || event.srcElement || event.currentTarget);
      let ul,
        anchor = target;
      // find the UL
      if (!target.is('a')) {
        anchor = target.parent('a');
      }
      ul = anchor.next();

      if (!ul.length) {
        return; // if not submenu return
      }

      const $aside = $('.sidebar-container');
      const $asideInner = $aside.children('.sidebar-content'); // for top offset calculation
      const $sidebar = $asideInner.children('.sidebar-nav');
      const mar =
        parseInt($asideInner.css('padding-top'), 0) +
        parseInt($aside.css('padding-top'), 0);
      const itemTop =
        anchor.parent().position().top + mar - $sidebar.scrollTop();

      const floatingNav = ul.clone().appendTo($aside);
      const vwHeight = $(window).height();

      floatingNav
        .removeClass('active')
        .addClass('nav-floating')
        .css({
          position: 'absolute',
          top: itemTop,
          bottom:
            floatingNav.outerHeight(true) + itemTop > vwHeight ? 0 : 'auto',
          left: '70px',
          zIndex: 99
        });

      floatingNav
        .on('mouseleave', () => {
          floatingNav.remove();
        })
        .find('a')
        .on('click', function(e) {
          e.preventDefault(); // prevents page reload on click
          // get the exact route path to navigate
          self.router.navigate([$(this).attr('route')]);
        });
    } else if (hasChildren) {
      this.handleSidebar(event);
    }
  }
}
