import { Component, Output, SimpleChange } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { PromoRestService } from '../promo-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-promo-update',
  templateUrl: 'promo-update.component.html'
})
export class PromoUpdateComponent extends BaseUpdateBComponent {
  constructor(private _promoRest: PromoRestService) {
    super();
    this.componentId = 'PromoUpdateComponent';
    this.routeURL = '/promo';
    this.entrySuccessI18n = 'success.promo.update';
    this.headerTitle = 'ui.promo.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._promoRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            if (doc.mainImage) {
              doc.mainImage.id = doc?.mainImage?._id;
            }

            return of(doc);
          })
        );
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._promoRest.update(this.comp.routeParams.id, formValue);
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'PromoFormComponent:workflowBuildForm',
          'PromoUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
