import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class DealerRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/dealers`;
  requestRegion = this._request.new(`${APP_CONST.API_MASTER}/public/regions`);
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  create(payload) {
    return this.request.post('/', payload);
  }

  update(id, payload) {
    return this.request.put(`/${id}`, payload);
  }

  delete(id) {
    return this.request.delete(`/${id}`);
  }

  getRegions() {
    return this.requestRegion.get('');
  }

  getUpdatedBy(id) {
    return this.request.get(`/updatedBy/${id}`);
  }

  getDetail(id: string) {
    return this.request.get(`/detail/${id}`);
  }
}
