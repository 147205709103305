import { Component } from '@angular/core';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { UserRestService } from '../user-rest.service';

@Component({
  selector: 'app-user-list',
  templateUrl: 'user-list.component.html'
})
export class UserListComponent extends BaseListBComponent<any> {
  tableColumns: IGridTableColumn[] = [
    {
      i18nLabel: 'ui.user.entity.name',
      field: 'fullName'
    },
    {
      i18nLabel: 'ui.user.entity.email',
      field: 'email'
    },
    {
      i18nLabel: 'ui.user.entity.role',
      field: 'role.name'
    },
    {
      i18nLabel: 'ui.user.entity.dealer',
      field: 'dealer.name'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _userRest: UserRestService) {
    super();
    this.componentId = 'UserListComponent';
    this.headerTitle = 'ui.user.list.title';
    this.exportFileName = 'user';
    this.exportDocumentTitle = 'ui.user.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.user.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.user.list.button.create',
        color: 'primary-outline',
        routerLink: '/users/create',
        permissions: ['user.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._userRest.findAll(qParams);
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const userId = event.data._id;
        return this._userRest.delete(userId);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'UserListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
