import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PoRestService } from '../../po/po-rest.service';
@Component({
  selector: 'app-order-list-summary',
  templateUrl: 'order-list-summary.component.html'
})
export class OrderListSummaryComponent extends BaseListBComponent<any> {
  summaryData: any;
  statusTrx: { text: string; value: string }[];
  isLoading: boolean = true;
  subsidyOption = [
    {
      text: 'Semua Status Subsidi',
      value: ''
    },
    {
      text: 'Subsidy',
      value: true
    },
    {
      text: 'Non-Subsidy',
      value: false
    }
  ];
  paymentStatusOption = [
    {
      text: 'Semua Status Pesanan',
      value: ''
    },
    {
      text: 'Paid',
      value: 'paid'
    },
    {
      text: 'Unpaid',
      value: 'unpaid'
    },
    {
      text: 'Cancel',
      value: 'cancel'
    },
    {
      text: 'Expired',
      value: 'expired'
    },
    {
      text: 'Failed',
      value: 'failed'
    }
  ];

  constructor(private _PoRest: PoRestService) {
    super();
    this.componentId = 'OrderListSummaryComponent';
    this.headerTitle = 'ui.po.list.summaryTitle';
    this.qParams.paymentStatus = '';
    this.qParams.isSubsidy = '';
    this.qParams.endDate = moment().format('YYYY-MM-DD');
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadSingleData',
      handle: () => {
        return this._PoRest.getSummary(this.qParams).pipe();
      },
      success: res => {
        this.summaryData = res;
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'OrderListSummaryComponent:loadSingleData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  onGridChanges() {
    this.callHookDirectly('init');
    this.isLoading = true;
  }

  convertTotal(numberValue) {
    const format = numberValue
      ?.toString()
      .split('')
      .reverse()
      .join('');
    const convert = format.match(/\d{1,3}/g);
    const totalData = convert
      .join('.')
      .split('')
      .reverse()
      .join('');
    return totalData;
  }
}
