import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardListComponent } from './list/dashboard-list.component';
import { DashboardRestService } from './dashboard-rest.service';

export const PROVIDERS = [DashboardRestService];
@NgModule({
  imports: [CommonModule, DashboardRoutingModule, TranslateModule],
  declarations: [DashboardComponent, DashboardListComponent]
})
export class DashboardModule {}
