import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  PRIMARY_OUTLET
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const breadcrumbs = this.buildBreadcrumbs(this.activatedRoute.root);
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private buildBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const { snapshot, children } = route;

    if (snapshot?.routeConfig?.path && snapshot.outlet === PRIMARY_OUTLET) {
      url += `/${snapshot.routeConfig.path}`;

      const breadcrumbLabel = this.getRouteBreadcrumbLabel(snapshot);
      const breadcrumb: Breadcrumb = {
        label: breadcrumbLabel,
        url: url
      };

      breadcrumbs.push(breadcrumb);
    }

    if (children.length === 0 || breadcrumbs.length === 2) {
      return breadcrumbs;
    }

    const child = children.find(c => c.outlet === PRIMARY_OUTLET);

    if (!child) {
      return breadcrumbs;
    }

    return this.buildBreadcrumbs(child, url, breadcrumbs);
  }

  private getRouteBreadcrumbLabel(snapshot: any): string {
    let breadcrumbLabel = snapshot.data?.breadcrumb || '';

    if (!breadcrumbLabel && snapshot.firstChild) {
      breadcrumbLabel = this.getRouteBreadcrumbLabel(snapshot.firstChild);
    }

    if (!breadcrumbLabel && snapshot.routeConfig?.path) {
      breadcrumbLabel = this.convertToLabel(snapshot.routeConfig.path);
    }

    return breadcrumbLabel;
  }

  private isBreadcrumbExists(
    breadcrumbs: Breadcrumb[],
    label: string,
    url: string
  ): boolean {
    return breadcrumbs.some(
      crumb => crumb.label === label && crumb.url === url
    );
  }

  private convertToLabel(path: string): string {
    // Convert the path to a readable label
    // Example: 'user-profile' => 'User Profile'
    const words = path.split('-');
    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  }
}
