import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class MarketingSupportRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/marketing-support`;
  baseFileUrl = `${APP_CONST.API_FILE}`;

  request = this._request.new(this.baseURL);
  requestFile = this._request.new(this.baseFileUrl);

  constructor(private _request: RequestService) {}
  findAllGeneralDesign(queryParams: any = {}) {
    return this.request.get<any>(`/general-design`, { params: queryParams });
  }

  createGeneralDesign(payload: any) {
    return this.request.post<any>(`/general-design`, payload);
  }

  updateGeneralDesign(payload: any) {
    return this.request.put<any>(`/general-design/${payload._id}`, payload);
  }

  deleteGeneralDesign(id: string) {
    return this.request.delete<any>(`/general-design/${id}`);
  }

  findAllCustomDesignRequest(queryParams: any = {}) {
    return this.request.get<any>(`/custom-design/form`, {
      params: queryParams
    });
  }

  findAllCustomDesignReport(queryParams: any = {}) {
    return this.request.get<any>(`/custom-design/report`, {
      params: queryParams
    });
  }

  createCustomDesign(payload: any) {
    return this.request.post<any>(`/custom-design/form`, payload);
  }

  reviseCustomDesign(payload: any) {
    return this.request.patch<any>(`/custom-design/form/revise`, payload);
  }

  reviewCustomDesign(payload: any) {
    return this.request.patch<any>(`/custom-design/report/review`, payload);
  }

  completeCustomDesign(payload: any) {
    return this.request.patch<any>(`/custom-design/report/complete`, payload);
  }

  getPresignedUrl(payload: any) {
    return this.request.post<any>('/pre-signed-url', payload);
  }
}
