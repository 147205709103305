import { Component, ElementRef, Output } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { PoRestService } from '../po-rest.service';
import { APP_CONST } from '../../../app.const';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import { UrlService } from '../../../libraries/common/url.service';

@Component({
  selector: 'app-po-details',
  templateUrl: 'po-details.component.html',
  styleUrls: ['./po-details.component.scss']
})
export class PoDetailsComponent extends BaseFormBComponent {
  @Output() _data: any;
  @Output() imageModal: boolean = false;
  @Output() showModalConfirm: boolean = false;
  @Output() showModalDoc: boolean = false;
  @Output() showModalDelivery: boolean = false;
  imagePlaceholder = (require('../../../libraries/img-upload/img-upload-placeholder.png') as any)
    .default;
  formModalConfirm: FormGroup;
  formModalDoc: FormGroup;
  formModalDelivery: FormGroup;
  nextDealerConfirmationStatus: any;
  nextDocumentStatus: any;
  nextDeliveryStatus: any;

  constructor(private _poRest: PoRestService, private urlService: UrlService, private el: ElementRef) {
    super();
    this.createFormConfirm();
    this.createFormDoc();
    this.createFormDelivery();
    this.componentId = 'PoDetailsComponent';
  }

  goBack() {
    this.urlService.back();
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._poRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this._data = res;
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataStatus',
      handle: () => {
        return this._poRest.getStatus(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nextDealerConfirmationStatus = res.nextDealerConfirmationStatus;
        this.nextDocumentStatus = res.nextDocumentStatus;
        this.nextDeliveryStatus = res.nextDeliveryStatus;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'PoDetailsComponent:loadData',
          'PoDetailsComponent:loadDataStatus'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  createFormConfirm() {
    this.formModalConfirm = new FormGroup({
      status: new FormControl(''),
      confirmBy: new FormControl('', [Validators.required])
    });
  }

  createFormDoc() {
    this.formModalDoc = new FormGroup({
      status: new FormControl(''),
      confirmBy: new FormControl('', [Validators.required])
    });
  }

  createFormDelivery() {
    this.formModalDelivery = new FormGroup({
      status: new FormControl(''),
      confirmBy: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required])
    });
  }

  async updateDealerStatus(value) {
    const result = await this._poRest
      .putStatusDealer(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.po.updateStatus'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async updateDocStatus(value) {
    const result = await this._poRest
      .putStatusDocument(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.po.updateStatus'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async updateDeliveryStatus(value) {
    const result = await this._poRest
      .putStatusDelivery(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.po.updateStatus'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  inputModalConfirm() {
    this.formModalConfirm.controls['status'].setValue(
      this.nextDealerConfirmationStatus
    );
    const formValue = this.formModalConfirm.value;
    this.updateDealerStatus(formValue);
    this.showModalConfirm = false;
    this.formModalConfirm.controls['confirmBy'].reset();
    this.callHookDirectly('init');
  }

  inputModalDoc() {
    this.formModalDoc.controls['status'].setValue(this.nextDocumentStatus);
    const formValue = this.formModalDoc.value;
    this.updateDocStatus(formValue);
    this.showModalDoc = false;
    this.formModalDoc.controls['confirmBy'].reset();
    this.callHookDirectly('init');
  }

  inputModalDelivery() {
    this.formModalDelivery.controls['status'].setValue(this.nextDeliveryStatus);
    const formValue = this.formModalDelivery.value;
    this.updateDeliveryStatus(formValue);
    this.showModalDelivery = false;
    this.formModalDoc.controls['confirmBy'].reset();
    this.callHookDirectly('init');
  }

  convertToIdr(value) {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });

    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }

  convertDate(dateValue) {
    const dateNew = moment(dateValue).format('HH:mm, DD MMMM YYYY');
    return dateNew;
  }

  convertStatus(status) {
    const statusFormated = status.toLowerCase();
    switch (statusFormated) {
      case 'confirmed':
        return 'ui.po.status.confirmed';
      case 'completed':
        return 'ui.po.status.completed';
      case 'paid':
        return 'ui.po.status.paid';
      case 'processing':
        return 'ui.po.status.processing';
      case 'unpaid':
        return 'ui.po.status.unpaid';
      case 'belum dikirim':
        return 'ui.po.status.undelivered';
      case 'sedang dikirim':
        return 'ui.po.status.delivered';
      case 'waiting confirmation':
        return 'ui.po.status.waitingConfirmation';
      case 'waiting document':
        return 'ui.po.status.waitingDoc';
      case 'sudah diterima':
        return 'ui.po.status.accepted';
      case 'expired':
        return 'ui.po.status.expired';
      case 'failed':
        return 'ui.po.status.failed';
      case 'cancel':
        return 'ui.po.status.cancel';
      default:
        return status;
    }
  }

  convertNextStatus(status) {
    const statusFormated = status.toUpperCase();
    return statusFormated;
  }

  convertImg(url) {
    if (url.startsWith('/')) return `${APP_CONST.API_COMPANY}${url}`;
    return url;
  }

  showImageDialog() {
    this.imageModal = true;
    const bucket = 'volta-ecommerce-dev';
    const params = {
      bucket,
      fileName: decodeURIComponent(this._data.ktpPhoto.split(`${bucket}/`)[1])
    };
    this._poRest.getKTPImage(params).subscribe(data => {
      const ktpPreview = this.el.nativeElement.querySelector('#js-ktp-preview');
      console.log(ktpPreview)
      if (ktpPreview) {
        ktpPreview.src = `data:image/png;base64,${data}`;
      }
    }, error => {
      const ktpPreview = this.el.nativeElement.querySelector('#js-ktp-preview');
      if (ktpPreview) {
        ktpPreview.src = '';
      }
    });
  }

  showDealerConfirmationDialog() {
    this.showModalConfirm = true;
  }

  showDocumentConfirmationDialog() {
    this.showModalDoc = true;
  }

  showDeliveryConfirmationDialog() {
    this.showModalDelivery = true;
  }
}
