import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { DealerRestService } from '../../../dealer/dealer-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
// import { DataFormatService } from '../../../libraries/common/data-format.service';
import * as moment from 'moment';

@Component({
  selector: 'app-product-price-list-and-update',
  templateUrl: 'product-price-list-and-update.component.html'
})
export class ProductPriceListAndUpdateComponent extends BaseListBComponent<
  any
> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.region',
      field: 'region',
      sort: true,
      isEditable: false
    },
    {
      i18nLabel: 'ui.product.entity.fullPayment',
      field: 'priceFullPayment',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.bookingFee',
      field: 'priceBookingFee',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.dpCredit',
      field: 'priceDPCredit',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceSubsidy',
      field: 'priceSubsidy',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceBuybackMax1y',
      field: 'priceBuybackMax1y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceBuybackMax2y',
      field: 'priceBuybackMax2y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceBuybackMax3y',
      field: 'priceBuybackMax3y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifBuyback1y',
      field: 'priceDifBuyback1y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifBuyback2y',
      field: 'priceDifBuyback2y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifBuyback3y',
      field: 'priceDifBuyback3y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceTradeinMax1y',
      field: 'priceTradeinMax1y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceTradeinMax2y',
      field: 'priceTradeinMax2y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceTradeinMax3y',
      field: 'priceTradeinMax3y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifTradein1y',
      field: 'priceDifTradein1y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifTradein2y',
      field: 'priceDifTradein2y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'ui.product.entity.priceDifTradein3y',
      field: 'priceDifTradein3y',
      sort: true,
      isEditable: true
    },
    {
      i18nLabel: 'Last Update',
      field: 'updatedAt',
      sort: true,
      isEditable: false
    }
  ];
  tableColumnsShow = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  modalCreate: boolean = false;
  modalUpdateAll: boolean = false;
  formModalCreate: FormGroup;
  formModalUpdateAll: FormGroup;
  breadcrumbs: { label: string; url: string }[];
  prodId: any;
  varId: any;
  priceId: any;
  nameProduct: any;
  regionsOption: any[] = [];
  dealers: any[] = [];

  constructor(
    private _ProductRest: ProductRestService,
    private _DealerRest: DealerRestService,
    private route: ActivatedRoute
  ) {
    super();

    this.componentId = 'ProductPriceListAndUpdateComponent';
    this.headerTitle = 'ui.product.update.updatePrice';
    this.titlePage = '';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.banner.delete';

    this.route.params.subscribe(res => {
      this.varId = res.idVariant;
      this.prodId = res.id;
    });

    this.headerButtons = [];
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/motorcycle'
      },
      {
        label: 'ui.product.update.updatePrice',
        url: ''
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qParams = {
          sortBy: 'price',
          sortDir: 'desc',
          limit: 1
        };

        return this._ProductRest.findAllVariant(this.prodId, qParams).pipe(
          switchMap(variant => {
            let dataMapped = variant.data[0]?.price?.map(item => ({
              ...item,
              updatedAt: moment(variant?.updatedAt)
                .utcOffset('+0700')
                .format('DD MMMM YYYY HH:mm')
            }));

            if (this.qParams.keyword) {
              dataMapped = dataMapped.filter(item =>
                item.region
                  .toLowerCase()
                  .includes(this.qParams.keyword.trim().toLowerCase())
              );
            }
            return of({ data: dataMapped });
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataProduct',
      handle: () => {
        return this._ProductRest.loadProduct(this.prodId).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nameProduct = res.name;
        if (!this.titlePage) {
          this.titlePage = this.nameProduct;
        }
        const productName = {
          label: `${this.nameProduct}`,
          url: `/motorcycle/${this.prodId}/list-variant`
        };

        // Check if productName already exists in the breadcrumbs array
        const existingProduct = this.breadcrumbs.find(
          breadcrumb => breadcrumb.label === productName.label
        );
        if (!existingProduct) {
          this.breadcrumbs.splice(1, 0, productName);
        }
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataDealers',
      handle: event => {
        // const qOption = {...event.data.qOption};
        // const qParams = {
        //   ...this.qParams,
        //   ...qOption,
        // };
        return this._DealerRest.findAll({}).subscribe(data => {
          const dataFormatted = data?.data?.map(d => {
            d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
            return d;
          });
          this.dealers = dataFormatted;
        });
      }
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductPriceListAndUpdateComponent:workflowLoadData',
          'ProductPriceListAndUpdateComponent:loadDataProduct',
          'ProductPriceListAndUpdateComponent:loadDataDealers'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }
  onCellEditChange(data: any) {
    data.isChanged = true;
  }

  applyCellEdit(data: any) {
    data.isUpdating = true;

    this._ProductRest
      .updateAllVariantProduct(this.prodId, data)
      .toPromise()
      .then(res => {
        if (res) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.updatePrice'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          data.isUpdating = false;
          data.isChanged = false;
          this.callHook('loadData');
        }
      })
      .catch(err => {
        this.comp._globalSystemMessage.log({
          message: this.comp._translate.instant('error.product.updatePrice'),
          type: 'error',
          showAs: 'growl',
          showSnackBar: false,
          scroll: true
        });
        data.isUpdating = false;
      });
  }
}
