import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PartnershipBusinessRestService } from '../partnership-business-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';

@Component({
  selector: 'app-partnership-business-list',
  templateUrl: 'partnership-business-list.component.html'
})
export class PartnershipBusinessListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.partnershipBusiness.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.name',
      field: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.companyName',
      field: 'companyName',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.officeAddress',
      field: 'officeAddress',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.businessType',
      field: 'businessType',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.purpose',
      field: 'purpose',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.shortBrief',
      field: 'shortBrief',
      sort: true
    },
    {
      i18nLabel: 'ui.partnershipBusiness.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  isLoadingExportExcel: boolean = false;

  constructor(
    public _authentication: AuthenticationService,
    private _PartnershipBusinessRest: PartnershipBusinessRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'PartnershipBusinessListComponent';
    this.headerTitle = 'ui.partnershipBusiness.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._PartnershipBusinessRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'PartnershipBusinessListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  convertToIdr(value) {
    if (!value) return '';

    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });
    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._PartnershipBusinessRest.findAll({}).toPromise();
      const mapOption = {
        name: { header: 'Nama' },
        companyName: { header: 'Nama Perusahaan' },
        phone: { header: 'Telepon' },
        officeAddress: { header: 'Alamat Perusahaan' },
        businessType: { header: 'Bidang Usaha' },
        purpose: { header: 'Tujuan' },
        shortBrief: { header: 'Penjelasan Singkat' },
      };
      const payload = result.data.map(item => ({
        ...item,
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }))
      this._export.export({
        extension: 'xlsx',
        fileName: 'PartnershipBusiness',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
