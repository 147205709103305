import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { ApiQueryOption } from '../../libraries/http/api-query-option.model';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class BannerRestService {
  baseURL = `${APP_CONST.API_MAIN}/admin/banner`;
  baseFileUrl = `${APP_CONST.API_FILE}`;

  request = this._request.new(this.baseURL);
  requestFile = this._request.new(this.baseFileUrl);

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(`/`, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  load(bannerId: string) {
    return this.request.get<any>(`${bannerId}`);
  }

  delete(bannerId: string) {
    return this.request.delete<any>(`${bannerId}`);
  }

  update(bannerId: string, payload: any) {
    return this.request.put<any>(`${bannerId}`, payload);
  }

  deleteImageById(id) {
    return this.requestFile.delete(id);
  }
}
