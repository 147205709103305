import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PartnershipRestService } from '../partnership-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/libraries.module';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-partnership-list',
  templateUrl: 'partnership-list.component.html'
})
export class PartnershipListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.partnership.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.partnership.entity.name',
      field: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.ktp',
      field: 'ktp',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.address',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.businessType',
      field: 'businessType',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.currentBusiness',
      field: 'currentBusiness',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.partnerType',
      field: 'partnerType',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.province',
      field: 'province.provName',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.city',
      field: 'city',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.marketingArea',
      field: 'marketingArea',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.totalModal',
      field: '',
      name: 'totalModal',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.havingBusinessLocation',
      field: 'havingBusinessLocation',
      sort: true
    },
    {
      i18nLabel: 'ui.partnership.entity.linkMaps',
      field: 'linkMaps',
    },
    {
      i18nLabel: 'ui.partnership.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  isLoadingExportExcel: boolean = false;

  constructor(
    public _authentication: AuthenticationService,
    private _PartnershipRest: PartnershipRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'PartnershipListComponent';
    this.headerTitle = 'ui.partnership.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._PartnershipRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'PartnershipListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  convertToIdr(value) {
    if (!value) return '';

    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });
    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._PartnershipRest.findAll({}).toPromise();
      const mapOption = {
        name: { header: 'Nama' },
        phone: { header: 'Telepon' },
        ktp: { header: 'No KTP' },
        address: { header: 'Alamat' },
        businessType: { header: 'Tipe Bisnis/Usaha' },
        currentBusiness: { header: 'Jenis Bisnis/Usaha Saat Ini' },
        partnerType: { header: 'Tipe Partner' },
        province: { header: 'Provinsi' },
        city: { header: 'Kota' },
        marketingArea: { header: 'Area Marketing' },
        totalModal: { header: 'Total Modal' },
        havingBusinessLocation: { header: 'Alamat Lokasi Usaha' },
        linkMaps: { header: 'Link Map' },
        createdAt: { header: 'Tanggal Submit' },
      };
      const payload = result.data.map(item => ({
        ...item,
        province: item.provName,
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }))
      this._export.export({
        extension: 'xlsx',
        fileName: 'Partnership',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
