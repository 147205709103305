import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class SPKRestService {
  baseURL = `${APP_CONST.API_MASTER}`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  getUnitType() {
    return this.request.get('/admin/spk/spk-units');
  }

  getDealers() {
    return this.request.get('/admin/spk/get-dealers');
  }

  getBatteries() {
    return this.request.get('/admin/spk/spk-batteries');
  }

  createSPK(payload) {
    return this.request.post('/admin/spk/spk-cash', payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/admin/spk`, { params: queryParams });
  }

  getPresignedUrl(payload) {
    return this.request.post<any>('/admin/spk/pre-signed-url', payload);
  }

  createInvoice(payload) {
    return this.request.patch('/admin/spk/create-invoice', payload);
  }

  createPerformaInvoice(payload) {
    return this.request.patch('/admin/spk/create-performa-invoice', payload);
  }

  createInvoiceDP(payload) {
    return this.request.patch('/admin/spk/create-invoice-dp', payload);
  }

  createInvoiceSettlement(payload) {
    return this.request.patch('/admin/spk/create-invoice-settlement', payload);
  }

  updateChassisNumber(payload) {
    return this.request.patch('/admin/spk/update-chassis-number', payload);
  }

  updatePoliceNumber(payload) {
    return this.request.patch('/admin/spk/update-police-number', payload);
  }

  findSubsidyByNIK(nik: number) {
    return this.request.get<any>(`/admin/spk/user-subsidy/${nik}`);
  }

  getSubsidyDiscountPrice() {
    return this.request.get<any>('/public/orders/subsidy/discount-price');
  }

  updateProofOfPayment(payload) {
    return this.request.patch('/admin/spk/update-proof-of-payment', payload);
  }

  updateProofOfPaymentStatus(payload) {
    return this.request.patch('/admin/spk/update-proof-of-payment/status', payload);
  }

  updateVerification(payload) {
    return this.request.patch('/admin/spk/update-verification', payload);
  }

  updateVerificationStatus(payload) {
    return this.request.patch('/admin/spk/update-verification/status', payload);
  }

  claimSubsidy(payload) {
    return this.request.patch('/admin/spk/claim', payload);
  }

  confirmClaimSubsidy(payload) {
    return this.request.patch('/admin/spk/claim/status', payload);
  }

  updateDeliveryDate(payload) {
    return this.request.patch('/admin/spk/update-delivery-date', payload);
  }

  delete(id: string) {
    return this.request.delete(`/admin/spk/${id}?returnStock=true`);
  }

  rejectSPK(payload) {
    return this.request.put('/admin/spk/reject', payload);
  }

  changeStatusSPK(payload) {
    return this.request.patch('/admin/spk/change-status', payload);
  }

  updateShippingCost(payload) {
    return this.request.patch('/admin/spk/update-shipping-cost', payload);
  }

  updateShippingAddress(payload) {
    return this.request.patch('/admin/spk/update-shipping-address', payload);
  }

  updateUnits(payload) {
    return this.request.patch('/admin/spk/update-units', payload);
  }

  updateSpkUnit(payload: any) {
    return this.request.patch('/admin/spk/spk-unit', payload);
  }
}
