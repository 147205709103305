import { from as observableFrom, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelTokenStatic
} from 'axios';

import { AuthenticationService } from '../auth/authentication.service';
import { HttpExtsrvService } from './http-extsrv.service';

// import * as axiosRetry from 'axios-retry';
@Injectable()
export class RequestService {
  axios: AxiosInstance;
  cancelToken: CancelTokenStatic = Axios.CancelToken;

  constructor(
    private _auth: AuthenticationService,
    private _httpExtsrv: HttpExtsrvService
  ) {}

  new(
    baseURL: string,
    useDefaultInterceptor: boolean = true,
    withoutInterceptor: boolean = false
  ): RequestService {
    const axios: AxiosInstance = Axios.create({ baseURL });
    // (<any>axiosRetry)(axios, { retries: 3 });

    if (useDefaultInterceptor) {
      this._auth.axiosInterceptors(axios);
    }

    if (!withoutInterceptor) {
      this._httpExtsrv.axiosInterceptors(axios);
    }

    const requestService = new RequestService(this._auth, this._httpExtsrv);
    requestService.axios = axios;

    return requestService;
  }

  post<T>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios
        .post(url, data, config)
        .then(response => response.data);
      observableFrom(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  put<T>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios
        .put(url, data, config)
        .then(response => response.data);
      observableFrom(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  patch<T>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios
        .patch(url, data, config)
        .then(response => response.data);
      observableFrom(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  get<T>(
    url: string,
    config: AxiosRequestConfig = {},
    allowModify: boolean = false
  ) {
    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios
        .get(url, config)
        .then(response => (allowModify ? response : response.data));
      observableFrom(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  delete<T>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios
        .request({
          url,
          method: 'delete',
          data,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.data);
      observableFrom(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }
}
