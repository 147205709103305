import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { APP_CONST } from "../../../../app.const";

@Component({
  selector: 'app-assessment-card',
  templateUrl: 'assessment.component.html',
  styleUrls: ['../../buyback.component.scss']
})
export class AssessmentCardComponent {
  @Input() id: number;
  @Input() component: string;
  @Input() info: string;
  @Input() formGroup: FormGroup; // Accept the form group as input

  uploadEndpoint = APP_CONST.API_FILE;

  // Access form controls from the formGroup
  get isConditionGood() {
    return this.formGroup.get('isConditionGood');
  }

  get remark() {
    return this.formGroup.get('remark');
  }

  
  onImageFinishUploaded(image, inputName: string) {
    this.formGroup.patchValue({
      uploadFoto: {
          [inputName]: image?.fileUrl,
      }
    });
  }

  onDeleteImage(inputName: string) {
    this.formGroup.patchValue({
      uploadFoto: {
        [inputName]: ''
      }
    });
  }
}