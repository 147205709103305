import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { ApiQueryOption } from '../../libraries/http/api-query-option.model';
import { RequestService } from '../../libraries/http/request.service';

@Injectable({
  providedIn: 'any'
})
export class PoRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/orders`;
  statusURL = (poId: string) => `${APP_CONST.API_MASTER}/admin/orders/${poId}/statuses`;
  request = this._request.new(this.baseURL);
  requestStatus = (poId: string) => this._request.new(this.statusURL(poId));

  constructor(private _request: RequestService) {}

  create(payload: any) {
    return this.request.post<any>(`create`, payload);
  }

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  load(PoDetailsComponent: string) {
    return this.request.get<any>(`${PoDetailsComponent}`);
  }

  delete(poId: string) {
    return this.request.delete<any>(`${poId}`);
  }

  update(poId: string, payload: any) {
    return this.request.put<any>(`${poId}`, payload);
  }

  getStatus(poId: string) {
    return this.requestStatus(poId).get<any>(``);
  }

  putStatusDealer(poId: string, payload: any) {
    return this.requestStatus(poId).put<any>(`dealer`, payload);
  }

  putStatusDocument(poId: string, payload: any) {
    return this.requestStatus(poId).put<any>(`document`, payload);
  }

  putStatusDelivery(poId: string, payload: any) {
    return this.requestStatus(poId).put<any>(`delivery`, payload);
  }

  getAccessRole() {
    return this.request.get<any>(`default-role-access`);
  }

  retryOrders(poId: string) {
    const modifiedPayload = {
      id: poId
    };
    return this.request.post<any>(`/retry/${poId}`, modifiedPayload);
  }

  recreateOrders(poId: string) {
    const modifiedPayload = {
      id: poId
    };
    return this.request.post<any>(`/recreate/${poId}`, modifiedPayload);
  }

  getSummary(queryParams) {
    return this.request.get('/summary/get', { params: queryParams });
  }

  getKTPImage(queryParams) {
    const fileRequest = this._request.new(`${APP_CONST.API_FILE}`);
    return fileRequest.get('/getFileStream/minio', { params: queryParams });
  }

  getDealers() {
    return this._request.new(APP_CONST.API_MASTER).get('/admin/dealers');
  }
}
