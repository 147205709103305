import { Component, Input } from "@angular/core";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-finance-review-modal',
  templateUrl: 'finance-review-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class FinanceReviewModalComponent {
  @Input() data: any;
  @Input() disabled: boolean;
  @Input() note: string;

  constructor(public _buybackListComponent: BuybackListComponent) {}

  isFinanceReviewModalOpen: boolean = false;

  openFinanceReviewModal() {
    this.isFinanceReviewModalOpen = true;
  }

  closeFinanceReviewModal() {
    this.isFinanceReviewModalOpen = false;
  }
}