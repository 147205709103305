import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SubsidiRestService } from '../subsidi-rest.service';
@Component({
  selector: 'app-subsidi-list-summary',
  templateUrl: 'subsidi-list-summary.component.html'
})
export class SubsidiListSummaryComponent extends BaseListBComponent<any> {
  summaryData: any;
  sourceListData: any;
  sourceSummaryData: any;
  statusTrx: { text: string; value: string }[];
  isLoading: boolean = false;

  constructor(private _SubsidiRest: SubsidiRestService) {
    super();
    this.componentId = 'SubsidiListSummaryComponent';
    this.headerTitle = 'ui.subsidi.list.titleSummary';
    this.statusTrx = [
      {
        text: 'Semua Status Transaksi',
        value: ''
      },
      {
        text: 'New',
        value: 'New'
      },
      {
        text: 'Booking Fee Paid',
        value: 'Booking Fee Paid'
      },
      {
        text: 'DP Paid',
        value: 'DP Paid'
      },
      {
        text: 'Proses Finance',
        value: 'Proses Finance'
      },
      {
        text: 'Payment Complete',
        value: 'Payment Complete'
      }
    ];
    this.qParams.status_trx = '';
    this.qParams.sourceid = '';
    this.qParams.endDate = moment().format('YYYY-MM-DD');
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadSingleData',
      handle: () => {
        this.isLoading = true;
        return this._SubsidiRest.getSummary(this.qParams).pipe();
      },
      success: res => {
        this.summaryData = res;
        this.sourceSummaryData = res?.sources;
        const dataSource = res?.sources;
        this.sourceSummaryData = dataSource
          .map(source => ({
            name: Object.keys(source)[0],
            total: Object.values(source)[0]
          }))
          .filter(source => source.name !== 'null');
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataSource',
      handle: () => {
        this.isLoading = true;
        return this._SubsidiRest.getSourceList().pipe();
      },
      success: res => {
        const sourceList = res.filter(v => v.sourceid !== 'null');
        const allSource = {
          text: 'Semua Sourceid',
          value: ''
        };
        this.sourceListData = sourceList.map(v => ({
          text: v.sourceid,
          value: v.sourceid
        }));
        this.sourceListData.unshift(allSource);
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'SubsidiListSummaryComponent:loadSingleData',
          'SubsidiListSummaryComponent:loadDataSource'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  onGridChanges() {
    this.callHookDirectly('init');
    this.isLoading = true;
  }

  convertTotal(numberValue) {
    const format = numberValue
      ?.toString()
      .split('')
      .reverse()
      .join('');
    const convert = format.match(/\d{1,3}/g);
    const totalData = convert
      .join('.')
      .split('')
      .reverse()
      .join('');
    return totalData;
  }
}
