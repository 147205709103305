import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-product-variant-list',
  templateUrl: 'product-variant-list.component.html'
})
export class ProductVariantListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.nameProduct',
      field: 'name'
    },
    {
      i18nLabel: 'ui.product.entity.variantId',
      field: 'productId'
    },
    {
      i18nLabel: 'ui.product.entity.color',
      field: 'nameColor'
    },
    {
      i18nLabel: 'ui.product.entity.colorId',
      field: 'colorCode'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  modalCreate: boolean = false;
  modalUpdateAll: boolean = false;
  formModalCreate: FormGroup;
  formModalUpdateAll: FormGroup;
  breadcrumbs: { label: string; url: string }[];
  prodId: any;
  variantId: any;
  dataUpdate: any;
  nameProduct: any;
  regionsOption: any[] = [];

  constructor(
    private _ProductRest: ProductRestService,
    private route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    super();
    this.createFormProduct();
    this.updateAllFormProduct();
    this.componentId = 'ProductVariantListComponent';
    this.headerTitle = 'ui.product.list.titleVariant';
    this.titlePage = '';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.route.params.subscribe(res => {
      this.prodId = res.id;
    });

    this.headerButtons = [
      {
        type: 'custom',
        label: 'Update All Price',
        color: 'primary-outline',
        onClick: () => this.openDialogUpdateAll()
      },
      {
        type: 'custom',
        label: 'ui.product.list.button.createVariant',
        color: 'primary-outline',
        onClick: () => this.openDialogCreate()
      }
    ];
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/sparepart'
      }
      // {
      //   label: 'ui.product.list.titleVariant',
      //   url: `/product/${this.prodId}/list-variant`
      // }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._ProductRest
          .findAllVariant(this.comp.routeParams.id, qParams)
          .pipe(
            switchMap(doc => {
              const dataFormatted = doc?.data
                ?.map(d => d)
                .sort((a, b) => a.nameColor.localeCompare(b.nameColor)); // Sort by nameColor property
              doc.data = dataFormatted;
              console.log(dataFormatted);
              return of(doc);
            })
          );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataProduct',
      handle: () => {
        return this._ProductRest.loadProduct(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nameProduct = res.name;
        // Check if titlePage is empty before assigning a new value
        if (!this.titlePage) {
          this.titlePage = this.nameProduct;
        }
        this.formModalCreate.patchValue({
          name: res.name
        });
        const productName = {
          label: res.name,
          url: `/product/${this.prodId}/list-variant`
        };
        // Check if productName already exists in the breadcrumbs array
        const existingProduct = this.breadcrumbs.find(
          breadcrumb => breadcrumb.label === productName.label
        );
        if (!existingProduct) {
          this.breadcrumbs.push(productName);
        }
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductVariantListComponent:workflowLoadData',
          'ProductVariantListComponent:loadDataProduct'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.modalCreate = true;
  }

  openDialogUpdate(variantId) {
    this.getVariantData(variantId);
    this.modalCreate = true;
    // this.variantId = variantId;
  }

  openDialogUpdateAll() {
    this.getAllRegions();
    this.modalUpdateAll = true;
  }

  actionDeleteVariant(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteVariantData(value);
      } else {
        return;
      }
    });
  }

  async createVariantData(value) {
    const result = await this._ProductRest
      .createProductVariant(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.createVariant'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async getVariantData(variantId) {
    const result = await this._ProductRest
      .getProductVariant(this.comp.routeParams.id, variantId)
      .toPromise()
      .then(res => {
        if (res) {
          this.variantId = variantId;
          this.formModalCreate.patchValue({
            name: this.nameProduct,
            color: res.color,
            nameColor: res.nameColor,
            colorCode: res.colorCode
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async getAllRegions() {
    try {
      const response = await this._ProductRest
        .getAllProductsRegion(this.prodId)
        .toPromise();
      if (response?.data) {
        const regions = response.data;
        const newRegionOption = {
          label: 'New Region',
          value: 'New Region'
        };
        this.regionsOption = [
          newRegionOption,
          ...regions.map(item => ({
            label: item,
            value: item
          }))
        ];
      }
    } catch (error) {
      this.comp._systemMessage.log({
        message: 'Error Getting Regions Data',
        type: 'error',
        scroll: true
      });
      this.modalUpdateAll = false;
    }
  }

  async updateAllPrice(value, variantId, type) {
    try {
      const response = await this._ProductRest
        .updateAllProductPriceData(variantId, value)
        .toPromise();
      if (response) {
        this.comp._globalSystemMessage.log({
          message: type
            ? this.comp._translate.instant(
                'success.product.successAddNewRegion'
              )
            : this.comp._translate.instant('success.product.updateAllPrice'),
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
          scroll: true
        });
      }
    } catch (error) {
      this.comp._systemMessage.log({
        message: 'error.product.updateAllPrice',
        type: 'error',
        scroll: true
      });
    }
  }

  async updateVariantData(value, variantId) {
    const result = await this._ProductRest
      .updateProductVariant(this.comp.routeParams.id, value, variantId)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.updateVariant'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteVariantData(value) {
    const result = await this._ProductRest
      .deleteProductVariant(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.deleteVariant'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  createFormProduct() {
    this.formModalCreate = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl('', [Validators.required]),
      nameColor: new FormControl('', [Validators.required]),
      colorCode: new FormControl('', [Validators.required])
    });
  }

  updateAllFormProduct() {
    this.formModalUpdateAll = new FormGroup({
      region: new FormControl('', [Validators.required]),
      newRegion: new FormControl(''),
      priceFullPayment: new FormControl(''),
      priceDPCredit: new FormControl(''),
      priceBookingFee: new FormControl(''),
      priceSubsidy: new FormControl('')
    });

    // Set conditional validators for newRegion field
    const newRegionControl = this.formModalUpdateAll.get('newRegion');
    const regionControl = this.formModalUpdateAll.get('region');

    regionControl.valueChanges.subscribe(value => {
      if (value === 'New Region') {
        newRegionControl.setValidators([Validators.required]);
      } else {
        newRegionControl.clearValidators();
      }

      newRegionControl.updateValueAndValidity();
    });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    if (this.variantId) {
      this.updateVariantData(formValue, this.variantId);
      this.callHookDirectly('init');
    } else {
      this.createVariantData(formValue);
    }
    this.modalCreate = false;
    this.formModalCreate.reset();
    this.callHookDirectly('init');
  }

  saveModalUpdateAll() {
    if (!this.formModalUpdateAll.valid) {
      CommonService.markAsDirty(this.formModalUpdateAll);
      return;
    }

    const formValue = this.formModalUpdateAll.value;
    const isNewRegion = formValue.region === 'New Region';

    const updatedPrice = {
      region: isNewRegion ? formValue.newRegion : formValue.region,
      priceFullPayment: formValue.priceFullPayment,
      priceDPCredit: formValue.priceDPCredit,
      priceBookingFee: formValue.priceBookingFee,
      priceSubsidy: formValue.priceSubsidy,
      create: isNewRegion
    };

    if (this.prodId) {
      this.updateAllPrice(updatedPrice, this.prodId, isNewRegion);
      this.callHookDirectly('init');
    }

    this.modalUpdateAll = false;
    this.formModalUpdateAll.reset();
  }

  closeModalCreate() {
    this.modalCreate = false;
    this.variantId = null;
    this.formModalCreate.reset();
    this.callHookDirectly('loadDataProduct');
  }

  closeModalUpdateAll() {
    this.modalUpdateAll = false;
    this.formModalUpdateAll.reset();
  }
}
