import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { SubsidiListComponent } from './list/subsidi-list.component';
import { SubsidiListSummaryComponent } from './list-summary/subsidi-list-summary.component';
import { SubsidiListTransactionComponent } from './list-transaction/subsidi-list-transaction.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: SubsidiListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'subsidy.read'
            }
          }
        ]
      },
      {
        path: 'subsidi-transaction',
        component: BasePageComponent,
        data: {
          breadcrumb: 'Subsidi Transaction'
        },
        children: [
          {
            path: '',
            component: SubsidiListTransactionComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'subsidy.read',
              breadcrumb: 'Subsidi Transaction'
            }
          }
        ]
      },
      {
        path: 'subsidi-summary',
        component: BasePageComponent,
        data: {
          breadcrumb: 'Subsidi Summary'
        },
        children: [
          {
            path: '',
            component: SubsidiListSummaryComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'subsidy.read',
              breadcrumb: 'Subsidi Summary'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubsidiRoutingModule {}
