import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { DealerRestService } from '../../../dealer/dealer-rest.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-product-list',
  templateUrl: 'region-dealer-list.component.html'
})
export class RegionDealerListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.region',
      field: 'region'
    },
    {
      i18nLabel: 'ui.product.entity.dealer',
      field: '',
      name: 'itemDealers'
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  modalUpdate: boolean = false;
  modalDealerList: boolean = false;
  formModalUpdate: FormGroup;
  breadcrumbs: { label: string; url: string }[];
  dealerList: object[] = [];
  selectedDealerIds: string[] = [];
  selectedRegionDealer: string = '';

  constructor(private _ProductRest: ProductRestService, private _DealerRest: DealerRestService) {
    super();
    this.createFormRegionDealer();
    this.componentId = 'RegionDealerListComponent';
    // this.headerTitle = 'ui.product.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';

    this.breadcrumbs = [
      {
        label: 'ui.product.list.titleRegionDealer',
        url: '/product'
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._ProductRest.getAllRegionFromProductVariant().pipe(
          switchMap(doc => {
            doc.data = doc;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'RegionDealerListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    this._DealerRest.findAll({ sortBy: 'region', sortDir: 'asc' }).subscribe(data => {
      this.dealerList = data?.data;
    });

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  onSelectDealer(dealerId: string) {
    const index = this.selectedDealerIds.indexOf(dealerId);
    if (index === -1) {
      this.selectedDealerIds.push(dealerId);
    } else {
      this.selectedDealerIds.splice(index, 1);
    }

    this.formModalUpdate.patchValue({
      dealerIds: this.selectedDealerIds
    });
  }

  renderDealerName(itemDealers: any[]) {
    return itemDealers.map(itemDealer => `${itemDealer.name} (${itemDealer.region})`).join(', ');
  }

  openDialogUpdate(data) {
    this.modalUpdate = true;
    this.formModalUpdate.patchValue({
      newRegion: data.region,
      dealerIds: data.itemDealers.map(item => item._id)
    });
    this.selectedDealerIds = data.itemDealers.map(item => item._id);
    this.selectedRegionDealer = data.region;
  }

  openModalDealerList() {
    this.modalDealerList = true;
  }

  closeModalUpdate() {
    this.modalUpdate = false;
    this.formModalUpdate.reset();
  }

  closeModalDealerList() {
    this.modalDealerList = false;
  }

  createFormRegionDealer() {
    this.formModalUpdate = new FormGroup({
      newRegion: new FormControl('', [Validators.required]),
      dealerIds: new FormControl([])
    });
  }

  async updateRegionDealer(value) {
    const result = await this._ProductRest
      .updateAllProductVariantsRegionDealer(this.selectedRegionDealer, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.create'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductData(value) {
    const result = await this._ProductRest
      .deleteProduct(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.delete'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  renderSelectedDealers() {
    return this.dealerList
      .filter((item: any) => this.selectedDealerIds.includes(item._id))
  }

  async saveModalUpdate() {
    const formValue = this.formModalUpdate.value;
    if (!this.formModalUpdate.valid) {
      CommonService.markAsDirty(this.formModalUpdate);
      return;
    }
    await this.updateRegionDealer(formValue);
    this.modalUpdate = false;
    this.formModalUpdate.reset();
    this.callHookDirectly('init');
  }
}
