import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { SPKListComponent } from './list/spk-list.component';
import { SPKRoutingModule } from './spk-indent-routing.module';
import { SPKRestService } from './spk-indent-rest.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CreateModalComponent } from './create-modal/create-modal.component';
import { ChipComponent } from './components/chip/chip.component';
import { AccordionComponent } from './components/accordion/accordion.component'
import { ModalComponent } from './components/modal/modal.component';
import { ProofOfPaymentDPModalComponent } from './proof-of-payment-dp-modal/proof-of-payment-dp-modal.component';
import { PaymentDetailModalComponent } from './payment-detail-modal/payment-detail-modal.component';
import { StatusInfoModal } from './status-info-modal/status-info-modal.component';
import { DetailUnitModal } from './detail-unit-modal/detail-unit-modal.component';
import { DeliveryDateModalComponent } from './delivery-date-modal/delivery-date-modal.component';
import { ProofOfPaymentSettlementModalComponent } from './proof-of-payment-settlement-modal/proof-of-payment-settlement-modal.component';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';
import { ShippingCostComponent } from './shipping-cost/shipping-cost.component';
import { FormUpdateUnitsComponent } from './detail-unit-modal/form-update-units/form-update-units.component';

export const PROVIDERS = [SPKRestService];

@NgModule({
  imports: [
    SPKRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule
  ],
  declarations: [
    SPKListComponent,
    CreateModalComponent,
    ProofOfPaymentDPModalComponent,
    PaymentDetailModalComponent,
    StatusInfoModal,
    ChipComponent,
    AccordionComponent,
    ModalComponent,
    DetailUnitModal,
    DeliveryDateModalComponent,
    ProofOfPaymentSettlementModalComponent,
    ChangeStatusModalComponent,
    ShippingCostComponent,
    FormUpdateUnitsComponent
  ]
})
export class SPKIndentModule {}
