import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SPKRestService } from '../spk-rest.service';
@Component({
  selector: 'app-spk-list-summary',
  templateUrl: 'spk-list-summary.component.html'
})
export class SPKListSummaryComponent extends BaseListBComponent<any> {
  summaryData: any;
  statusTrx: { text: string; value: string }[];
  isLoading: boolean = true;
  typeOptions = [
    {
      text: 'Semua Tipe SPK',
      value: ''
    },
    {
      text: 'Satuan',
      value: 'satuan'
    },
    {
      text: 'Cash',
      value: 'cash'
    },
    {
      text: 'Indent',
      value: 'indent'
    }
  ];
  statusOptions = [
    {
      text: 'Semua Status SPK',
      value: ''
    },
    {
      text: "New",
      value: "new"
    },
    {
      text: "Waiting Payment",
      value: "waiting payment"
    },
    {
      text: "SPK Paid",
      value: "spk paid"
    },
    {
      text: "Waiting Sisapira",
      value: "waiting sisapira"
    },
    {
      text: "Waiting Verification",
      value: "waiting verification"
    },
    {
      text: "Approved",
      value: "approved"
    },
    {
      text: "Waiting Claim",
      value: "waiting claim"
    },
    {
      text: "Claimed",
      value: "claimed"
    },
    {
      text: "Delivered",
      value: "delivered"
    },
    {
      text: "Expired",
      value: "expired"
    }
  ];

  constructor(private _SPKRest: SPKRestService) {
    super();
    this.componentId = 'SPKListSummaryComponent';
    this.headerTitle = 'ui.spk.list.summaryTitle';
    this.qParams.type = 'satuan';
    this.qParams.status = '';
    this.qParams.endDate = moment().format('YYYY-MM-DD');
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadSingleData',
      handle: () => {
        return this._SPKRest.getSummary(this.qParams).pipe();
      },
      success: res => {
        this.summaryData = res;
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'SPKListSummaryComponent:loadSingleData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  onGridChanges() {
    this.callHookDirectly('init');
    this.isLoading = true;
  }

  convertTotal(numberValue) {
    const format = numberValue
      ?.toString()
      .split('')
      .reverse()
      .join('');
    const convert = format.match(/\d{1,3}/g);
    const totalData = convert
      .join('.')
      .split('')
      .reverse()
      .join('');
    return totalData;
  }
}
