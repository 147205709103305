import { Component, Input } from "@angular/core";
import { BuybackListComponent } from "../list/buyback-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-review-result-modal',
  templateUrl: 'review-result-modal.component.html',
  styleUrls: ['../buyback.component.scss']
})
export class ReviewResultModalComponent {
  @Input() note: string;
  @Input() disabled: boolean;

  constructor(public _buybackListComponent: BuybackListComponent) {}

  isReviewResultModalOpen: boolean = false;

  openReviewResultModal() {
    this.isReviewResultModalOpen = true;
  }

  closeReviewResultModal() {
    this.isReviewResultModalOpen = false;
  }

}