import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKRestService } from "../spk-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-police-number-modal',
  templateUrl: 'police-number-modal.component.html',
  styleUrls: ['../spk-component.scss']
})
export class PoliceNumberModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
    this.initFormPoliceNumber();
  }

  isLoading: boolean = false;
  formUpdatePoliceNumber: FormGroup;
  isPoliceNumberModalOpen: boolean = false;

  initFormPoliceNumber() {
    this.formUpdatePoliceNumber = new FormGroup({
      noPol: new FormControl('', [Validators.required])
    });
  }

  openPoliceNumberModal() {
    if (['expired', 'rejected'].includes(this.spk.status)) return;
    this.formUpdatePoliceNumber.patchValue({ noPol: this.spk?.noPol });
    this.isPoliceNumberModalOpen = true;
  }

  closePoliceNumberModal() {
    this.isPoliceNumberModalOpen = false;
    this.formUpdatePoliceNumber.reset();
  }

  async updatePoliceNumber() {
    if (!this.formUpdatePoliceNumber.valid) {
      CommonService.markAsDirty(this.formUpdatePoliceNumber);
    } else {
      const payload = {
        ...this.formUpdatePoliceNumber.value,
        spkId: this.spk._id
      };
      this.isLoading = true;

      await this._SPKRest
        .updatePoliceNumber(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Update Police Number successfully!',
              '',
              'success'
            )

            this._spkListComponent.callHookDirectly('init');
            this.closePoliceNumberModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}