import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { SPKListComponent } from './list/spk-list.component';
import { SPKRoutingModule } from './spk-routing.module';
import { SPKRestService } from './spk-rest.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CreateModalComponent } from './create-modal/create-modal.component';
import { ChipComponent } from './components/chip/chip.component';
import { AccordionComponent } from './components/accordion/accordion.component'
import { ModalComponent } from './components/modal/modal.component';
import { ChassisNumberModalComponent } from './chassis-number-modal/chassis-number-modal.component';
import { PoliceNumberModalComponent } from './police-number-modal/police-number-modal.component';
import { ProofOfPaymentModalComponent } from './proof-of-payment-modal/proof-of-payment-modal.component';
import { PaymentDetailModalComponent } from './payment-detail-modal/payment-detail-modal.component';
import { VerificationModalComponent } from './verification-modal/verification-modal.component';
import { StatusInfoModal } from './status-info-modal/status-info-modal.component';
import { ClaimModalComponent } from './claim-modal/claim-modal.component';
import { SPKListSummaryComponent } from './list-summary/spk-list-summary.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';
import { ShippingCostComponent } from './shipping-cost/shipping-cost.component';

export const PROVIDERS = [SPKRestService];

@NgModule({
  imports: [
    SPKRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    ProgressSpinnerModule
  ],
  declarations: [
    SPKListComponent,
    CreateModalComponent,
    ChassisNumberModalComponent,
    PoliceNumberModalComponent,
    ProofOfPaymentModalComponent,
    PaymentDetailModalComponent,
    VerificationModalComponent,
    ClaimModalComponent,
    StatusInfoModal,
    ChipComponent,
    AccordionComponent,
    ModalComponent,
    SPKListSummaryComponent,
    ChangeStatusModalComponent,
    ShippingCostComponent,
  ]
})
export class SPKModule {}
