import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKRestService } from '../../spk-lite-to-reguler-rest.service';
import { CommonService } from '../../../../libraries/libraries.module';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../../list/spk-list.component';

type UnitType = {
  unit: string;
  total: number;
  productTypeVariantId: string;
  description: string;
  batteryType: string;
}

@Component({
  selector: 'app-form-update-units',
  templateUrl: 'form-update-units.component.html',
  styleUrls: ['../../spk-lite-to-reguler-component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class FormUpdateUnitsComponent {
  @Input() units: UnitType[];
  @Input() spk: any;
  @Output() onClose = new EventEmitter();

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {}

  batteryTypes = [
    {
      title: 'Online',
      value: 'online'
    },
    {
      title: 'Offline',
      value: 'offline'
    },
  ];
  formUpdateUnits: FormGroup;
  unitItems: any[] = [];
  loadingUpdateUnits: boolean = false;

  createUnitFormGroup(data?: UnitType): FormGroup {
    return new FormGroup({
      unit: new FormControl(data?.unit || '', [Validators.required]),
      total: new FormControl(data?.total || '', [Validators.required]),
      description: new FormControl(data?.description || ''),
      batteryType: new FormControl(data?.batteryType || ''),
      productTypeVariantId: new FormControl(data?.productTypeVariantId || '', [Validators.required])
    });
  }

  onUnitSelected(unit, index) {
    const productTypeVariantId = unit?.value?.productTypeVariantId;
    const selectedUnit = this.unitItems?.find(item => item._id == productTypeVariantId);
    const selectedUnitName = selectedUnit?.name;

    const unitFormGroup = this.formUpdateUnits?.get('units')?.get(index.toString()) as FormGroup;

    // Set unit name
    unitFormGroup.get('unit')?.setValue(selectedUnitName);
  }

  initFormUpdateUnits() {
    this.formUpdateUnits = new FormGroup({
      units: new FormArray([
        ...this.units?.map(unit => this.createUnitFormGroup(unit))
      ])
    });
  }

  addUnit() {
    const units = this.formUpdateUnits.get('units') as FormArray;
    units.push(this.createUnitFormGroup());
  }

  removeUnit(index: number) {
    const units = this.formUpdateUnits.get('units') as FormArray;
    units.removeAt(index);
  }

  get unitsControls() {
    return (this.formUpdateUnits.get('units') as FormGroup).controls;
  }

  validSPKStatus() {
    return !['expired', 'rejected'].includes(this.spk?.status)
  }

  async getUnits() {
    await this._SPKRest.getUnitType().subscribe((data: any) => {
      this.unitItems = data?.map(item => {
        const currentUserUnit = this.units?.find(currentUnit => currentUnit.productTypeVariantId === item._id);

        return {
          ...item,
          name: `${item?.productTypeId?.name} - ${item?.color}`
        };
      });
    });
  }

  onCloseUpdateUnits() {
    this.onClose.emit();
  }

  onUpdateUnits() {
    if (!this.formUpdateUnits.valid) {
      CommonService.markAsDirty(this.formUpdateUnits);
    } else {
      Swal.fire({
        title: 'Update Units?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateUnits();
        }
      });
    }
  }

  async updateUnits() {
    const payload = {
      spkId: this.spk?._id,
      units: this.formUpdateUnits.value.units
    };

    this.loadingUpdateUnits = true;
    this._SPKRest
      .updateUnits(payload)
      .toPromise()
      .then((response: any) => {
        this._spkListComponent.callHookDirectly('init');
        Swal.fire(
          'Update units Successfully!',
          '',
          'success'
        )
      })
      .catch(error => {
        const errorData = { ...error };
        Swal.fire(
          'Gagal',
          errorData?.response?.data?.message,
          'error'
        )
      })
      .finally (() => {
        this.loadingUpdateUnits = false;
        this.onCloseUpdateUnits();
      })
  }

  ngOnInit(): void {
    this.initFormUpdateUnits();
    this.getUnits();
  }
}