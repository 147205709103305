import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class AgentBfiClaimRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/affiliator-claim/partner/bfi`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  approval(payload: any) {
    return this.request.patch<any>(`/approval`, payload);
  }

  getPresignedUrl(payload) {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.post<any>('/admin/spk/pre-signed-url', payload)
  }
}
