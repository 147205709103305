import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../../libraries/common/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-feature',
  templateUrl: 'update-feature.component.html'
})
export class ProductUpdateFeatureComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.info',
      field: 'information'
    },
    {
      i18nLabel: 'ui.product.entity.desc',
      field: 'description'
    },
    {
      i18nLabel: 'ui.product.entity.type',
      field: 'type'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  formModalCreate: FormGroup;

  constructor(private _ProductRest: ProductRestService) {
    super();
    this.createFormProductFeature();
    this.componentId = 'ProductUpdateFeatureComponent';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.banner.delete';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._ProductRest
          .getProductFeature(this.comp.routeParams.id)
          .pipe(
            switchMap(doc => {
              const dataFormatted = doc?.map(d => {
                return d;
              });
              doc.data = dataFormatted;
              return of(doc);
            })
          );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductUpdateFeatureComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  createFormProductFeature() {
    this.formModalCreate = new FormGroup({
      title: new FormControl(''),
      information: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      type: new FormControl('')
    });
  }

  actionDeleteProductFeature(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteProductFeatureData(value);
      } else {
        return;
      }
    });
  }

  async createProductFeatureData(value) {
    const result = await this._ProductRest
      .createProductFeature(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.createFeature'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductFeatureData(value) {
    const result = await this._ProductRest
      .deleteProductFeature(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.deleteFeature'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    this.createProductFeatureData(formValue);
    this.showModalCreate = false;
    this.formModalCreate.reset();
    this.callHookDirectly('init');
  }

  closeModalCreate() {
    this.showModalCreate = false;
    this.formModalCreate.reset();
  }
}
