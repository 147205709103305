import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../../libraries/libraries.module';
import { SharedModule } from '../../../libraries/shared.module';
import { ProductMotorcycleListComponent } from './list-motorcycle/product-list.component';
import { ProductRestService } from '../product-rest.service';
import { ProductRoutingModule } from './product-routing.module';
import { ProductUpdateDetailComponent } from './update-product/update-detail/update-detail.component';
import { ProductUpdateFeatureComponent } from './update-product/update-feature/update-feature.component';
import { ProductUpdateComponent } from './update-product/update-product.component';
import { ProductVariantListComponent } from './list-variant/product-variant-list.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProductPriceListComponent } from './list-price/product-price-list.component';
import { PhotoListComponent } from './list-photo/photo-list.component';
import { ProductPriceListAndUpdateComponent } from './list-and-update-price/product-price-list-and-update.component';
import { ChipModule } from '../../../libraries/chip/chip.module';

export const PROVIDERS = [ProductRestService];

@NgModule({
  imports: [
    ProductRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule,
    ChipModule
  ],
  declarations: [
    ProductMotorcycleListComponent,
    ProductUpdateComponent,
    ProductUpdateFeatureComponent,
    ProductUpdateDetailComponent,
    ProductVariantListComponent,
    ProductPriceListComponent,
    ProductPriceListAndUpdateComponent,
    PhotoListComponent
  ]
})
export class ProductModule {}
