import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { GeneralDesignListComponent } from './generalDesign/list/list.component';
import { CustomDesignFormListComponent } from './customDesignForm/list/list.component';
import { CustomDesignReportListComponent } from './customDesignReport/list/list.component';

export const routes: Routes = [
  {
    path: 'general-design',
    data: {
      breadcrumb: 'General Design'
    },
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: GeneralDesignListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'generalDesign.read'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'custom-design-form',
    data: {
      breadcrumb: 'Custom Design Form'
    },
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: CustomDesignFormListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'marketingSupportCustomDesignForm.read'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'custom-design-report',
    data: {
      breadcrumb: 'Custom Design Report'
    },
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: CustomDesignReportListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'marketingSupportCustomDesignReport.read'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingSupportRoutingModule {}
