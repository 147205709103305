import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKRestService } from "../spk-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-verification-modal',
  templateUrl: 'verification-modal.component.html',
  styleUrls: ['../spk-component.scss']
})
export class VerificationModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
    this.initFormVerification();
  }

  isLoading: boolean = false;
  formVerification: FormGroup;
  isVerificationModalOpen: boolean = false;

  previewBase64Image: any;
  selectedFileName: string;
  fileSize: any;

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      reader.onload = () => {
        this.previewBase64Image = reader.result as string
      };

      reader.readAsDataURL(file);

      this.formVerification.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formVerification.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  initFormVerification() {
    this.formVerification = new FormGroup({
      file: new FormControl('', [Validators.required]),
    });
  }

  async openVerificationModal() {
    this.isVerificationModalOpen = true;

    const verification = this.spk.verification;

    if (verification && ['confirmed', 'waiting confirmation'].includes(verification.status)) {
      const payload = { storageFileName: verification.file?.storageFileName }
      this.previewBase64Image = verification?.file?.storageFileName;
      await this._spkListComponent.renderPresignedUrlImage(payload, '#js-verificationFilePreview');
    }
  }

  closeVerificationModal() {
    this.isVerificationModalOpen = false;
    this.formVerification.reset();
    this.resetFileImg();
  }

  async onUpdateStatus(status: string) {
    Swal.fire({
      title: status === 'confirmed' ? 'Terima Bukti?' : 'Tolak Bukti?',
      text: 'Status verifikasi akan diupdate',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.updateStatus(status);
      } else {
        return;
      }
    });
  }

  async updateStatus(status: string) {
    const payload = {
      spkId: this.spk._id,
      verificationId: this.spk.verificationId,
      status,
    }

    this.isLoading = true;
    await this._SPKRest
      .updateVerificationStatus(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Verification status update successfully!',
            '',
            'success'
          )

          this._spkListComponent.callHookDirectly('init');
          this.closeVerificationModal();
        }
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Oops..',
          'Something went wrong!',
          'error'
        )
      });
    this.isLoading = false;
  }

  async updateVerification() {
    if (!this.formVerification.valid) {
      CommonService.markAsDirty(this.formVerification);
    } else {
      const formValues = this.formVerification.value;
      const payload = new FormData();
      payload.append('spkId', this.spk._id);
      payload.append('file', formValues.file);

      this.isLoading = true;

      await this._SPKRest
        .updateVerification(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Upload Verification successfully!',
              '',
              'success'
            )

            this._spkListComponent.callHookDirectly('init');
            this.closeVerificationModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}