import { filter } from 'rxjs/operators';
// ref: http://valor-software.com/ng2-table/
import { BehaviorSubject } from 'rxjs';

export class GridTablePagerConfig {
  public pageNum = 1;
  public totalItems = 0;
  public itemsPerPage = 10;
  public maxPageSize = 5;

  public standardTotalCounts = [10, 20, 30, 50, 100];

  updateFromApiPaginationResult<T>(p: IApiPaginationResult<T>) {
    if (p === undefined) {
      return;
    }

    if (p.total > 0) {
      this.totalItems = p.total;
    } else if (p.data && p.data.length) {
      this.totalItems = p.data.length;
    } else {
      this.totalItems = 0;
    }

    if (this.itemsPerPage > 0) {
      if (p.skip) {
        this.pageNum = p.skip / this.itemsPerPage + 1 || 1; // convert to 32 bit int
      } else {
        return;
      }
    } else {
      this.pageNum = 1;
    }
  }
}

export class GridTableDataSource<T> {
  private _data$: BehaviorSubject<T[]> = new BehaviorSubject(undefined);
  public get data() {
    return this._data$.value;
  }
  public set data(newDataValue: T[]) {
    this._data$.next(newDataValue);
  }

  public pager: GridTablePagerConfig = new GridTablePagerConfig();

  updateFromApiPaginationResult(p: IApiPaginationResult<T>) {
    if (p === undefined) {
      return;
    }
    this.data = p.data;
    this.pager.updateFromApiPaginationResult(p);
  }

  onDataChange() {
    return this._data$.pipe(filter(value => value !== undefined));
  }
}
