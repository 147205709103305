import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  // styleUrls: ['modal.component.css']
})
export class ModalComponent {
  @Input() isModalOpen: boolean;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() size: string = 'medium';

  onCloseModal() {
    this.closeModal.emit();
  }
}
