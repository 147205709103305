import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../../libraries/base/page/base-page.component';
import { PhotoListComponent } from './list-photo/photo-list.component';
import { ProductPriceListComponent } from './list-price/product-price-list.component';
import { ProductVariantListComponent } from './list-variant/product-variant-list.component';
import { ProductSparepartListComponent } from './list-sparepart/product-list.component';
import { ProductUpdateComponent } from './update-product/update-product.component';

const productChildRoutes = [
  {
    path: ':id/update-product',
    component: BasePageComponent,
    data: {
      breadcrumb: 'Update Product'
    },
    children: [
      {
        path: '',
        component: ProductUpdateComponent,
        canActivate: [AuthorizationService],
        data: {
          name: 'product.update'
        }
      }
    ]
  },
  {
    path: ':id/list-variant',
    component: BasePageComponent,
    data: {
      breadcrumb: 'List Variant'
    },
    children: [
      {
        path: '',
        component: ProductVariantListComponent,
        canActivate: [AuthorizationService],
        data: {
          name: 'product.update'
        }
      },
      {
        path: ':idProd/list-price/:idVariant',
        component: BasePageComponent,
        data: {
          breadcrumb: 'List Price'
        },
        children: [
          {
            path: '',
            component: ProductPriceListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'product.update'
            }
          }
        ]
      },
      {
        path: ':idProd/list-photo/:idVariant',
        component: BasePageComponent,
        data: {
          breadcrumb: 'List Photo'
        },
        children: [
          {
            path: '',
            component: PhotoListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'product.update'
            }
          }
        ]
      }
    ]
  }
];

export const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Sparepart'
    },
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: ProductSparepartListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'product.read',
            }
          }
        ],
      },
      ...productChildRoutes
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {}
