import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { CustomerRestService } from '../customer-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-customer-list',
  templateUrl: 'customer-list.component.html'
})
export class CustomerListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.customer.entity.name',
      field: '',
      name: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.customer.entity.customerNo',
      field: 'phoneNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.customer.entity.email',
      field: '',
      name: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.customer.entity.createdDate',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.customer.entity.address',
      field: '',
      name: 'address'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  detailModal: boolean = false;
  detailAddress: any;
  isLoadingExportExcel: boolean = false;

  constructor(
    private _CustomerRest: CustomerRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'CustomerListComponent';
    this.headerTitle = 'ui.customer.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        delete qOption['page'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._CustomerRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'CustomerListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._CustomerRest.findAll({}).toPromise();
      const mapOption = {
        name: { header: 'Nama' },
        phone: { header: 'No WA' },
        email: { header: 'Email' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      this._export.export({
        extension: 'xlsx',
        fileName: 'Pelanggan',
        records: result.data,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  showDetailDialog(value) {
    this.getDetailAddress(value);
    this.detailModal = true;
  }

  closeDetailDialog() {
    this.detailAddress = null;
    this.detailModal = false;
  }

  async getDetailAddress(value) {
    const result = await this._CustomerRest
      .getAddress(value)
      .toPromise()
      .then(response => {
        if (response) {
          console.log(response);
          this.detailAddress = response;
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  actionDeleteCustomer(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteCustomerData(value);
      } else {
        return;
      }
    });
  }

  async deleteCustomerData(value) {
    const result = await this._CustomerRest
      .deleteCustomer(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'ui.customer.list.successDelete'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }
}
