import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { APP_CONST } from "../../../../app.const";

@Component({
  selector: 'app-assessment-card-view',
  templateUrl: 'assessment-view.component.html',
  styleUrls: ['../../buyback.component.scss']
})
export class AssessmentCardViewComponent {
  @Input() id: number;
  @Input() component: string;
  @Input() info: string;
  @Input() data: any; // Accept the form group as input
}