import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../../libraries/base/page/base-page.component';
import { RegionDealerListComponent } from './list-region-dealer/region-dealer-list.component';

export const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Region Dealer'
    },
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: RegionDealerListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'product.read',
            }
          }
        ],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegionDealerRoutingModule {}
