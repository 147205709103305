import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { BannerRestService } from '../banner-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-banner-update',
  templateUrl: 'banner-update.component.html'
})
export class BannerUpdateComponent extends BaseUpdateBComponent {
  constructor(private _bannerRest: BannerRestService) {
    super();
    this.componentId = 'BannerUpdateComponent';
    this.routeURL = '/banner';
    this.entrySuccessI18n = 'success.banner.update';
    this.headerTitle = 'ui.banner.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._bannerRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            doc.periodeStart = new Date(doc.periodeStart);
            doc.periodeEnd = new Date(doc.periodeEnd);
            if (doc.mainImage) {
              doc.mainImage.id = doc?.mainImage?._id;
            }
            return of(doc);
          })
        );
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._bannerRest.update(this.comp.routeParams.id, formValue);
      },

      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'BannerFormComponent:workflowBuildForm',
          'BannerUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
