import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { RoleListComponent } from './list/role-list.component';
import { RoleCreateComponent } from './create/role-create.component';
import { RoleUpdateComponent } from './update/role-update.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: RoleListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'role.read'
            }
          }
        ]
      },
      {
        path: 'create',
        component: BasePageComponent,
        data: {
          breadcrumb: 'Add Role'
        },
        children: [
          {
            path: '',
            component: RoleCreateComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'role.create'
            }
          }
        ]
      },
      {
        path: ':id/update',
        component: BasePageComponent,
        data: {
          breadcrumb: 'Update Role'
        },
        children: [
          {
            path: '',
            component: RoleUpdateComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'role.update'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule {}
