import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { DealerRestService } from '../../../dealer/dealer-rest.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
// import { DataFormatService } from '../../../libraries/common/data-format.service';
import * as moment from 'moment';

@Component({
  selector: 'app-product-price-list',
  templateUrl: 'product-price-list.component.html'
})
export class ProductPriceListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.region',
      field: 'region',
      sort: true,
    },
    {
      i18nLabel: 'ui.product.entity.fullPayment',
      field: 'priceFullPayment',
      sort: true,
    },
    {
      i18nLabel: 'ui.product.entity.bookingFee',
      field: 'priceBookingFee',
      sort: true,
    },
    {
      i18nLabel: 'ui.product.entity.dpCredit',
      field: 'priceDPCredit',
      sort: true,
    },
    {
      i18nLabel: 'ui.product.entity.priceSubsidy',
      field: 'priceSubsidy',
      sort: true,
    },
    // {
    //   i18nLabel: 'ui.product.entity.dealerName',
    //   field: 'dealerName',
    //   sort: true,
    // },
    // {
    //   i18nLabel: 'ui.product.entity.dealerRegion',
    //   field: 'dealerRegion',
    //   sort: true,
    // },
    {
      i18nLabel: 'Last Update',
      field: 'updatedAt',
      sort: true,
    }
  ];
  tableColumnsShow = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  modalCreate: boolean = false;
  modalUpdateAll: boolean = false;
  formModalCreate: FormGroup;
  formModalUpdateAll: FormGroup;
  breadcrumbs: { label: string; url: string }[];
  prodId: any;
  varId: any;
  priceId: any;
  nameProduct: any;
  regionsOption: any[] = [];
  dealers: any[] = [];

  constructor(
    private _ProductRest: ProductRestService,
    private _DealerRest: DealerRestService,
    private route: ActivatedRoute,
    private _translateService: TranslateService
  ) {
    super();
    this.createFormProduct();

    this.componentId = 'ProductPriceListComponent';
    this.headerTitle = 'ui.product.update.updatePrice';
    this.titlePage = '';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.banner.delete';

    this.route.params.subscribe(res => {
      this.varId = res.idVariant;
      this.prodId = res.idProd;
    });

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.product.create.titlePrice',
        color: 'primary-outline',
        onClick: () => this.openDialogCreate()
      }
    ];
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/sparepart'
      },
      // {
      //   label: 'ui.product.list.titleVariant',
      //   url: `/product/${this.prodId}/list-variant`
      // },
      // {
      //   label: 'ui.product.update.updatePrice',
      //   url: `/product/${this.prodId}/list-variant/list-price/${this.varId}?idProd=${this.prodId}`
      // }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: (event) => {
        const qOption = {...event.data.qOption};
        const qParams = {
          ...this.qParams,
          ...qOption,
        }
        return this._ProductRest.findVariantById(this.prodId, this.varId, qParams).pipe(
          switchMap(variant => {
            const colorNameBreadrcumb = {
              label: variant.nameColor,
              url: `/sparepart/${this.prodId}/list-variant/${this.prodId}/list-price/${variant._id}`
            };
            // Check if productName already exists in the breadcrumbs array
            const existingColorOnBreadcrumb = this.breadcrumbs.find(
              breadcrumb => breadcrumb.label === colorNameBreadrcumb.label
            );
            //add to last breadcrumb
            if (!existingColorOnBreadcrumb) {
              this.breadcrumbs.splice(2, 0, colorNameBreadrcumb);
            }
            const dataMapped = variant.price?.map(item => ({
              ...item,
              updatedAt: moment(variant?.updatedAt)
                .utcOffset('+0700')
                .format('DD MMMM YYYY HH:mm')
            }))
            return of({ data: dataMapped });
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataProduct',
      handle: () => {
        return this._ProductRest.loadProduct(this.prodId).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nameProduct = res.name;
        if (!this.titlePage) {
          this.titlePage = this.nameProduct;
        }
        const productName = {
          label: `${this.nameProduct}`,
          url: `/sparepart/${this.prodId}/list-variant`
        };

        // Check if productName already exists in the breadcrumbs array
        const existingProduct = this.breadcrumbs.find(
          breadcrumb => breadcrumb.label === productName.label
        );
        if (!existingProduct) {
          this.breadcrumbs.splice(1, 0, productName);
        }
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataDealers',
      handle: event => {
        // const qOption = {...event.data.qOption};
        // const qParams = {
        //   ...this.qParams,
        //   ...qOption,
        // };
        return this._DealerRest.findAll({}).subscribe((data) => {
          const dataFormatted = data?.data?.map(d => {
            d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
            return d;
          });
          this.dealers = dataFormatted;
        })
      }
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductPriceListComponent:workflowLoadData',
          'ProductPriceListComponent:loadDataProduct',
          'ProductPriceListComponent:loadDataDealers'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.modalCreate = true;
  }

  openDialogUpdate(price) {
    this.getPriceData(price);
    this.modalCreate = true;
  }

  actionDeletePrice(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deletePriceData(value);
      } else {
        return;
      }
    });
  }

  async createPriceData(value) {
    const result = await this._ProductRest
      .createProductPrice(this.prodId, this.varId, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.createPrice'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async getPriceData(price) {
    if (price) {
      this.priceId = price._id;
      this.formModalCreate.patchValue({
        region: price.region,
        priceBookingFee: price.priceBookingFee,
        priceDPCredit: price.priceDPCredit,
        priceFullPayment: price.priceFullPayment,
        priceSubsidy: price.priceSubsidy
        // dealerId: price.dealerId
      });
    }
  }

  async updatePriceData(value, priceId) {
    const result = await this._ProductRest
      .updateProductPrice(this.prodId, this.varId, priceId, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.updatePrice'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async hightlighThisPrice(priceId, isHighlighted) {
    try {
      const toggleHighlightValue = { isHighlight: !isHighlighted };
      const response = await this._ProductRest
        .highlightPrice(this.prodId, this.varId, priceId, toggleHighlightValue)
        .toPromise();
      if (response) {
        this.callHookDirectly('init');
        this.comp._globalSystemMessage.log({
          message: this.comp._translate.instant(
            toggleHighlightValue.isHighlight == true
              ? 'success.product.highlightPrice'
              : 'success.product.clearHighlightPrice'
          ),
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
          scroll: true
        });
      }
    } catch (error) {
      this.comp._systemMessage.log({
        message: 'Error Toggle Highlight',
        type: 'error',
        scroll: true
      });
    }
  }

  async deletePriceData(value) {
    const result = await this._ProductRest
      .deleteProductPrice(this.prodId, this.varId, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.deletePrice'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  createFormProduct() {
    this.formModalCreate = new FormGroup({
      region: new FormControl(''),
      priceFullPayment: new FormControl('', [Validators.required]),
      priceDPCredit: new FormControl('', [Validators.required]),
      priceBookingFee: new FormControl(''),
      priceSubsidy: new FormControl('')
      // dealerId: new FormControl('')
    });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    if (this.priceId) {
      this.updatePriceData(formValue, this.priceId);
      this.callHookDirectly('init');
    } else {
      this.createPriceData(formValue);
    }
    this.modalCreate = false;
    this.formModalCreate.reset();
    this.callHookDirectly('init');
  }

  closeModalCreate() {
    this.modalCreate = false;
    this.priceId = null;
    this.formModalCreate.reset();
  }
}
