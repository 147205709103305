import { Component, Input } from "@angular/core";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-payment-detail-modal',
  templateUrl: 'payment-detail-modal.component.html',
  styleUrls: ['../spk-lite-to-reguler-component.scss']
})
export class PaymentDetailModalComponent {
  @Input() spk: any;

  constructor(public _spkListComponent: SPKListComponent) {}

  isPaymentDetailModalOpen: boolean = false;

  openPaymentDetailModal() {
    this.isPaymentDetailModalOpen = true;
  }

  closePaymentDetailModal() {
    this.isPaymentDetailModalOpen = false;
  }

  copyToClipboard(textToCopy: string) {
    const tempInput = document.createElement('input');
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    if (document.queryCommandSupported('copy')) {
      alert('Text copied to clipboard: ' + textToCopy);
    }
  }
}