import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class BatteryRestService {
  baseURL = `${APP_CONST.API_MASTER}/`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/admin/battery`, { params: queryParams });
  }

  update(id, payload) {
    return this.request.patch(`/admin/battery/${id}`, payload);
  }
}
