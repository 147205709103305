import { Component } from '@angular/core';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../buyback.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class StatusInfoModal {
  statusList = [
    {
      title: "Draft",
      description: "Dokumen masih belum lengkap dan belum disubmit."
    },
    {
      title: "Pengajuan Dokumen",
      description: "Setelah MD melakukan pengajuan, dokumen menunggu untuk ditinjau oleh tim pusat."
    },
    {
      title: "Pengajuan Appraisal",
      description: "Dokumen sudah di approve oleh tim pusat dan selanjutnya MD dapat mendownload dokumen appraisal "
    },
    {
      title: "Pengajuan Pembayaran",
      description: "Setelah dokumen di-approve oleh tim pusat, MD mengunggah dokumen tambahan (berupa persetujuan dari customer yg sudah di ttd) dan mengirimkan kembali untuk proses oleh tim finance."
    },
    {
      title: "Finance Review",
      description: "On Process: Jika dokumen baru disubmit.\nDone: Jika dokumen diterima, proses dapat dilanjutkan ke pembayaran.\nRevised: Jika ada yang perlu diperiksa lebih lanjut, dokumen akan diulas kembali."
    },
    {
      title: "Pencairan Komisi",
      description: "Jika tim finance menyetujui dokumen, status diubah menjadi selesai dan transaksi dianggap lengkap."
    },
    {
      title: "Expired",
      description: "Dokumen sudah disubmit dan disetujui, namun belum ada konfirmasi kembali dari MD untuk mengupload dokumen appraisal selama 7 hari."
    }
  ] 

  isOpen: boolean = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
