import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKRestService } from '../../spk-battery-rest.service';
import { CommonService } from '../../../../libraries/libraries.module';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../../list/spk-list.component';

type BatteryType = {
  batteryType: string;
  total: number;
  chassisNumber: string;
  batteryId: string;
  description: string;
}

@Component({
  selector: 'app-form-update-batteries',
  templateUrl: 'form-update-batteries.component.html',
  styleUrls: ['../../spk-battery-component.scss']
})
export class FormUpdateBatteriesComponent {
  @Input() batteries: BatteryType[];
  @Input() spk: any;
  @Output() onClose = new EventEmitter();

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {}

  batteryTypes = [
    {
      title: 'Online',
      value: 'online'
    },
    {
      title: 'Offline',
      value: 'offline'
    },
  ];
  formUpdateBatteries: FormGroup;
  batteryItems: any[] = [];
  loadingUpdateBatteries: boolean = false;

  stockValidator(initialStock: number) {
    return (control: FormControl): { [key: string]: any } | null => {
      const currentStock = control.value;
      if (currentStock > initialStock) {
        return { 'invalidStock': true };
      }
      return null;
    };
  }

  createBatteryFormGroup(data?: BatteryType): FormGroup {
    return new FormGroup({
      chassisNumber: new FormControl(data?.chassisNumber || ''),
      batteryType: new FormControl(data?.batteryType || '', [Validators.required]),
      total: new FormControl(data?.total || '', [Validators.required, this.stockValidator(data?.total || 0)]),
      description: new FormControl(data?.description || ''),
      batteryId: new FormControl(data?.batteryId || '', [Validators.required])
    });
  }

  onBatterySelected(battery, index) {
    const batteryId = battery?.value?.batteryId;
    const selectedBattery = this.batteryItems?.find(item => item._id == batteryId);
    const selectedBatteryStock = selectedBattery?.stock;
    const selectedBatteryType = selectedBattery?.batteryType;

    const batteryFormGroup = this.formUpdateBatteries?.get('batteries')?.get(index.toString()) as FormGroup;
    const totalFormControl = batteryFormGroup.get('total');

    // Set battery name
    batteryFormGroup.get('batteryType')?.setValue(selectedBatteryType);

    // Update the initialStock property in the form control
    totalFormControl?.setValidators([Validators.required, this.stockValidator(selectedBatteryStock)]);
    totalFormControl?.updateValueAndValidity();
  }

  initFormUpdateBatteries() {
    this.formUpdateBatteries = new FormGroup({
      batteries: new FormArray([
        ...this.batteries?.map(battery => this.createBatteryFormGroup(battery))
      ])
    });
  }

  addBattery() {
    const batteries = this.formUpdateBatteries.get('batteries') as FormArray;
    batteries.push(this.createBatteryFormGroup());
  }

  removeBattery(index: number) {
    const batteries = this.formUpdateBatteries.get('batteries') as FormArray;
    batteries.removeAt(index);
  }

  get batteriesControls() {
    return (this.formUpdateBatteries.get('batteries') as FormGroup).controls;
  }

  validSPKStatus() {
    return !['expired', 'rejected'].includes(this.spk?.status)
  }

  async getUnitsBattery() {
    await this._SPKRest.getBatteries().subscribe((data: any) => {
      this.batteryItems = data?.map(item => {
        const currentUserBattery = this.batteries?.find(currentBattery => currentBattery.batteryId === item._id);

        return {
          ...item,
          batteryType: item.batteryType,
          stock: (currentUserBattery && this.validSPKStatus()) ? Number(item.stock) + currentUserBattery.total : item.stock
        };
      });

      // Insert empty batteries to form update battery
      const inStockBatteries = data?.map(item => item._id);
      const emptyStockBatteries = this.batteries.filter(battery => !inStockBatteries.includes(battery.batteryId));

      this.batteryItems.push(...emptyStockBatteries.map(item => ({
        ...item,
        _id: item.batteryId,
        batteryType: item.batteryType,
        stock: item.total,
      })));
    });
  }

  onCloseUpdateBatteries() {
    this.onClose.emit();
  }

  onUpdateBatteries() {
    console.log(this.formUpdateBatteries);
    if (!this.formUpdateBatteries.valid) {
      CommonService.markAsDirty(this.formUpdateBatteries);
    } else {
      Swal.fire({
        title: 'Update Units?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateBatteries();
        }
      });
    }
  }

  async updateBatteries() {
    const payload = {
      spkId: this.spk?._id,
      batteries: this.formUpdateBatteries.value.batteries
    };

    this.loadingUpdateBatteries = true;
    this._SPKRest
      .updateUnitsBattery(payload)
      .toPromise()
      .then((response: any) => {
        if (!!response?.invalid) {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid Battery Stock',
            html: response?.invalidStockBattery?.map(item => `
            <div class="text-left">
                <div>Battery Type: ${item.batteryType}</div>
                <div>Total: ${item.total}</div>
                <div>Current Stock: ${item.currentStock}</div>
                <hr/>
            </div>
            `).join(''),
            confirmButtonText: 'OK'
          });
        } else {
          this._spkListComponent.callHookDirectly('init');
          Swal.fire(
            'Update units Successfully!',
            '',
            'success'
          )
        }
      })
      .catch(error => {
        const errorData = { ...error };
        Swal.fire(
          'Gagal',
          errorData?.response?.data?.message,
          'error'
        )
      })
      .finally (() => {
        this.loadingUpdateBatteries = false;
        this.onCloseUpdateBatteries();
      })
  }

  ngOnInit(): void {
    this.initFormUpdateBatteries();
    this.getUnitsBattery();
  }
}