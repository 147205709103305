import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { SgbMitraListComponent } from './list/sgb-mitra-list.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: BasePageComponent,
        children: [
          {
            path: '',
            component: SgbMitraListComponent,
            canActivate: [AuthorizationService],
            data: {
              name: 'mitraSgb.read'
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SgbMitraRoutingModule {}
