import { Component, SimpleChanges } from '@angular/core';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { BannerRestService } from '../banner-rest.service';
import { APP_CONST } from '../../../app.const';
import { FormGroup } from '@angular/forms';
import { MFormgenSchemaField } from '../../../libraries/formgen/formgen.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-banner-form',
  templateUrl: 'banner-form.component.html'
})
export class BannerFormComponent extends BaseFormBComponent {
  datePeriodeStart: any;
  datePeriodeEnd: any;
  constructor(private _bannerRest: BannerRestService) {
    super();

    this.componentId = 'BannerFormComponent';
  }

  uploadEndpoint = APP_CONST.API_FILE;
  ckEditorConfig = {
    fileBrowserUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Files&responseType=json`,
    fileBrowserImageUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Images&responseType=json`
  };

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.banner.entity.name',
            name: 'title',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            name: 'mainImageId',
            ignore: true
          },
          {
            name: 'mainImage',
            ignore: true
          },
          {
            name: 'imgUrlDesktop',
            ignore: true
          },
          {
            name: 'imgUrlMobile',
            ignore: true
          },
          {
            label: 'ui.banner.entity.sequenceBanner',
            name: 'sequence',
            field: 'select',
            templateConfig: {
              select: {
                options: ['1', '2', '3', '4', '5', '6', '7', '8'].map(i => ({
                  directLabel: i,
                  value: i
                }))
              }
            },
            validations: {
              required: true
            }
          },
          {
            label: 'ui.banner.entity.periodStart',
            name: 'periodeStart',
            field: 'date',
            templateConfig: {
              calendar: {
                maxDate: new Date(this.datePeriodeEnd)
              }
            },
            validations: {
              required: true
            },
            fieldEvents: {
              onSelect: (value: any, field: MFormgenSchemaField, form: FormGroup) => {
                this.datePeriodeStart = value;
                this.changeDateStart();
              }
            }
          },
          {
            label: 'ui.banner.entity.periodEnd',
            name: 'periodeEnd',
            field: 'date',
            templateConfig: {
              calendar: {
                minDate: new Date(this.datePeriodeStart)
              }
            },
            validations: {
              required: true
            },
            fieldEvents: {
              onSelect: (value: any, field: MFormgenSchemaField, form: FormGroup) => {
                this.datePeriodeEnd = value;
                this.changeDateStart();
              }
            }
          },
          {
            label: 'ui.banner.entity.url',
            name: 'url',
            field: 'text'
          }
        ];
      }
    });
  }

  onImageFinishUploaded(image, deviceType: string) {
    this.form.patchValue({
      mainImageId: image._id,
      ...(deviceType === 'desktop' && { imgUrlDesktop: image?.fileUrl }),
      ...(deviceType === 'mobile' && { imgUrlMobile: image?.fileUrl })
    });
  }

  onImageDeleted(deviceType: string) {
    if (deviceType === 'mobile') {
      this.form.patchValue({
        imgUrlMobile: null
      });
      return false;
    }

    const mainImageId = this.form.get('mainImageId')?.value;
    this._bannerRest.deleteImageById(mainImageId).subscribe();
    this.form.patchValue({
      mainImageId: null
    });
  }

  changeDateStart() {
    if (this.datePeriodeStart > this.datePeriodeEnd) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: this.comp._translate.instant('ui.banner.create.periodStartError')
      }).then(result => {
        if (result.isConfirmed) {
          this.datePeriodeStart = null;
          this.form.patchValue({
            periodeStart: null
          });
        }
      });
    }
    if (this.datePeriodeEnd < this.datePeriodeStart) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: this.comp._translate.instant('ui.banner.create.periodEndError')
      }).then(result => {
        if (result.isConfirmed) {
          this.datePeriodeEnd = null;
          this.form.patchValue({
            periodeEnd: null
          });
        }
      });
    }
  }
}
