import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKRestService } from "../spk-lite-to-reguler-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-shipping-cost',
  templateUrl: 'shipping-cost.component.html',
  styleUrls: ['../spk-lite-to-reguler-component.scss']
})
export class ShippingCostComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
    this.initFormShippingCost();
  }

  isLoading: boolean = false;
  formShippingCost: FormGroup;
  isModalOpen: boolean = false;

  initFormShippingCost() {
    this.formShippingCost = new FormGroup({
      shippingCost: new FormControl('', [Validators.required]),
    });
  }

  openModal() {
    this.formShippingCost.patchValue({
      shippingCost: this.spk.shippingCost
    });
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.formShippingCost.reset();
  }

  async updateShippingCost() {
    if (!this.formShippingCost.valid) {
      CommonService.markAsDirty(this.formShippingCost);
    } else {
      const payload = {
        ...this.formShippingCost.value,
        spkId: this.spk._id
      };
      this.isLoading = true;

      await this._SPKRest
        .updateShippingCost(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Update Shipping Information successfully!',
              '',
              'success'
            )

            this._spkListComponent.callHookDirectly('init');
            this.closeModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}