import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class CustomerRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/client-user`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  getAddress(userId: string) {
    return this.request.get<any>(`address/${userId}`);
  }

  deleteCustomer(userId: string) {
    return this.request.delete<any>(`${userId}`);
  }
}
