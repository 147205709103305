import { Component, Input } from '@angular/core';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../spk-cash-component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class StatusInfoModal {

  constructor(
    public _spkListComponent: SPKListComponent
  ){
    this.spkCashStatusList.splice(this.statuses.length - 1, 0, {
      icon: '../../../../assets/img/icons/po-delivered.svg',
      title: 'Delivered',
      description: 'Motor dalam proses pengiriman'
    });

    this.spkCashStatusList.push({
      icon: '../../../../assets/img/icons/rejected.svg',
      title: 'Rejected',
      description: 'SPK telah ditolak'
    });
  }

  isOpen: boolean = false;

  statuses = ['new', 'waiting payment', 'spk paid', 'expired'];
  spkCashStatusList = this._spkListComponent.statusList.filter(item => this.statuses.includes(item.title.toLowerCase()))

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
