import { Component, ElementRef, Input } from '@angular/core';
import { APP_CONST } from '../../../app.const';
import { BuybackRestService } from '../buyback-rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonService } from '../../../libraries/libraries.module';
import { BuybackListComponent } from '../list/buyback-list.component';

@Component({
  selector: 'app-document-submission',
  templateUrl: 'document-submission.component.html',
  styleUrls: ['../buyback.component.scss', './document-submission.component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class DocumentSubmission {
  @Input() data: any;
  form: FormGroup;
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';
  isOpen: boolean = false;
  step: number = 1;
  today: string;
  uploadEndpoint = APP_CONST.API_FILE;
  vehicleAssessments: any = [
    {
      "id": 1,
      "category": "Body",
      "component": "Cover body/Permukaan luar",
      "decrease_value": -6
    },
    {
      "id": 2,
      "category": "Body",
      "component": "Saddle/jok",
      "decrease_value": -3
    },
    {
      "id": 3,
      "category": "Body",
      "component": "Lamp & Reflector/ Lampu & Reflektor",
      "decrease_value": -3
    },
    {
      "id": 4,
      "category": "Body",
      "component": "Tire/Ban",
      "decrease_value": -6
    },
    {
      "id": 5,
      "category": "Body",
      "component": "Velg",
      "decrease_value": -6
    },
    {
      "id": 6,
      "category": "Body",
      "component": "Shockbreaker",
      "decrease_value": -6
    },
    {
      "id": 7,
      "category": "Body",
      "component": "Speedometer",
      "decrease_value": -6
    },
    {
      "id": 8,
      "category": "Electric",
      "component": "Switch/tombol",
      "decrease_value": -3
    },
    {
      "id": 9,
      "category": "Electric",
      "component": "Main Cabling / Kabel Body",
      "decrease_value": -6
    },
    {
      "id": 10,
      "category": "Supporting Tools",
      "component": "Rearview Mirror/Kaca spion",
      "decrease_value": -2
    },
    {
      "id": 11,
      "category": "Supporting Tools",
      "component": "Key/Kunci",
      "decrease_value": -2
    },
    {
      "id": 12,
      "category": "Supporting Tools",
      "component": "Remote & NFC",
      "decrease_value": -2
    },
    {
      "id": 13,
      "category": "Supporting Tools",
      "component": "Charger",
      "decrease_value": -9
    },
    {
      "id": 14,
      "category": "Battery",
      "component": "Test Ride",
      "decrease_value": -15
    },
    {
      "id": 15,
      "category": "Battery",
      "component": "Indicator",
      "decrease_value": -10
    },
    {
      "id": 16,
      "category": "Battery",
      "component": "Healthiness",
      "decrease_value": -15
    }
  ]
  isLoading: boolean = false;

  groupedAssessments: { category: string; components: any[] }[] = [];

  documentTypes = [
    { label: 'STNK', key: 'stnk' },
    { label: 'BPKB', key: 'bpkb' },
    { label: 'INVOICE', key: 'invoice' },
    { label: 'FAKTUR', key: 'faktur' },
    { label: 'KTP', key: 'ktp' },
  ];

  isPdfFile(fileUrl: string): boolean {
    return fileUrl.toLowerCase().endsWith('.pdf');
  }
  
  openPdf(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  ngOnInit() {
    this.groupAssessmentsByCategory();
    this.form = this.fb.group({
      _id: [this.data._id],
      statusAppraisal: ['', Validators.required],
      statusPengajuan: ['done'],
      statusDokumen: ['pengajuan appraisal'],
      dataDokumen: this.fb.group({
        note: [''],
        stnk: this.fb.group({
          available: this.data.dataDokumen?.stnk?.available,
          uploaded_file_url: [this.data.dataDokumen?.stnk?.uploaded_file_url]
        }),
        bpkb: this.fb.group({
          available: this.data.dataDokumen?.bpkb?.available,
          uploaded_file_url: [this.data.dataDokumen?.bpkb?.uploaded_file_url]
        }),
        invoice: this.fb.group({
          available: this.data.dataDokumen?.invoice?.available,
          uploaded_file_url: [this.data.dataDokumen?.invoice?.uploaded_file_url]
        }),
        faktur: this.fb.group({
          available: this.data.dataDokumen?.faktur?.available,
          uploaded_file_url: [this.data.dataDokumen?.faktur?.uploaded_file_url]
        }),
        ktp: this.fb.group({
          available: this.data.dataDokumen?.ktp?.available,
          uploaded_file_url: [this.data.dataDokumen?.ktp?.uploaded_file_url]
        })
      })
    })
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  async renderPresignedUrlImage(payload: any, elementId: string) {
    await this._BuybackRest.getPresignedUrl(payload).subscribe(data => {
      const photoPreview = this.el.nativeElement.querySelector(elementId);

      console.log(payload);

      if (photoPreview) {
        // photoPreview.src = data.url;
        // photoPreview.href = data.url;
        photoPreview.src = payload?.storageFileName;
        photoPreview.href = payload?.storageFileName;
      }
    }, error => {
      const photoPreview = this.el.nativeElement.querySelector(elementId);
      if (photoPreview) {
        photoPreview.src = '';
      }
    })
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(url, title) {
    const payload = { storageFileName: url }
    await this.renderPresignedUrlImage(payload, '#js-photoPreview');
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;
  }

  groupAssessmentsByCategory() {
    const groups = this.vehicleAssessments.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {} as { [key: string]: any[] });

    this.groupedAssessments = Object.keys(groups).map(category => ({
      category,
      components: groups[category]
    }));
  }

  constructor(
    private el: ElementRef,
    private _BuybackRest: BuybackRestService,
    private fb: FormBuilder,
    public _buybackListComponent: BuybackListComponent
  ) {
    this.today = new Date().toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
  }


  onUpdateStatusAppraisal() {
    console.log('test');
    if (this.form.invalid) {
     
      console.log(this.form.value);
      // Create an alert message
      const alertMessage = `Data belum lengkap`;
      
      // Show SweetAlert2 alert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: alertMessage,
        confirmButtonText: 'OK'
      });
      return;
    }
    // this.generatePayload();
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      Swal.fire({
        title: 'Apakah anda yakin ingin mengupdate status apparisal?',
        text: 'Pastikan semua data terisi dengan benar',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateStatusAppraisal();
          console.log(this.form.value);
        }
      });
    }
  }

  async updateStatusAppraisal() {
    if (!this.form.valid) {
      CommonService.markAsDirty(this.form);
    } else {
      this.isLoading = true;

      await this._BuybackRest
        .update(this.form.value)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Update Status Failed',
              confirmButtonText: 'OK'
            });
          } else {
            Swal.fire(
              'Update Status Success!',
              '',
              'success'
            )

            this._buybackListComponent.callHookDirectly('init');
            this.closeModal();
            this.step = 1;
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }

  openModal() {
    this.isOpen = true;
    console.log(this.data);
  }

  closeModal() {
    this.isOpen = false;
  }

  prevStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }

  clearForm() {
    console.log("this function is for clear form")
  }

  submitPengajuan() {
    alert("submission submitted!");
    this.closeModal();
    this.clearForm();
    this.step = 1;
  }
}
