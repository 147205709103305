import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKRestService } from "../spk-cash-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";
import * as moment from 'moment';

@Component({
  selector: 'app-delivery-date-modal',
  templateUrl: 'delivery-date-modal.component.html',
  styleUrls: ['../spk-cash-component.scss']
})
export class DeliveryDateModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
    this.initFormDeliveryDate();
  }

  isLoading: boolean = false;
  formUpdateDeliveryDate: FormGroup;
  isDeliveryDateModalOpen: boolean = false;

  initFormDeliveryDate() {
    this.formUpdateDeliveryDate = new FormGroup({
      deliveryDate: new FormControl('', [Validators.required]),
      status: new FormControl('')
    });
  }

  openDeliveryDateModal() {
    this.formUpdateDeliveryDate.patchValue({
      deliveryDate: this.spk?.deliveryDate ? this.toDateInput(this.spk?.deliveryDate) : '',
      status: this.spk?.status
    });
    this.isDeliveryDateModalOpen = true;
  }

  toDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  toDateInput(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  closeDeliveryDateModal() {
    this.isDeliveryDateModalOpen = false;
    this.formUpdateDeliveryDate.reset();
  }

  async updateDeliveryDate() {
    if (!this.formUpdateDeliveryDate.valid) {
      CommonService.markAsDirty(this.formUpdateDeliveryDate);
    } else {
      const payload = {
        ...this.formUpdateDeliveryDate.value,
        spkId: this.spk._id
      };
      this.isLoading = true;

      await this._SPKRest
        .updateDeliveryDate(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Update Delivery Date successfully!',
              '',
              'success'
            )

            this._spkListComponent.callHookDirectly('init');
            this.closeDeliveryDateModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}