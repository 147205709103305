import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SPKRestService } from "../spk-lite-to-reguler-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";
import { CommonService } from "../../../libraries/libraries.module";

@Component({
  selector: 'app-proof-of-payment-settlement-modal',
  templateUrl: 'proof-of-payment-settlement-modal.component.html',
  styleUrls: ['../spk-lite-to-reguler-component.scss']
})
export class ProofOfPaymentSettlementModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
    this.initFormPoliceNumber();
  }

  isLoading: boolean = false;
  formUpdateProofOfPayment: FormGroup;
  isProofOfPaymentModalOpen: boolean = false;

  previewBase64Image: any;
  selectedFileName: string;
  fileSize: any;

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      reader.onload = () => {
        this.previewBase64Image = reader.result as string
      };

      reader.readAsDataURL(file);

      this.formUpdateProofOfPayment.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formUpdateProofOfPayment.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  initFormPoliceNumber() {
    this.formUpdateProofOfPayment = new FormGroup({
      bankName: new FormControl('', [Validators.required]),
      accountNumber: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    });
  }

  async openProofOfPaymentModal() {
    this.enableForm();
    this.isProofOfPaymentModalOpen = true;

    if (['waiting confirmation', 'paid'].includes(this.spk?.proofOfPaymentSettlement?.status)) {
      this.formUpdateProofOfPayment.patchValue({ bankName: this.spk.proofOfPaymentSettlement.bankName });
      this.formUpdateProofOfPayment.patchValue({ accountNumber: this.spk.proofOfPaymentSettlement.accountNumber });
      const proofOfPayment = this.spk.proofOfPaymentSettlement;
      const payload = { storageFileName: proofOfPayment.file?.storageFileName }
      this.previewBase64Image = proofOfPayment?.file?.storageFileName;
      await this._spkListComponent.renderPresignedUrlImage(payload, '#js-ProofOfPaymentSettlementFilePreview');
      this.disableForm();
    }
  }

  disableForm() {
    this.formUpdateProofOfPayment.get('bankName')?.disable();
    this.formUpdateProofOfPayment.get('accountNumber')?.disable();
  }

  enableForm() {
    this.formUpdateProofOfPayment.get('bankName')?.enable();
    this.formUpdateProofOfPayment.get('accountNumber')?.enable();
  }

  closeProofOfPaymentModal() {
    this.isProofOfPaymentModalOpen = false;
    this.formUpdateProofOfPayment.reset();
    this.resetFileImg();
  }

  async onUpdateStatus(status: string) {
    Swal.fire({
      title: status === 'paid' ? 'Terima Pembayaran?' : 'Tolak Pembayaran?',
      text: 'Status pembayaran akan diupdate',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.updateStatus(status);
      } else {
        return;
      }
    });
  }

  async updateStatus(status: string) {
    const payload = {
      spkId: this.spk._id,
      proofOfPaymentId: this.spk.proofOfPaymentSettlementId,
      status,
    }

    this.isLoading = true;
    await this._SPKRest
      .updateProofOfPaymentSettlementStatus(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Proof of Payment Settlement status update successfully!',
            '',
            'success'
          )

          this._spkListComponent.callHookDirectly('init');
          this.closeProofOfPaymentModal();
        }
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Oops..',
          'Something went wrong!',
          'error'
        )
      });
    this.isLoading = false;
  }

  async updateProofOfPayment() {
    if (!this.formUpdateProofOfPayment.valid) {
      CommonService.markAsDirty(this.formUpdateProofOfPayment);
    } else {
      const formValues = this.formUpdateProofOfPayment.value;
      const payload = new FormData();
      payload.append('spkId', this.spk._id);
      payload.append('bankName', formValues.bankName);
      payload.append('accountNumber', formValues.accountNumber);
      payload.append('file', formValues.file);

      this.isLoading = true;

      await this._SPKRest
        .updateProofOfPaymentSettlement(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Upload Proof of Payment Settlement successfully!',
              '',
              'success'
            )

            this._spkListComponent.callHookDirectly('init');
            this.closeProofOfPaymentModal();
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.isLoading = false;
    }
  }
}