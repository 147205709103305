import { Component, Input, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { tap } from 'rxjs/operators';
import { APP_CONST } from '../../../app.const';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { MFormgenSchemaField } from '../../../libraries/formgen/formgen.model';
import { PromoRestService } from '../promo-rest.service';

@Component({
  selector: 'app-promo-form',
  templateUrl: 'promo-form.component.html'
})
export class PromoFormComponent extends BaseFormBComponent {
  constructor(private _promoRest: PromoRestService) {
    super();

    this.componentId = 'PromoFormComponent';
  }

  uploadEndpoint = APP_CONST.API_FILE;
  ckEditorConfig = {
    fileBrowserUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Files&responseType=json`,
    fileBrowserImageUploadUrl: `${this.uploadEndpoint}/?command=QuickUpload&type=Images&responseType=json`
  };

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            name: 'mainImageId',
            ignore: true
          },
          {
            name: 'mainImage',
            ignore: true
          },
          {
            label: 'ui.promo.entity.title',
            name: 'title',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.promo.entity.description',
            name: 'description',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.promo.entity.termsCondition',
            name: 'termsCondition',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.promo.entity.period',
            name: 'period',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.promo.entity.linkCTA',
            name: 'linkCTA',
            field: 'text',
            validations: {
              required: false
            }
          },
          {
            label: 'ui.promo.entity.labelCTA',
            name: 'labelCTA',
            field: 'text',
            defaultValue: '',
            validations: {
              required: false
            }
          },
        ];
      }
    });
  }

  matchPasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (this.form.get('password').value !== control.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }

  onImageFinishUploaded(image) {
    this.form.patchValue({
      mainImageId: image._id
    });
  }

  onImageDeleted() {
    const mainImageId = this.form.get('mainImageId').value;
    this._promoRest.deleteImageById(mainImageId).subscribe();

    this.form.patchValue({
      mainImageId: null
    });
  }
}
