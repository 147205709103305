import { Component } from '@angular/core';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';

@Component({
  selector: 'app-faq-form',
  templateUrl: 'faq-form.component.html'
})
export class FaqFormComponent extends BaseFormBComponent {
  constructor() {
    super();

    this.componentId = 'FaqFormComponent';
  }
  
  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.faq.entity.question',
            name: 'question',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.faq.entity.category',
            name: 'category',
            field: 'text'
          },
          {
            label: 'ui.faq.entity.lang',
            name: 'lang',
            field: 'select',
            validations: {
              required: true
            },
            templateConfig: {
              select: {
                options: [
                  {
                    directLabel: 'ID',
                    value: 'id'
                  },
                  {
                    directLabel: 'EN',
                    value: 'en'
                  }
                ]
              }
            }
          },
          {
            name: 'answer',
            ignore: true
          }
        ];
      }
    });
  }
}
