import { Component, Output, EventEmitter } from '@angular/core';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { PromoRestService } from '../promo-rest.service';

@Component({
  selector: 'app-promo-create',
  templateUrl: 'promo-create.component.html'
})
export class PromoCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(private _ptomoRest: PromoRestService) {
    super();
    this.componentId = 'PromoCreateComponent';
    this.routeURL = '/promo';
    this.entrySuccessI18n = 'success.promo.create';
    this.headerTitle = 'ui.promo.create.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;
        console.log(formValue)

        return this._ptomoRest.create(formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'PromoFormComponent:workflowBuildForm'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
