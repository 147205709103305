import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BannerRestService } from '../banner-rest.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: 'banner-list.component.html'
})
export class BannerListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.banner.entity.name',
      field: 'title',
      // sort: true,
    },
    {
      i18nLabel: 'ui.banner.entity.sequence',
      field: 'sequence'
    },
    {
      i18nLabel: 'ui.banner.entity.imageUrl',
      field: '',
      name: 'urlImage'
    },
    {
      i18nLabel: 'ui.banner.entity.publishPeriod',
      field: '',
      name: 'publishPeriod'
    },
    {
      i18nLabel: 'ui.banner.entity.createdDate',
      field: 'createdAt'
    },
    {
      i18nLabel: 'ui.banner.entity.status',
      field: '',
      name: 'status'
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );

  constructor(private _BannerRest: BannerRestService) {
    super();
    this.componentId = 'BannerListComponent';
    this.headerTitle = 'ui.banner.list.title';
    this.exportFileName = 'user';
    this.exportDocumentTitle = 'ui.banner.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.banner.delete';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.banner.list.button.create',
        color: 'primary-outline',
        routerLink: '/banner/create'
        // permissions: ['user.create']
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
          );

        return this._BannerRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              d.periodeStart = moment(d?.periodeStart).format('DD MMMM YYYY');
              d.periodeEnd = moment(d?.periodeEnd).format('DD MMMM YYYY');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const bannerId = event.data._id;
        return this._BannerRest.delete(bannerId);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'BannerListComponent:workflowLoadData'
        ])
    });

  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  countDate(start, end) {
    const nowDate = moment();
    const startDate = moment(start, 'DD MMMM YYYY', true).format();
    const endDate = moment(end, 'DD MMMM YYYY', true).add(1, 'days').format();
    const date1 = moment(nowDate).isAfter(startDate);
    const date2 = moment(nowDate).isBefore(endDate);
    if (date1 && date2) {
      return 'ui.status.active';
    } else {
      return 'ui.status.inactive';
    }
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
