import { Component, ElementRef, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../../libraries/common/common.service';
import { APP_CONST } from '../../../../../app.const';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-detail',
  templateUrl: 'update-detail.component.html'
})
export class ProductUpdateDetailComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.input.title',
      field: 'title'
    },
    {
      i18nLabel: 'ui.product.input.caption',
      field: 'caption'
    },
    {
      i18nLabel: 'ui.product.entity.photo',
      field: '',
      name: 'photo'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  formModalCreate: FormGroup;
  imageModal: boolean = false;
  photoDetail: string;

  cardImageBase64: any;
  selectedFileName: string;
  fileSize: any;

  constructor(private _ProductRest: ProductRestService) {
    super();
    this.createFormProductDetail();
    this.componentId = 'ProductUpdateDetailComponent';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.banner.delete';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._ProductRest
          .getProductDetail(this.comp.routeParams.id)
          .pipe(
            switchMap(doc => {
              const dataFormatted = doc?.map(d => {
                return d;
              });
              doc.data = dataFormatted;
              return of(doc);
            })
          );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductUpdateDetailComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  convertImg(url) {
    if (url.includes('/images/')) {
      const imgUrl = `${APP_CONST.API_COMPANY}${url}`;
      return imgUrl;
    } else {
      return url;
    }
  }

  showImageDialog(value) {
    this.photoDetail = this.convertImg(value);
    this.imageModal = true;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  createFormProductDetail() {
    this.formModalCreate = new FormGroup({
      title: new FormControl('', [Validators.required]),
      caption: new FormControl('', [Validators.required]),
      photo: new FormControl('', [Validators.required])
    });
  }

  actionDeleteProductDetail(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteProductDetailData(value);
      } else {
        return;
      }
    });
  }

  async createProductDetailData(valueData) {
    const formData = new FormData();
    formData.append('title', valueData.title);
    formData.append('caption', valueData.caption);
    formData.append('photo', valueData.photo);
    const result = await this._ProductRest
      .createProductDetail(this.comp.routeParams.id, formData)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.createDetail'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductDetailData(value) {
    const result = await this._ProductRest
      .deleteProductDetail(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.deleteDetail'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      const sizeLimit = 1048576;
      if (event.target.files[0].size > sizeLimit) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.comp._translate.instant('error.image.maxLimitProduct')
        });
        this.resetFileImg();
        return false;
      }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      reader.onload = () => {
        this.cardImageBase64 = reader.result as string;
      };

      reader.readAsDataURL(file);

      this.formModalCreate.patchValue({
        photo: file
      });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event: Event) {
    event.preventDefault();
    this.cardImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  resetFileImg() {
    this.cardImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    this.createProductDetailData(formValue);
    this.showModalCreate = false;
    this.formModalCreate.reset();
  }

  closeModalCreate() {
    this.showModalCreate = false;
    this.resetFileImg();
    this.formModalCreate.reset();
  }
}
