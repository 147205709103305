export class ApiQueryPaging {
    public take = 10;
    public skip = 0;
    public page = 1;
    public includeTotalCount: boolean = false;
    public sortBy: string;
    public sortDir: string;

    constructor() {
    }
}

export class ApiQueryOption extends ApiQueryPaging {
    public sorts?: IApiQueryOptionSort[] = new Array<IApiQueryOptionSort>();
    public filter: IApiQueryOptionFilter[] = new Array<IApiQueryOptionFilter>();

    constructor() {
        super();
    }
}
