import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { AffiliatorClaimRestService } from '../affiliator-claim-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { AclService } from '../../../libraries/auth/acl.service';
import Swal from 'sweetalert2';
import * as pdfMake from 'pdfmake';

@Component({
  selector: 'app-affiliator-claim-list',
  templateUrl: 'affiliator-claim-list.component.html'
})
export class AffiliatorClaimListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.affiliatorClaim.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.affiliator',
      name: 'affiliator',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.status',
      name: 'status',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.buyerName',
      field: 'buyerName',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.orderDate',
      field: 'orderDate',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.motorType',
      field: 'motorType',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.trx',
      name: 'trx',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.picName',
      field: 'picName',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.picPhone',
      field: 'picPhone',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.dealer',
      name: 'dealer',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.bankBranch',
      field: 'bankBranch',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.vinNumber',
      field: 'vinNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliatorClaim.entity.statusHistory',
      field: '',
      name: 'statusHistory',
      sort: false
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isDealerModalOpen: boolean = false;
  dealerOpened: any = {};
  isAffiliatorModalOpen: boolean = false;
  affiliatorOpened: any = {};
  isTrxModalOpen: boolean = false;
  isExportPdfModalOpen: boolean = false;
  isLoadingExportPdf: boolean = false;
  trxOpened: any = [];
  isStatusHistoryModalOpen: boolean = false;
  statusHistoryOpened: any = {};
  dealerFilter = [
    {
      name: 'Semua Dealer',
      _id: ''
    }
  ];
  startDate: string = '';
  endDate: string = '';
  today: string;

  affiliatorClaimStatuses = [
    {
      text: 'Semua Status',
      value: ''
    },
    {
      text: 'Request',
      value: 'request'
    },
    {
      text: 'Waiting Payment',
      value: 'waiting payment'
    },
    {
      text: 'Processed',
      value: 'processed'
    },
    {
      text: 'Paid',
      value: 'paid'
    },
    {
      text: 'Rejected',
      value: 'rejected'
    }
  ];

  constructor(
    public _authentication: AuthenticationService,
    private _AffiliatorClaimRest: AffiliatorClaimRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    super();
    this.componentId = 'AffiliatorClaimListComponent';
    this.headerTitle = 'ui.affiliatorClaim.list.title';

    this.qParams.status = '';
    this.qParams.dealerId = '';
  }

  isDealership = this._authentication?.user?.role?.name?.toLowerCase() === 'dealership';

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._AffiliatorClaimRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.orderDate = moment(d?.orderDate).format('DD MMMM YYYY');
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['AffiliatorClaimListComponent:workflowLoadData'])
    });
  }

  getDealers() {
    this._AffiliatorClaimRest.getDealers().subscribe((response: any) => {
      const data = response?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      this.dealerFilter = [...this.dealerFilter, ...data];
    });
  }

  appOnInit() {
    super.appOnInit();
    this.getDealers();
    const now = new Date();
    this.today = now.toISOString().split('T')[0];

    return this.callHook('init');
  }

  // Date range validation for export pdf (max 7 days)
  validateDateRange() {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);

      const diffInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

      if (diffInDays > 7) {
        const maxEndDate = new Date(start);
        maxEndDate.setDate(start.getDate() + 7);
        this.endDate = maxEndDate.toISOString().split('T')[0];
      }

      if (new Date(this.endDate) > new Date(this.today)) {
        this.endDate = this.today;
      }
    }
  }

  openDealerModal(data) {
    this.isDealerModalOpen = true;
    this.dealerOpened = data;
  }

  closeDealerModal() {
    this.isDealerModalOpen = false;
  }

  openAffiliatorModal(data) {
    this.isAffiliatorModalOpen = true;
    this.affiliatorOpened = data;
  }

  closeAffiliatorModal() {
    this.isAffiliatorModalOpen = false;
  }

  openExportPdfModal() {
    this.isExportPdfModalOpen = true;
  }

  async openTrxModal(files) {
    this.isTrxModalOpen = true;
    this.trxOpened = files;
    files.forEach((file, index) => {
      const payload = { storageFileName: file?.storageFileName };
      this.renderPresignedUrlImage(payload, `#js-photoPreview-${index}`);
    });
  }

  closeTrxModal() {
    this.isTrxModalOpen = false;
  }

  showStatusHistoryModal(data: any) {
    this.isStatusHistoryModalOpen = true;
    this.statusHistoryOpened = data;
  }

  closeStatusHistoryModal() {
    this.isStatusHistoryModalOpen = false;
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._AffiliatorClaimRest.getPresignedUrl(payload).subscribe(
      data => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);

        if (photoPreview) {
          photoPreview.src = data.url;
          photoPreview.href = data.url;
        }
      },
      error => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);
        if (photoPreview) {
          photoPreview.src = '';
        }
      }
    );
  }

  toAction(status: string) {
    if (status == 'waiting payment') return 'Waiting Payment';
    if (status == 'processed') return 'Approved';
    if (status == 'rejected') return 'Rejected';
    if (status == 'paid') return 'Paid';
  }

  onApproval(id, affiliatorId, action) {
    Swal.fire({
      title: `Update status to ${this.toAction(action)}?`,
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.approveAffiliatorClaim(id, affiliatorId, action);
      } else {
        return;
      }
    });
  }

  async approveAffiliatorClaim(id, affiliatorId, action) {
    await this._AffiliatorClaimRest
      .approval({ _id: id, affiliatorId, status: action })
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire('Status updated successfully!', '', 'success');
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._AffiliatorClaimRest.findAll({}).toPromise();
      const mapOption = {
        affiliatorName: { header: 'Nama Affiliator' },
        memberId: { header: 'Member ID Affiliator' },
        phone: { header: 'No Telepon Affiliator' },
        ktpNumber: { header: 'Nomor KTP Affiliator' },
        email: { header: 'Email Affiliator' },
        address: { header: 'Alamat Affiliator' },
        bankName: { header: 'Nama Bank Affiliator' },
        bankAccount: { header: 'No Rekening Affiliator' },
        status: { header: 'Status' },
        buyerName: { header: 'Nama Pembeli' },
        orderDate: { header: 'Tanggal Pembelian' },
        motorType: { header: 'Jenis Motor' },
        picName: { header: 'Nama PIC' },
        picPhone: { header: 'No Telepon PIC' },
        dealer: { header: 'Dealer' },
        bankBranch: { header: 'Cabang Bank' },
        vinNumber: { header: 'No VIN / Rangka' },
        updatedAt: { header: 'Tanggal Diupdate' },
        createdAt: { header: 'Tanggal Submit' }
      };
      const payload = result.data.map(item => ({
        ...item,
        orderDate: moment(item?.orderDate).format('DD MMMM YYYY'),
        affiliatorName: item.affiliator?.name,
        memberId: item.affiliator?.memberId,
        phone: item.affiliator?.phone,
        ktpNumber: item.affiliator?.ktpNumber,
        email: item.affiliator?.email,
        address: item.affiliator?.address,
        bankName: item.affiliator?.bankName,
        bankAccount: item.affiliator?.bankAccount,
        dealer: item.dealer?.name,
        updatedAt: moment(item?.updatedAt).format('DD MMMM YYYY HH:mm'),
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'Affiliator Claim',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  async onDownloadPdf(startDate, endDate) {
    if (!startDate || !endDate) {
      Swal.fire('Error', 'Masukan tanggal awal dan tanggal akhir', 'error');
      return;
    }

    this.isLoadingExportPdf = true;

    try {
      const result = await this._AffiliatorClaimRest.findAll({ startDate, endDate }).toPromise();
      const data = result.data;

      const ktpUrls = data.map((item) => item.affiliator.ktpFile.storageFileName); // only urls
      const ktpUrlsObject = {}; // initiate url object for pdf

      // convert to presigned url
      await Promise.all(ktpUrls.map(async (url, index) => {
        try {
          // Fetch presigned URL (assuming `getPresignedUrl` returns a promise)
          const response = await this._AffiliatorClaimRest.getPresignedUrl({ storageFileName: url }).toPromise();
          // Update ktpUrlsObject with the fetched URL
          ktpUrlsObject[`url${index}`] = response.url;
        } catch (error) {
          console.error(`Error fetching presigned URL for ${url}:`, error);
          // Handle the error if needed
        }
      }));

      // Map data to table rows
      const tableBody = [
        [
          { text: 'No', bold: true, fillColor: '#e0e0e0' },
          { text: 'Nama Affliator', bold: true, fillColor: '#e0e0e0' },
          { text: 'Member ID Affiliator', bold: true, fillColor: '#e0e0e0' },
          { text: 'Nomor KTP Affiliator', bold: true, fillColor: '#e0e0e0' },
          { text: 'Bank', bold: true, fillColor: '#e0e0e0' },
          { text: 'Nomor Rekening', bold: true, fillColor: '#e0e0e0' },
          { text: 'Nama Pembeli', bold: true, fillColor: '#e0e0e0' },
          { text: 'Tanggal Pembelian', bold: true, fillColor: '#e0e0e0' },
          { text: 'Jenis Motor', bold: true, fillColor: '#e0e0e0' },
          { text: 'Amount', bold: true, fillColor: '#e0e0e0' },
          { text: 'Keterangan', bold: true, fillColor: '#e0e0e0' }
        ]
      ];

      data.forEach((item, index) => {
        tableBody.push([
          index + 1,
          item.affiliator?.name || '',
          item.affiliator?.memberId || '',
          item.affiliator?.ktpNumber || '', // Assuming KTP number is available in the affiliator object
          item.affiliator?.bankName || '',
          item.affiliator?.bankAccount || '',
          item.buyerName || '',
          moment(item.orderDate).format('DD MMMM YYYY') || '',
          item.motorType || '',
          item.commission || '', // Assuming commission is available in the data
          item.dealer?.name || ''
        ]);
      });

      const formatCommission = number => new Intl.NumberFormat('id-ID').format(number);
      const totalCommission = data.reduce((sum, item) => sum + (item.commission || 0), 0);
      const todayDate = moment().format('DD MMMM YYYY');
      
      // Convert imageUrls object to an array of { url, key }
      const imagesArray = Object.entries(ktpUrlsObject).map(([key, url]) => ({
        image: key, // Use the key as the reference
        url // Store the URL for later use
      }));
      
      // Number of images per row
      const imagesPerRow = 5;
      const rows = [];
      
      // Create rows of images
      for (let i = 0; i < imagesArray.length; i += imagesPerRow) {
        rows.push({
          columns: imagesArray.slice(i, i + imagesPerRow).map(({ image }) => ({
            image,
            width: 200, // Adjust width as needed
            margin: [10, 10] // Adjust margins if needed
          })),
          columnGap: 20 // Horizontal gap each image
        });
      }    

      // Define PDF document
      const docDefinition = {
        pageOrientation: 'landscape',
        pageSize: 'A3',
        pageMargin: [0, 0, 0, 40],
        content: [
          { text: 'FORM PENGAJUAN KLAIM VOLTA PRO - AFFILIATOR', style: 'header' },
          {
            columns: [
              {
                width: 'auto',
                stack: [
                  { text: 'Total Pengajuan ', style: 'subHeader' },
                  { text: 'Potongan PPH 21 (2.5%)', style: 'subHeader' },
                  { text: 'Tanggal Pengajuan', style: 'subHeader' }
                ]
              },
              {
                width: 'auto',
                stack: [
                  { text: `: ${formatCommission(totalCommission)}`, style: 'subHeader', bold: true },
                  { text: `: ${formatCommission(totalCommission * 2.5 / 100)}`, style: 'subHeader', bold: true },
                  {
                    text: `: ${moment(startDate).format('DD MMMM YYYY')} - ${moment(endDate).format('DD MMMM YYYY')}`,
                    style: 'subHeader'
                  }
                ]
              }
            ],
            columnGap: 2 // This controls the gap between the columns
          },
          {
            table: {
              headerRows: 1,
              widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
              body: tableBody
            },
            fontSize: 8
          },
          { text: `Jakarta, ${todayDate}`, style: 'subHeader', margin: [0, 20, 0, 3] },
          { text: 'Requester,', style: 'subHeader' },
          { text: '..................', style: 'subHeader', margin: [0, 32, 0, 6] },
          // Add a page break for
          { text: '', pageBreak: 'before' }, // This ensures the next content starts on a new page
          // New page content
          {
            stack: rows,
            margin: [0, 20]
          }
        ],
        images: ktpUrlsObject,
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 6]
          },
          subHeader: {
            fontSize: 8,
            margin: [0, 0, 0, 6]
          }
        },
        footer: (currentPage, pageCount) => {
          // Display page number starting from the second page
          if (currentPage > 1) {
            return {
              text: `Page ${currentPage - 1} of ${pageCount - 1}`,
              alignment: 'center',
              fontSize: 8,
              margin: [0, 10, 0, 0]
            };
          } else {
            return ''; // No footer on the first page
          }
        }
      };

      // Generate and download the PDF
      pdfMake.createPdf(docDefinition).download(`Laporan_Transaksi_${startDate}_to_${endDate}.pdf`);
    } catch (error) {
      console.log(error);
      Swal.fire('Error', 'Gagal mengunduh PDF', 'error');
    } finally {
      this.isLoadingExportPdf = false;
    }
  }

  getStatusColor(status: string) {
    if (status == 'request') return 'warning';
    if (status == 'draft') return 'secondary';
    if (status == 'waiting payment') return 'primary';
    if (status == 'processed') return 'success';
    if (status == 'paid') return 'success';
    if (status == 'rejected') return 'danger';
  }

  formatDate(date: string) {
    return moment(date).format('DD MMMM YYYY HH:mm');
  }
}
