import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BatteryRestService } from '../battery-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-battery-list',
  templateUrl: 'battery-list.component.html'
})
export class BatteryListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.batteryType',
      field: 'batteryType',
      sort: true
    },
    {
      i18nLabel: 'ui.product.entity.currentStock',
      field: 'stock',
      sort: true
    },
    {
      i18nLabel: 'ui.product.entity.stockOrdered',
      field: 'stockOrdered',
      sort: true
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  formModalUpdate: FormGroup;
  modalUpdate: boolean = false;
  selectedBatteryId = null;

  constructor(
    private _BatteryRest: BatteryRestService,
    // private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'BatteryListComponent';
    this.headerTitle = 'ui.product.list.title';
    // this.headerButtons = [
    //   {
    //     type: 'custom',
    //     label: 'ui.dealer.list.button.create',
    //     color: 'primary-outline',
    //     onClick: () => this.openDialogUpdate()
    //   }
    // ];
    this.initFormUpdate();
  }

  initFormUpdate() {
    this.formModalUpdate = new FormGroup({
      currentStock: new FormControl('', [Validators.required]),
    })
  }

  getBatteries(data) {
    this.selectedBatteryId = data._id;
    this.formModalUpdate.patchValue({ currentStock: data?.stock });
  }

  openDialogUpdate(data?) {
    if (data) {
      this.getBatteries(data);
    }
    this.modalUpdate = true;
  }

  closeModalUpdate() {
    this.modalUpdate = false;
  }

  async updateBattery() {
    await this._BatteryRest
      .update(this.selectedBatteryId, this.formModalUpdate.value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.update'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }

        this.modalUpdate = false;
        this.selectedBatteryId = null;
        this.formModalUpdate.reset();
        this.callHookDirectly('init');
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._BatteryRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'BatteryListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
