import { Component, Input } from "@angular/core";
import { SPKRestService } from "../spk-rest.service";
import Swal from "sweetalert2";
import { SPKListComponent } from "../list/spk-list.component";

@Component({
  selector: 'app-claim-modal',
  templateUrl: 'claim-modal.component.html',
  styleUrls: ['../spk-component.scss']
})
export class ClaimModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {
  }

  isLoading: boolean = false;
  isClaimModalOpen: boolean = false;
  isClaimConfirmationModalOpen: boolean = false;
  claimFee: number = 0;

  openClaimConfirmationModal() {
    this.isClaimConfirmationModalOpen = true;
  }

  closeClaimConfirmationModal() {
    this.isClaimConfirmationModalOpen = false;
  }

  openClaimModal() {
    this.isClaimModalOpen = true;
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();

    if (dayOfMonth >= 1 && dayOfMonth <= 10) {
      this.claimFee = 100000;
    } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
      this.claimFee = 75000;
    } else {
      this.claimFee = 50000;
    }
  }

  closeClaimModal() {
    this.isClaimModalOpen = false;
  }

  onClaimSubsidy() {
    Swal.fire({
      title: 'Ajukan Klaim?',
      text: '',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.claimSubsidy();
      } else {
        return;
      }
    });
  }

  onConfirmClaimSubsidy(status: string) {
    Swal.fire({
      title: status === 'confirmed' ? 'Terima Pengajuan Klaim?' : 'Tolak Pengajuan Klaim?',
      text: '',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.confirmClaimSubsidy(status);
      } else {
        return;
      }
    });
  }

  async claimSubsidy() {
    const payload = { spkId: this.spk._id };
    this.isLoading = true;

    await this._SPKRest
      .claimSubsidy(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Claim successfully!',
            '',
            'success'
          )

          this._spkListComponent.callHookDirectly('init');
          this.closeClaimModal();
        }
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Oops..',
          'Something went wrong!',
          'error'
        )
      });
    this.isLoading = false;
  }

  async confirmClaimSubsidy(status: string) {
    const payload = {
      spkId: this.spk._id,
      claimId: this.spk.claimId,
      status,
    };
    this.isLoading = true;

    await this._SPKRest
      .confirmClaimSubsidy(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Claim status updated!',
            '',
            'success'
          )

          this._spkListComponent.callHookDirectly('init');
          this.closeClaimModal();
        }
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Oops..',
          'Something went wrong!',
          'error'
        )
      });
    this.isLoading = false;
  }
}