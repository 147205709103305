import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class ProductTypeVariantRestService {
  baseURL = `${APP_CONST.API_MASTER}/`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/admin/product-type-variant/products`, {
      params: queryParams
    });
  }

  create(payload: any) {
    return this.request.post<any>(
      `/admin/product-type-variant/products`,
      payload
    );
  }

  update(id, payload) {
    return this.request.put(
      `/admin/product-type-variant/products/${id}`,
      payload
    );
  }

  getProductType() {
    return this.request.get<any>(`/admin/product-type`);
  }
}
