import { Component } from '@angular/core';
// import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SubsidiRestService } from '../subsidi-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/common/common.service';

@Component({
  selector: 'app-subsidi-list-transaction',
  templateUrl: 'subsidi-list-transaction.component.html'
})
export class SubsidiListTransactionComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.subsidi.entity.nik',
      field: 'nik',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.nikStatus',
      field: 'nikStatus',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.name',
      field: 'fullname',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.customerNo',
      field: 'whatsapp',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.address',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.domicile',
      field: 'domicile',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.status',
      field: 'status_trx',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.sourceid',
      field: 'sourceid',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.refId',
      field: 'refid',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.unit',
      field: 'unitType',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.photoKtp',
      field: '',
      name: 'photoKtp',
      sort: true
    },
    {
      i18nLabel: 'ui.subsidi.entity.createdDate',
      field: 'createdAt',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  statusNik: { text: string; value: string }[];
  statusTrx: { text: string; value: string }[];
  transactionData: any;
  photoKtp: any;
  imageModal: boolean = false;
  modalUpdate: boolean = false;
  formModalUpdate: FormGroup;
  statusTrans: string[];
  subsidyId: any;
  sourceListData: any;
  isLoading: boolean;
  isLoadingExportExcel: boolean = false;

  constructor(
    private _SubsidiRest: SubsidiRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.createFormSubsidy();
    this.componentId = 'SubsidiListTransactionComponent';
    this.headerTitle = 'ui.subsidi.list.titleTransaction';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.statusNik = [
      {
        text: 'Semua Status NIK',
        value: ''
      },
      {
        text: 'Eligible',
        value: 'eligible'
      },
      {
        text: 'Non-Eligible',
        value: 'not eligible'
      }
    ];
    this.statusTrx = [
      {
        text: 'Semua Status Transaksi',
        value: ''
      },
      {
        text: 'Booking Fee Paid',
        value: 'Booking Fee Paid'
      },
      {
        text: 'DP Paid',
        value: 'DP Paid'
      },
      {
        text: 'Proses Finance',
        value: 'Proses Finance'
      },
      {
        text: 'Payment Complete',
        value: 'Payment Complete'
      }
    ];
    this.statusTrans = [
      'New',
      'Booking Fee Paid',
      'DP Paid',
      'Proses Finance',
      'Payment Complete'
    ];
    this.qParams.nikStatus = '';
    this.qParams.status_trx = '';
    this.qParams.sourceid = '';
    this.qParams.endDate = moment().format('YYYY-MM-DD');
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }
        return this._SubsidiRest.getPaymentData(qParams).pipe(
          switchMap(doc => {
            this.callHookDirectly('loadDataSummary');
            const dataFormatted = doc?.data?.map(d => {
              d.nikStatus = this.capitalizeFirstLetter(d.nikStatus);
              d.fullname = this.capitalizeFirstLetter(d.fullname);
              d.createdAt = moment(d?.createdAt)
                .utcOffset('+0700')
                .format('DD MMMM YYYY HH:mm');
              d.sourceid = this.formatNull(d?.sourceid);
              d.refid = this.formatNull(d?.refid);
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataSummary',
      handle: () => {
        this.isLoading = true;
        return this._SubsidiRest.getPaymentData(this.qParams).pipe();
      },
      success: res => {
        this.transactionData = res;
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataSource',
      handle: () => {
        this.isLoading = true;
        return this._SubsidiRest.getSourceList().pipe();
      },
      success: res => {
        const sourceList = res.filter(v => v.sourceid !== 'null');
        const allSource = {
          text: 'Semua Sourceid',
          value: ''
        };
        this.sourceListData = sourceList.map(v => ({
          text: v.sourceid,
          value: v.sourceid
        }));
        this.sourceListData.unshift(allSource);
        this.isLoading = false;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'SubsidiListTransactionComponent:workflowLoadData',
          'SubsidiListTransactionComponent:loadDataSource'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._SubsidiRest
        .getPaymentData(this.qParams)
        .toPromise();
      const dataFormatted = result?.data?.map(d => {
        d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
        d.nik = String(d?.nik);
        return d;
      });
      const mapOption = {
        nik: { header: 'NIK' },
        nikStatus: { header: 'Status NIK' },
        fullname: { header: 'Nama Lengkap' },
        whatsapp: { header: 'No Whatsapp' },
        email: { header: 'Email' },
        address: { header: 'Alamat' },
        domicile: { header: 'Domisili' },
        status_trx: { header: 'Status Transaksi' },
        sourceid: { header: 'Source Id' },
        refid: { header: 'Ref Id' },
        unitType: { header: 'Unit - Type' },
        photoKtp: { header: 'Foto KTP' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      this._export.export({
        extension: 'xlsx',
        fileName: 'Subsidi Transaction',
        records: dataFormatted,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  formatNull(string) {
    if (string == 'null' || !string) {
      return '-';
    } else {
      return string;
    }
  }

  actionDeleteSubsidy(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteSubsidyData(value);
      } else {
        return;
      }
    });
  }

  actionSaveDraftSubsidy(value) {
    Swal.fire({
      title: this.comp._translate.instant('confirm.subsidi.label'),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.saveDraftSubsidyData(value);
      } else {
        return;
      }
    });
  }

  async deleteSubsidyData(value) {
    const result = await this._SubsidiRest
      .deleteSubsidy(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.subsidi.delete'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async saveDraftSubsidyData(value) {
    const result = await this._SubsidiRest
      .saveDraftSubsidy(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.subsidi.saveDraft'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: this.comp._translate.instant('error.subsidi.saveDraft'),
          type: 'error',
          showAs: 'growl',
          showSnackBar: false,
          scroll: true
        });
      });
  }

  openDialogUpdate(subsidy) {
    this.getSubsidyData(subsidy);
    this.modalUpdate = true;
  }

  showImageDialog(value) {
    this.photoKtp = value;
    this.imageModal = true;
  }

  async getSubsidyData(subsidy) {
    if (subsidy) {
      this.subsidyId = subsidy._id;
      this.formModalUpdate.patchValue({
        fullname: subsidy.fullname,
        email: subsidy.email,
        whatsapp: subsidy.whatsapp,
        address: subsidy.address,
        domicile: subsidy.domicile,
        status_trx: subsidy.status_trx
      });
    }
  }

  createFormSubsidy() {
    this.formModalUpdate = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      whatsapp: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      domicile: new FormControl('', [Validators.required]),
      status_trx: new FormControl('', [Validators.required])
    });
  }

  saveModalUpdate() {
    const formValue = this.formModalUpdate.value;
    if (!this.formModalUpdate.valid) {
      CommonService.markAsDirty(this.formModalUpdate);
      return;
    }
    this.updateSubsidyData(formValue, this.subsidyId);
    this.modalUpdate = false;
    this.formModalUpdate.reset();
    this.callHookDirectly('init');
  }

  closeModalUpdate() {
    this.modalUpdate = false;
    this.formModalUpdate.reset();
  }

  async updateSubsidyData(value, id) {
    const result = await this._SubsidiRest
      .updateSubsidy(id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.subsidi.update'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async downloadPhotoKtp(value) {
    if (value.photoKtp) {
      const blob = await fetch(value.photoKtp).then(r => r.blob());
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${value.nik} - ${value.fullname}.jpg`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      this.comp._globalSystemMessage.log({
        message: this.comp._translate.instant('ui.subsidi.list.noPhotoKtp'),
        type: 'error',
        showAs: 'growl',
        showSnackBar: false,
        scroll: true
      });
    }
  }

  convertTotal(numberValue) {
    const format = numberValue
      ?.toString()
      .split('')
      .reverse()
      .join('');
    const convert = format.match(/\d{1,3}/g);
    const totalData = convert
      .join('.')
      .split('')
      .reverse()
      .join('');
    return totalData;
  }
}
