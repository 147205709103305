import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { ProductTypeVariantRestService } from '../product-type-variant-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/libraries.module';

@Component({
  selector: 'app-product-type-variant-list',
  templateUrl: 'product-type-variant-list.component.html'
})
export class ProductTypeVariantListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.nameProduct',
      field: 'productTypeId.name',
      sort: true
    },
    {
      i18nLabel: 'ui.product.entity.color',
      field: 'color',
      sort: true
    },
    {
      i18nLabel: 'ui.product.entity.currentStock',
      field: 'stock',
      sort: true
    },
    {
      i18nLabel: 'ui.product.entity.stockOrdered',
      field: 'stockOrdered',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  formModalCreate: FormGroup;
  modalCreate: boolean = false;
  formModalUpdate: FormGroup;
  modalUpdate: boolean = false;
  selectedProductTypeVariantId = null;
  productTypeOptions = [];
  formModalCreateHelper = {};

  isFieldError = CommonService.isFieldError;

  constructor(private _ProductTypeVariantRest: ProductTypeVariantRestService) {
    super();
    this.componentId = 'ProductTypeVariantListComponent';
    this.headerTitle = 'ui.product.list.title';
    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.product.list.button.createProduct',
        color: 'primary-outline',
        onClick: () => this.openDialogCreate()
      }
    ];
    this.qParams.productTypeId = '';
    this.initFormUpdate();
  }

  initFormUpdate() {
    this.formModalCreate = new FormGroup({
      productTypeId: new FormControl(null, [Validators.required]),
      color: new FormControl('', [Validators.required]),
      currentStock: new FormControl('', [Validators.required]),
      stockOrdered: new FormControl('', [Validators.required])
    });

    this.formModalUpdate = new FormGroup({
      currentStock: new FormControl('', [Validators.required])
    });

    this.formModalCreateHelper = {};
  }

  getProductData(data) {
    this.selectedProductTypeVariantId = data._id;
    this.formModalUpdate.patchValue({ currentStock: data?.stock });
  }

  openDialogCreate() {
    this.modalCreate = true;
  }

  closeModalCreate() {
    this.modalCreate = false;
    this.formModalCreate.reset();
  }

  openDialogUpdate(data?) {
    if (data) {
      this.getProductData(data);
    }
    this.modalUpdate = true;
  }

  closeModalUpdate() {
    this.modalUpdate = false;
  }

  async createProduct() {
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }

    await this._ProductTypeVariantRest
      .create(this.formModalCreate.value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.create'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }

        this.modalCreate = false;
        this.formModalCreate.reset();
        this.callHookDirectly('init');
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message:
            error.response?.data?.message ??
            this.comp._translate.instant('error.retry'),
          type: 'error',
          showAs: 'growl',
          showSnackBar: false,
          scroll: true
        });
      });
  }

  async updateProduct() {
    await this._ProductTypeVariantRest
      .update(this.selectedProductTypeVariantId, this.formModalUpdate.value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.update'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }

        this.modalUpdate = false;
        this.selectedProductTypeVariantId = null;
        this.formModalUpdate.reset();
        this.callHookDirectly('init');
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        this._ProductTypeVariantRest.getProductType().subscribe(response => {
          this.productTypeOptions = response.data;
        });

        return this._ProductTypeVariantRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductTypeVariantListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
