import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { DealerRestService } from '../dealer-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/libraries.module';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import Swal from 'sweetalert2';
import { AclService } from '../../../libraries/auth/acl.service';
@Component({
  selector: 'app-dealer-list',
  templateUrl: 'dealer-list.component.html'
})
export class DealerListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.dealer.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.dealer.entity.name',
      field: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.cp',
      field: 'cp',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.address',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.region',
      field: 'region',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.category',
      field: 'category',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.isSubsidy',
      field: '',
      name: 'isSubsidy',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.va',
      field: 'virtualAccount',
      sort: false
    },
    {
      i18nLabel: 'ui.dealer.entity.vaName',
      field: 'virtualAccountName',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.latitude',
      field: 'latitude',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.longitude',
      field: 'longitude',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.bankAccount',
      field: 'bankAccount',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.bankName',
      field: 'bankName',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    },
    {
      i18nLabel: 'ui.dealer.entity.updatedBy',
      field: '',
      name: 'updatedBy',
      sort: false
    },
    {
      i18nLabel: 'ui.dealer.entity.detail',
      field: '',
      name: 'detail',
      sort: false
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  isLoadingExportExcel: boolean = false;
  modalUpdate: boolean = false;
  modalView: boolean = false;
  formModalUpdate: FormGroup;
  selectedDealerId: string | null;
  regionsOption: any[] = [];
  dealerOption: any[] = [];
  updatedBy: any = null;
  updatedByModal: boolean = false;
  detailDealerData: any = null;
  detailDealerModal: boolean = false;
  filterCategoryOption: { text: string; value: string }[];

  constructor(
    public _authentication: AuthenticationService,
    private _DealerRest: DealerRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService
  ) {
    super();
    this.createFormDealer();
    this.getRegionOption();

    this.componentId = 'DealerListComponent';
    this.headerTitle = 'ui.dealer.list.title';
    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.dealer.list.button.create',
        color: 'primary-outline',
        permissions: ['dealer.create', 'dealerBranch.create'],
        onClick: () => this.openDialogUpdate()
      }
    ];
    this.filterCategoryOption = [
      {
        text: 'Semua Kategori Dealer',
        value: ''
      },
      {
        text: 'Main Dealer',
        value: 'main dealer'
      },
      {
        text: 'Dealer',
        value: 'dealer'
      }
    ];

    this.qParams.category = '';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        this.getDealerOption();

        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];

        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );

        return this._DealerRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'DealerListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  async getRegionOption() {
    try {
      const response: any = await this._DealerRest.getRegions().toPromise();
      this.regionsOption = response
        .map(item => ({
          label: item.name,
          value: item.name
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    } catch (error) {
      this.comp._globalSystemMessage.log({
        message: 'Error',
        type: 'error',
        scroll: true
      });
    }
  }

  async getDealerOption() {
    try {
      const response: any = await this._DealerRest
        .findAll({ category: 'main dealer' })
        .toPromise();
      this.dealerOption = response.data.map((item: any) => ({
        value: item._id,
        label: `${item.name} - (${item.region})`
      }));
    } catch (error) {
      this.comp._globalSystemMessage.log({
        message: 'Error',
        type: 'error',
        scroll: true
      });
    }
  }

  // Exclude own dealer
  filteredDealerOption() {
    return this.dealerOption.filter(
      item => item.value !== this.selectedDealerId
    );
  }

  createFormDealer() {
    this.formModalUpdate = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      cp: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      region: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      isSubsidy: new FormControl(false, [Validators.required]),
      latitude: new FormControl('', [Validators.required]),
      longitude: new FormControl('', [Validators.required]),
      bankAccount: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      virtualAccount: new FormControl(''),
      virtualAccountName: new FormControl(''),
      parentId: new FormControl(null),
      mdId: new FormControl(null)
    });
  }

  async getDealerData(data) {
    this.selectedDealerId = data._id;
    this.formModalUpdate.patchValue(data);
  }

  openDialogUpdate(data?) {
    if (this._AclService.can('dealerBranch.create', 'dealerBranch.update')) {
      this.formModalUpdate.controls['category'].setValue('dealer');
    }

    if (data) {
      this.getDealerData(data);
    }

    this.modalUpdate = true;
  }

  openDialogView(data?) {
    if (this._AclService.can('dealerBranch.read', 'dealerBranch.read')) {
      this.formModalUpdate.controls['category'].setValue('dealer');
    }

    if (data) {
      this.getDealerData(data);
    }

    this.modalView = true;
  }

  openUpdatedByModal(data: any) {
    this.getUpdatedByData(data.updatedBy);
    this.updatedByModal = true;
  }

  getUpdatedByData(userId) {
    this.updatedBy = null;
    this._DealerRest
      .getUpdatedBy(userId)
      .toPromise()
      .then(res => {
        this.updatedBy = res;
      });
  }

  closeUpdatedByModal() {
    this.updatedByModal = false;
  }

  openDetailModal(data: any) {
    this.detailDealerModal = true;
    this.getDetailData(data._id);
  }

  getDetailData(dealerID: string) {
    this.detailDealerData = null;
    this._DealerRest
      .getDetail(dealerID)
      .toPromise()
      .then(res => {
        this.detailDealerData = res;
      });
  }

  closeDetailDealerModal() {
    this.detailDealerData = null;
    this.detailDealerModal = false;
  }

  openDeleteConfirmation(id) {
    Swal.fire({
      title: this.comp._translate.instant('confirm.any.delete.label'),
      text: this.comp._translate.instant('confirm.any.delete.description'),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteDealer(id);
      } else {
        return;
      }
    });
  }

  closeModalUpdate() {
    this.modalUpdate = false;
    this.formModalUpdate.reset();
    this.selectedDealerId = null;
    this.formModalUpdate.controls['isSubsidy'].setValue(false);
  }

  closeModalView() {
    this.modalView = false;
    this.formModalUpdate.reset();
    this.selectedDealerId = null;
    this.formModalUpdate.controls['isSubsidy'].setValue(false);
  }

  async deleteDealer(id) {
    await this._DealerRest
      .delete(id)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.dealer.delete'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async upsertDealer() {
    const userId: string = this._authentication.user?._id;
    const formValue = {
      ...this.formModalUpdate.value,
      updatedBy: userId,
      isSubsidy: ['true', true].includes(this.formModalUpdate.value?.isSubsidy)
    };

    if (!this.formModalUpdate.valid) {
      CommonService.markAsDirty(this.formModalUpdate);
      return;
    }

    if (formValue.mdId === 'null') formValue.mdId = null;
    if (formValue.parentId === 'null') formValue.parentId = null;

    if (this.selectedDealerId) {
      await this.updateDealer(this.selectedDealerId, formValue);
    } else {
      await this.createDealer(formValue);
    }

    this.modalUpdate = false;
    this.formModalUpdate.reset();
    this.selectedDealerId = null;
    this.callHookDirectly('init');
  }

  async createDealer(value) {
    await this._DealerRest
      .create(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              this.selectedDealerId
                ? 'success.dealer.update'
                : 'success.dealer.create'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async updateDealer(id, value) {
    await this._DealerRest
      .update(id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.dealer.update'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._DealerRest.findAll({}).toPromise();
      const mapOption = {
        name: { header: 'Nama' },
        email: { header: 'Email' },
        phone: { header: 'Telepon' },
        cp: { header: 'Kontak Person' },
        address: { header: 'Alamat' },
        region: { header: 'Region' },
        isSubsidy: { header: 'Menerima Subsidi' },
        virtualAccount: { header: 'Virtual Account' },
        virtualAccountName: { header: 'Virtual Account Name' },
        latitude: { header: 'Latitude' },
        longitude: { header: 'Longitude' },
        bankAccount: { header: 'No Rekening Bank' },
        bankName: { header: 'Nama Bank' },
        category: { header: 'Kategori' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      const payload = result.data.map(item => ({
        ...item,
        isSubsidy: item.isSubsidy ? 'Ya' : 'Tidak'
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'Kontak',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      // TODO: Handle the error
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
