import { Injectable } from '@angular/core';

@Injectable()
export class DataFormatService {
  constructor() {}

  formatYearRangeReport(): string {
    return `${new Date().getFullYear() - 10} : ${new Date().getFullYear()}`;
  }

  formatYearRangeTransactionMoreFilter(): string {
    return `${new Date().getFullYear() - 10} : ${new Date().getFullYear() +
      10}`;
  }

  formatYearRangeBirth(): string {
    return `${new Date().getFullYear() - 100} : ${new Date().getFullYear()}`;
  }

  simpleFormatCurrency(value) {
    return Math.abs(Number(value)) >= 1.0e12
      ? // Twelve Zeroes for Trillions
        Math.abs(Number(value)) / 1.0e12 + ' T'
      : // Nine Zeroes for Billions
      Math.abs(Number(value)) >= 1.0e9
      ? Math.abs(Number(value)) / 1.0e9 + ' M'
      : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? Math.abs(Number(value)) / 1.0e6 + ' Jt'
      : // Three Zeroes for Thousands
      Math.abs(Number(value)) >= 1.0e3
      ? Math.abs(Number(value)) / 1.0e3 + ' Rb'
      : Math.abs(Number(value));
  }

  formatRupiah(value: any): string | null {
    if (value == null || value === '' || typeof value === 'undefined') {
      return null;
    }

    const numberString = value.toString();
    const [integerPart, decimalPart] = numberString.split(',');

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    );

    let formattedValue = `Rp. ${formattedIntegerPart}`;

    if (decimalPart !== undefined) {
      formattedValue += `,${decimalPart}`;
    }

    return formattedValue;
  }
}
