import { Component } from '@angular/core';
import 'moment/locale/id';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SubsidiRestService } from '../subsidi-rest.service';
import * as moment from 'moment';

@Component({
  selector: 'app-list-visitors',
  templateUrl: 'list-visitors.component.html'
})
export class SubsidiListVisitorsComponent extends BaseListBComponent<any> {

  constructor(private _SubsidiRest: SubsidiRestService) {
    super();

    this.qParams.visitorEndDate = moment().format('YYYY-MM-DD');
  }

  isLoading: boolean = false;

  visitors = {};

  keys = (data) => Object.keys(data)

  async getVisitors() {
    const params = {
      startDate: this.qParams.visitorStartDate,
      endDate: this.qParams.visitorEndDate
    };
    await this._SubsidiRest.getVisitors(params).subscribe((data: any) => {
      this.visitors = data;
    });
  }

  appOnInit(): void {
    this.getVisitors();
  }
}