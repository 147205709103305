import { Component } from '@angular/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { PoRestService } from '../po-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../../libraries/common/url.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-po-list',
  templateUrl: 'po-list.component.html'
})
export class PoListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.po.entity.invoiceNo',
      field: 'invNumber'
    },
    {
      i18nLabel: 'ui.po.entity.customerName',
      field: 'userFullName'
    },
    {
      i18nLabel: 'ui.po.entity.customerEmail',
      field: '',
      name: 'email'
    },
    {
      i18nLabel: 'ui.po.entity.customerNo',
      field: 'phoneNumber'
    },
    {
      i18nLabel: 'ui.po.entity.createdDate',
      field: 'createdAt'
    },
    {
      i18nLabel: 'ui.po.entity.paymentStatus',
      field: '',
      name: 'paymentStatus'
    },
    // {
    //   i18nLabel: 'ui.po.entity.dealerStatus',
    //   field: '',
    //   name: 'dealerConfirmationStatus'
    // },
    // {
    //   i18nLabel: 'ui.po.entity.docStatus',
    //   field: '',
    //   name: 'documentStatus'
    // },
    // {
    //   i18nLabel: 'ui.po.entity.deliveryStatus',
    //   field: '',
    //   name: 'deliveryStatus'
    // },
    {
      i18nLabel: 'ui.po.entity.isSubsidy',
      field: '',
      name: 'isSubsidy'
    },
    {
      i18nLabel: 'ui.po.entity.items',
      field: '',
      name: 'items'
    },
    {
      i18nLabel: 'ui.po.entity.subTotal',
      field: '',
      name: 'subTotal'
    },
    {
      i18nLabel: 'ui.po.entity.referral',
      field: '',
      name: 'referral'
    },
    {
      i18nLabel: 'ui.po.entity.discount',
      field: '',
      name: 'discount'
    },
    {
      i18nLabel: 'ui.po.entity.invoicePayment',
      field: '',
      name: 'invoicePayment'
    },
    {
      i18nLabel: 'ui.po.entity.dealerName',
      field: '',
      name: 'dealerName'
    }
  ];
  subsidyOption = [
    {
      text: 'Semua Status Subsidi',
      value: ''
    },
    {
      text: 'Subsidy',
      value: true
    },
    {
      text: 'Non-Subsidy',
      value: false
    }
  ];
  paymentStatusOption = [
    {
      text: 'Semua Status Pesanan',
      value: ''
    },
    {
      text: 'Paid',
      value: 'paid'
    },
    {
      text: 'Unpaid',
      value: 'unpaid'
    },
    {
      text: 'Cancel',
      value: 'cancel'
    },
    {
      text: 'Expired',
      value: 'expired'
    },
    {
      text: 'Failed',
      value: 'failed'
    }
  ];

  dealerOption = [
    {
      name: 'Semua Dealer',
      _id: ''
    }
  ];

  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  displayDialog: boolean = false;
  dialogHeader: string;
  recreateOrRetryId: any;
  isLoadingRetry: boolean = false;
  dialogKey: string;
  isLoadingExportExcel: boolean = false;

  constructor(
    private _poRest: PoRestService,
    private _export: ExportDataTableService,
    private translate: TranslateService,
    private urlService: UrlService,
    private _router: Router
  ) {
    super();
    this.componentId = 'PoListComponent';
    this.headerTitle = 'Data Po';
    this.exportFileName = 'roles';
    this.exportDocumentTitle = 'ui.role.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';
    this.deleteRecordI18nSuccess = 'success.po.delete';
    this.qParams.isSubsidy = '';
    this.qParams.paymentStatus = '';
    this.qParams.dealerId = '';
    this.translate.onLangChange.subscribe(() => {
      this.updateTranslations();
    });
    this.qParams.endDate = moment().format('YYYY-MM-DD');
    this.dialogHeader = this.translate.instant('ui.po.action.retry.retryLabel');
  }

  navigateToDetails(orderId: string) {
    const detailsUrl = `po/details/${orderId}`;
    this._router.navigate([detailsUrl]);
  }

  renderItems(items) {
    return items?.map(item => `${item.productName} - ${item.nameColor}`).join(', ');
  }

  updateTranslations() {
    this.subsidyOption = [
      {
        text: this.translate.instant('ui.po.entity.allSubsidy'),
        value: ''
      },
      {
        text: this.translate.instant('ui.po.entity.isSubsidy'),
        value: true
      },
      {
        text: this.translate.instant('ui.po.entity.nonSubsidy'),
        value: false
      }
    ];

    this.paymentStatusOption = [
      {
        text: this.translate.instant('ui.po.status.allStatus'),
        value: ''
      },
      {
        text: this.translate.instant('ui.po.status.paid'),
        value: 'paid'
      },
      {
        text: this.translate.instant('ui.po.status.unpaid'),
        value: 'unpaid'
      },
      {
        text: this.translate.instant('ui.po.status.cancel'),
        value: 'cancel'
      },
      {
        text: this.translate.instant('ui.po.status.expired'),
        value: 'expired'
      },
      {
        text: this.translate.instant('ui.po.status.failed'),
        value: 'failed'
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',

      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        delete qParams['skip'];
        return this._poRest.findAll(qParams).pipe(
          switchMap((doc: any) => {
            const dataFormatted = doc?.data.map(d => {
              d.createdAt = moment(d?.createdAt)
                .utcOffset('+0700')
                .format('D MMMM YYYY HH:mm');

              return d;
            });

            doc.data = dataFormatted;

            return of(doc);
          })
        );
      },

      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'deleteRecord',
      handle: event => {
        const { _id } = event.data;
        return this._poRest.delete(_id);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['PoListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();
    this.getDealers();

    return this.callHook('init');
  }

  openPaymentUrl(value) {
    this.urlService.openLinkNewTab(value);
  }

  showDialogConfirmationRetry(value, status, key) {
    this.dialogKey = key;
    if (status === 'unpaid') {
      this.dialogHeader = this.translate.instant('ui.po.action.retry.retryLabel');
    } else {
      this.dialogHeader = this.translate.instant('ui.po.action.recreate.recreateLabel');
    }
    this.displayDialog = true;
    this.recreateOrRetryId = value;
  }

  onCancelDialogRetry() {
    this.displayDialog = false;
  }

  async onConfirmDialogRetry() {
    try {
      this.isLoadingRetry = true;
      await this.recreateOrRetryTransaction(this.recreateOrRetryId, this.dialogKey);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Error';
      console.log(error);
      this.comp._systemMessage.log({
        message: errorMessage,
        type: 'error',
        scroll: true
      });
    } finally {
      // Reset loading state and close the modal
      this.isLoadingRetry = false;
      this.displayDialog = false;
    }
  }

  async recreateOrRetryTransaction(id, key) {
    try {
      const response = await this.recreateOrRetryOrdersFunction(id, key);

      const message = response?.message;
      const messageType = response.data ? 'success' : 'error';

      // load data again after successful recreate or retry transaction
      this.callHookDirectly('init');

      this.comp._globalSystemMessage.log({
        message,
        type: messageType,
        showAs: 'growl',
        showSnackBar: messageType === 'error',
        scroll: true,
        timer: 5000
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Error';

      this.comp._globalSystemMessage.log({
        message: errorMessage,
        type: 'error',
        showAs: 'growl',
        showSnackBar: false,
        scroll: true,
        timer: 5000
      });
    }
  }

  async recreateOrRetryOrdersFunction(value, key) {
    if (key === 'recreate') {
      return await this._poRest.recreateOrders(value).toPromise();
    } else if (key === 'retry') {
      return await this._poRest.retryOrders(value).toPromise();
    } else {
      throw new Error('Invalid value. It should be either "recreate" or "retry".');
    }
  }

  convertToIdr(value) {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });
    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }

  convertStatus = status => {
    const statusFormated = status.toLowerCase();
    switch (statusFormated) {
      case 'confirmed':
        return 'ui.po.status.confirmed';
      case 'completed':
        return 'ui.po.status.completed';
      case 'paid':
        return 'ui.po.status.paid';
      case 'processing':
        return 'ui.po.status.processing';
      case 'unpaid':
        return 'ui.po.status.unpaid';
      case 'belum dikirim':
        return 'ui.po.status.undelivered';
      case 'sedang dikirim':
        return 'ui.po.status.delivered';
      case 'waiting confirmation':
        return 'ui.po.status.waitingConfirmation';
      case 'waiting document':
        return 'ui.po.status.waitingDoc';
      case 'sudah diterima':
        return 'ui.po.status.accepted';
      case 'expired':
        return 'ui.po.status.expired';
      case 'failed':
        return 'ui.po.status.failed';
      case 'cancel':
        return 'ui.po.status.cancel';
      default:
        return status;
    }
  };

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;

    let newQParams = {
      ...this.qParams
    };

    if (!newQParams.startDate) {
      newQParams.startDate = moment(newQParams.endDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
    }

    try {
      const result = await this._poRest.findAll(newQParams).toPromise();
      const dataFormatted = result?.data?.map(d => {
        d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY - HH:mm');
        d.dealerConfirmationStatus = d.dealerConfirmationStatus[0]?.status;
        d.documentStatus = d.documentStatus[0]?.status;
        d.deliveryStatus = d.deliveryStatus[0]?.status;
        d.alamat = d.address.address;
        d.city = d.address.cityName;
        d.namaDistrik = d.address.districtName;
        d.namaSubDistrik = d.address.subDistrictName;
        d.provinsi = d.address.provinceName;
        d.isSubsidy = d.isSubsidy ? 'Subsidy' : 'Non Subsidy';
        d.dealerName = d.items[0]?.dealer?.name || '-';
        d.items = this.renderItems(d.items);
        return d;
      });
      const mapOption = {
        invNumber: { header: 'Invoice No' },
        userFullName: { header: 'Nama Pelanggan' },
        email: { header: 'Email' },
        phoneNumber: { header: 'No WA' },
        alamat: { header: 'Alamat' },
        namaDistrik: { header: 'District' },
        namaSubDistrik: { header: 'Sub-district' },
        city: { header: 'Kota' },
        provinsi: { header: 'Provinsi' },
        ktpNumber: { header: 'Nomor KTP' },
        ktpPhoto: { header: 'Foto KTP' },
        isSubsidy: { header: 'Status Subsidi' },
        items: { header: 'Variant' },
        createdAt: { header: 'Tanggal Dibuat' },
        paymentStatus: { header: 'Status Pemabayaran' },
        dealerConfirmationStatus: { header: 'Status Konfirmasi Dealer' },
        documentStatus: { header: 'Status Dokumen' },
        deliveryStatus: { header: 'Status Pengiriman' },
        subtotal: { header: 'Sub Total' },
        referral: { header: 'Referral' },
        discount: { header: 'Diskon' },
        dealerName: { header: 'Nama Dealer' }
      };
      this._export.export({
        extension: 'xlsx',
        fileName: 'Data PO',
        records: dataFormatted,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  getDealers() {
    this._poRest.getDealers().subscribe((response: any) => {
      const data = response.data?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      this.dealerOption = [...this.dealerOption, ...data];
    });
  }
}
