import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { PromoCreateComponent } from './create/promo-create.component';
import { PromoFormComponent } from './form/promo-form.component';
import { PromoListComponent } from './list/promo-list.component';
import { PromoRestService } from './promo-rest.service';
import { PromoRoutingModule } from './promo-routing.module';
import { PromoUpdateComponent } from './update/promo-update.component';
import { CKEditorModule } from 'ckeditor4-angular';

export const PROVIDERS = [PromoRestService];

@NgModule({
  imports: [
    PromoRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    CKEditorModule
  ],
  declarations: [
    PromoCreateComponent,
    PromoFormComponent,
    PromoListComponent,
    PromoUpdateComponent
  ]
})
export class PromoModule {}
