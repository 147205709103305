import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { BannerCreateComponent } from './create/banner-create.component';
import { BannerFormComponent } from './form/banner-form.component';
import { BannerListComponent } from './list/banner-list.component';
import { BannerRestService } from './banner-rest.service';
import { BannerRoutingModule } from './banner-routing.module';
import { BannerUpdateComponent } from './update/banner-update.component';

export const PROVIDERS = [BannerRestService];

@NgModule({
  imports: [
    BannerRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule
  ],
  declarations: [
    BannerCreateComponent,
    BannerFormComponent,
    BannerListComponent,
    BannerUpdateComponent
  ]
})
export class BannerModule {}
