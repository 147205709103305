import { Component } from '@angular/core';
import 'moment/locale/id';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { MarketingSupportRestService } from '../../marketing-support-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: 'list.component.html',
  styleUrls: ['./list.component.scss']
})
export class CustomDesignFormListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.column.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.customDesign.entity.programTitle',
      field: 'programTitle'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picName',
      field: 'picName'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picPhone',
      field: 'picPhone'
    },
    {
      i18nLabel: 'ui.customDesign.entity.picEmail',
      field: 'picEmail'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designType',
      field: 'designType'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designSize',
      field: 'designSize'
    },
    {
      i18nLabel: 'ui.customDesign.entity.designContent',
      field: 'designContent'
    },
    {
      i18nLabel: 'ui.customDesign.entity.file',
      field: '',
      name: 'file'
    },
    {
      i18nLabel: 'ui.customDesign.entity.link',
      field: '',
      name: 'link'
    },
    {
      i18nLabel: 'ui.customDesign.entity.maxRevise',
      field: 'maxRevise'
    },
    {
      i18nLabel: 'ui.customDesign.entity.status',
      field: '',
      name: 'status'
    }
  ];

  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  isUpdateMode: boolean = false;
  formModalCreate: FormGroup;
  formModalCreateAccepted: boolean = false;

  formModalRevise: FormGroup;
  showModalRevise = false;

  showModalResult = false;

  selectedRowData: any = null;

  fileData: any = {
    previewBase64Image: null,
    name: null,
    size: null
  };

  // prettier-ignore
  optionsDesignType: any[] = [
    { value: 'Flyer', id: 'flyer', label: 'Flyer' },
    { value: 'Poster', id: 'poster', label: 'Poster' },
    { value: 'Spanduk', id: 'spanduk', label: 'Spanduk' },
    { value: 'X-Banner/Roll-Up Banner', id: 'x-banner', label: 'X-Banner/Roll-Up Banner' },
    { value: 'Baligho', id: 'baligho', label: 'Baligho' },
    { value: 'Giant Banner', id: 'giant-banner', label: 'Giant Banner' },
    { value: 'Backdrop', id: 'backdrop', label: 'Backdrop' },
    { value: 'Interior', id: 'interior', label: 'Interior' },
    { value: 'Exterior', id: 'exterior', label: 'Exterior' },
    { value: 'Digital Socmed', id: 'digital-socmed', label: 'Digital Socmed' },
    { value: 'Shop Bill', id: 'shop-bill', label: 'Shop Bill' }
  ];

  breadcrumbs: { label: string; url: string }[];

  constructor(private _RestService: MarketingSupportRestService) {
    super();
    this.createForm();
    this.componentId = 'CustomDesignFormListComponent';
    this.headerTitle = 'ui.customDesignForm.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.customDesignForm.list.button.create',
        color: 'primary-outline',
        permissions: ['marketingSupportCustomDesignForm.create'],
        onClick: () => this.openDialogCreate()
      }
    ];

    this.breadcrumbs = [
      {
        label: 'ui.customDesignForm.list.title',
        url: '/product'
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }

        return this._RestService.findAllCustomDesignRequest(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map((d: any) => {
              d.designType = d.designType.join(', ');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'CustomDesignFormListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  createForm() {
    this.formModalCreate = new FormGroup({
      programTitle: new FormControl('', [Validators.required]),
      picName: new FormControl('', [Validators.required]),
      picPhone: new FormControl('', [Validators.required]),
      picEmail: new FormControl('', [Validators.required]),
      designType: new FormControl('', [Validators.required]),
      designSize: new FormControl('', [Validators.required]),
      designContent: new FormControl('', [Validators.required]),
      file: new FormControl(''),
      link: new FormControl('')
    });

    this.formModalRevise = new FormGroup({
      id: new FormControl(''),
      reviseNote: new FormControl('')
    });
  }

  addNumber(number: number) {
    return number + 1;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  openDialogRevise(data: any) {
    this.showModalRevise = true;
    this.formModalRevise.controls['id'].setValue(data._id);
    this.selectedRowData = data;
  }

  onDesignTypeChange(e: any) {
    if (e.target.checked) {
      // prettier-ignore
      const designType = this.formModalCreate.controls['designType'].value || [];
      if (designType.length >= 3) {
        e.target.checked = false;
        return;
      }
      designType.push(e.target.value);
      this.formModalCreate.controls['designType'].setValue(designType);
    } else {
      // prettier-ignore
      const designType = this.formModalCreate.controls['designType'].value || [];
      designType.splice(designType.indexOf(e.target.value), 1);
      this.formModalCreate.controls['designType'].setValue(designType);
    }
  }

  openDialogResult(data: any) {
    this.showModalResult = true;
    this.selectedRowData = data;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const sizeLimit = 5242880;
      if (event.target.files[0].size > sizeLimit) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.comp._translate.instant('ui.form.validation.maxUpload', {
            max: '5 mb'
          })
        });
        this.fileData = {
          previewBase64Image: null,
          name: null,
          size: null
        };
        return false;
      }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const isPdf = event.target.files[0]?.type === 'application/pdf';

      if (!isPdf) {
        reader.onload = () => {
          this.fileData = {
            previewBase64Image: reader.result as string,
            name: file.name,
            size: fileSizeMB + 'MB'
          };
        };
        reader.readAsDataURL(file);
      } else {
        this.fileData = {
          previewBase64Image: 'pdf',
          name: file.name,
          size: fileSizeMB + 'MB'
        };
      }

      this.formModalCreate.patchValue({ file: file });
    }
  }

  setProductCode(event) {
    const valueFormatted = event.target.value.toLowerCase();
    const codeProduct = valueFormatted.replace(/\s+/g, '-');
    this.formModalCreate.controls['code'].setValue(codeProduct);
  }

  checkError(inputName: string) {
    return (
      this.formModalCreate.controls[inputName].invalid &&
      (this.formModalCreate.controls[inputName].dirty ||
        this.formModalCreate.controls[inputName].touched)
    );
  }

  async createCustomDesignData(value: any) {
    await this._RestService
      .createCustomDesign(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.customDesign.create'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.closeModalCreate();

          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant('error.customDesign.save'),
          type: 'error',
          scroll: true
        });
      });
  }

  openFile(file: any) {
    const payload = { storageFileName: file?.storageFileName };
    this._RestService.getPresignedUrl(payload).subscribe(data => {
      window.open(data.url, '_blank');
    });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    const payload = new FormData();
    payload.append('programTitle', formValue.programTitle);
    payload.append('picName', formValue.picName);
    payload.append('picPhone', formValue.picPhone);
    payload.append('picEmail', formValue.picEmail);
    payload.append('designType', formValue.designType);
    payload.append('designSize', formValue.designSize);
    payload.append('designContent', formValue.designContent);
    payload.append('file', formValue.file);
    payload.append('link', formValue.link);

    this.createCustomDesignData(payload);
  }

  async reviseCustomDesign(value: any) {
    await this._RestService
      .reviseCustomDesign(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'ui.customDesignForm.successMessage.revise'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.closeModalRevise();

          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant(
            'ui.customDesignForm.errorMessage.revise'
          ),
          type: 'error',
          scroll: true
        });
      });
  }

  saveModalRevise() {
    const formValue = this.formModalRevise.value;
    console.log(formValue);
    if (!this.formModalRevise.valid) {
      CommonService.markAsDirty(this.formModalRevise);
      return;
    }
    this.reviseCustomDesign(formValue);
  }

  closeModalCreate() {
    this.showModalCreate = false;
    this.formModalCreate.reset();
  }

  closeModalRevise() {
    this.showModalRevise = false;
    this.formModalRevise.reset();
    this.selectedRowData = null;
  }

  getStatusColor(status: string) {
    if (status == 'submitted') return 'tosca';
    if (status == 'on_progress') return 'green';
    if (status == 'request_revised') return 'orange';
    if (status == 'rejected') return 'red';
    if (status == 'done') return 'blue';
    if (status == 'closed') return 'gray';
  }
}
