import {
  of as observableOf,
  zip as observableZip,
  Observable,
  Subscription
} from 'rxjs';
import * as _ from 'lodash';
import { AutoComplete } from 'primeng/autocomplete';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/operators';

export class CommonService {
  static getUniqueId(size: number = 9) {
    return _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXZY1234567890', size).join('');
  }

  static filterArrayLike(arr: any[], pathToMatch: string, query: string) {
    return arr.filter(o => {
      const regex = new RegExp(query, 'gi');
      const valToMatch = _.get(o, pathToMatch, '');
      return regex.test(valToMatch);
    });
  }

  static markAsDirty(group: FormGroup | FormArray) {
    group.markAsDirty();
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsDirty();
      } else {
        CommonService.markAsDirty(group.controls[i]);
      }
    }
  }

  static iterateFormControls(
    group: FormGroup | FormArray,
    iteratee: Function,
    prevKey: string = ''
  ) {
    for (const i in group.controls) {
      const targetKey = prevKey.length ? `.${i}` : i;
      if (group.controls[i] instanceof FormControl) {
        iteratee(group.controls[i], prevKey + targetKey);
      } else {
        CommonService.iterateFormControls(
          group.controls[i],
          iteratee,
          prevKey + targetKey
        );
      }
    }
  }

  static syncFormControls(controls: any = {}) {
    controls = _.castArray(controls);

    const subscriptions: Subscription[] = [];

    const sync = control => {
      return control.from.valueChanges.debounceTime(500).subscribe(value => {
        control.to.setValue(value);
      });
    };

    _.forEach<any, any>(controls, (control, controlIndex) => {
      subscriptions[controlIndex] = sync(control);
    });

    return {
      disconnect: (index: number) => {
        if (subscriptions[index]) {
          subscriptions[index].unsubscribe();
          subscriptions.splice(index, 1);
        }
      },
      sync: control => {
        control.to.setValue(control.from.value);
        subscriptions.push(sync(control));

        return subscriptions.length - 1;
      }
    };
  }

  static baseName(targetPath: string) {
    return targetPath.split(/[\\/]/).pop();
  }

  static joinToString(strings: (string | number)[], separator: string = '.') {
    return strings
      .filter(s => s !== null && s !== undefined && s !== '')
      .join(separator);
  }

  static assignObjectTreePath(
    object: Object,
    childPaths: string[] = [],
    treePathSourceName: string = 'id',
    treePathPropertyName: string = 'treePath',
    treeParentPathPropertyName: string = 'treeParentPath',
    ignoreArrayIndex: boolean = false,
    treeParentPath: string = ''
  ) {
    _.forEach(object, (o, oIndex) => {
      let objectTreePath = _.get(o, treePathSourceName);
      if (!ignoreArrayIndex && _.isArray(object)) {
        objectTreePath = `${objectTreePath}.${oIndex}`;
      }

      _.set(o, treeParentPathPropertyName, treeParentPath); // set treeParentPath

      objectTreePath = treeParentPath
        ? `${treeParentPath}.${objectTreePath}`
        : objectTreePath;
      _.set(o, treePathPropertyName, objectTreePath); // set treePath

      childPaths.forEach(childPath => {
        if (_.has(o, childPath)) {
          const children = _.get(o, childPath);
          if (_.size(children)) {
            CommonService.assignObjectTreePath(
              children,
              childPaths,
              treePathSourceName,
              treePathPropertyName,
              treeParentPathPropertyName,
              ignoreArrayIndex,
              objectTreePath
            );
          }
        }
      });
    });
  }

  static cloneClassInstance<T>(classInstance: T) {
    return Object.assign(
      Object.create(Object.getPrototypeOf(classInstance)),
      classInstance
    ) as T;
  }

  // TODO: Refactor autocomplete related functions below to a new service rather than being here

  static searchLocalACItems(
    ev,
    sourceVarName: string,
    suggestionVarName: string
  ) {
    this[suggestionVarName] = [];
    this[suggestionVarName] = CommonService.filterArrayLike(
      this[sourceVarName],
      'name',
      ev.query
    );
  }

  static onLocalACDropdown(
    ev,
    sourceVarName: string,
    suggestionVarName: string
  ) {
    setTimeout(() => {
      this[suggestionVarName] = this[sourceVarName];
    }, 100);
  }

  static remoteACItemsHandler(params: {
    remoteParams: Function;
    remoteRequest: Function;
    remoteRequestMap: Function;
    acConfig?: any;
    element?: Function;
  }) {
    const store: any = {};
    const bind = type => {
      const methodParams = [
        store,
        type,
        params.remoteParams,
        params.remoteRequest,
        params.remoteRequestMap,
        params.acConfig || {}
      ];
      if (params.element) {
        methodParams.push(params.element);
      }
      return CommonService.populateRemoteACItems.bind({}, ...methodParams);
    };
    return {
      search: bind('search'),
      dropdown: bind('dropdown')
    };
  }

  static observableWaitAll(obs, name) {
    return observableZip(...(_.get(obs, name, []) || []));
  }

  private static populateRemoteACItems<T>(
    store: {
      [key: string]: any;
    },
    type: string,
    remoteParams: Function,
    remoteRequest: Function,
    remoteRequestMap: Function,
    acConfig: any = {},
    element: Function | AutoComplete,
    event: any
  ) {
    let suggestions = [];

    let targetElement: AutoComplete;
    if (_.isFunction(element)) {
      const elementFn: Function = element;
      targetElement = elementFn();
    } else {
      targetElement = element as AutoComplete;
    }

    // if (!$(targetElement.inputEL.nativeElement).hasClass('loading')) {
    //   $(targetElement.inputEL.nativeElement).addClass('loading');
    // }

    store.lastQuery = store.currentQuery;
    store.currentQuery = event.query;

    const lastSuggestion = _.get(store, 'lastSuggestions', []);
    if (
      store.currentQuery === store.lastQuery &&
      lastSuggestion.length > 1 &&
      store.skipThisIfLine
    ) {
      /*
       *  restore cached suggestions if query is unchanged and cached suggestions are more than 1
       *  but currently always skip this block because hasn't been accepted by boss
       */
      suggestions = store.lastSuggestions;
      store.obsSuggestions = observableOf(suggestions);
    } else {
      const params = remoteParams(event, type);
      store.obsSuggestions = remoteRequest(...params).pipe(
        map(remoteRequestMap as any),
        tap(remoteSuggestions => (store.lastSuggestions = remoteSuggestions))
      );
    }

    store.lastExecute = type;

    if (store.subSuggestions) {
      store.subSuggestions.unsubscribe();
    }
    store.subSuggestions = store.obsSuggestions.subscribe(
      populatedSuggestions => {
        if (acConfig.prependNull) {
          populatedSuggestions = _.range(0, +acConfig.prependNull)
            .map(() => null)
            .concat(populatedSuggestions);
        }

        targetElement.suggestions = populatedSuggestions;
        targetElement.handleSuggestionsChange();

        // $(targetElement.inputEL.nativeElement).removeClass('loading');
      }
    );
  }

  static removeNonUnicodeCharacterFromString(str: string) {
    return str.replace(/[^\x00-\x7F]/g, '');
  }

  static isFieldError(formGroup: FormGroup, fieldKey: string) {
    return (
      formGroup.controls[fieldKey].invalid &&
      (formGroup.controls[fieldKey].dirty ||
        formGroup.controls[fieldKey].touched)
    );
  }
}
