import { Component, OnInit } from '@angular/core';
import { DashboardRestService } from '../dashboard-rest.service';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: 'dashboard-list.component.html'
})
export class DashboardListComponent implements OnInit {
  summaryData: any;

  constructor(private _DashboardRest: DashboardRestService) {}

  ngOnInit() {
    this.loadSummaryData();
  }

  loadSummaryData() {
    this._DashboardRest.getAllSummary().subscribe(
      (data: any) => {
        this.summaryData = data;
      },
      (error: any) => {
        console.error('Error getting summary data dashboard:', error);
      }
    );
  }

  convertTotal(value: any): string | null {
    if (value == null || value === '' || typeof value === 'undefined') {
      return null;
    }

    const numberString = value.toString();
    const [integerPart] = numberString.split('.');

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    );

    return formattedIntegerPart;
  }
}
