// user-modal.component.ts
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKRestService } from '../spk-rest.service';
import { CommonService } from '../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-create-modal',
  templateUrl: 'create-modal.component.html'
})
export class CreateModalComponent {
  formModalCreate: FormGroup;
  invoiceImageBase64: any;
  selectedFileName: string;
  fileSize: any;
  showModalCreate: boolean = false;
  loadingCreate: boolean = false;
  unitItems: any[] = [];
  dealerItems: any[] = [];
  keywordNIK: any;
  createSPKOnly: boolean = false;
  isCustomerExist: string | null = null;
  createNewSPK: boolean = false;

  constructor(
    private _SPKRest: SPKRestService,
    public spkListComponent: SPKListComponent
  ) {}

  ngOnInit(): void {
    this.initFormSPK();
  }

  onChangeCustomerOption(e) {
    const value = e.target?.value == 'true' ? true : false;
    this.getUnits(value);
    this.formModalCreate.reset();
    this.enableFormInvoice();
    this.createNewSPK = false;
  }

  async onSearchNIK() {
    if (!this.keywordNIK?.toString().trim().length) return;
    await this._SPKRest.findSubsidyByNIK(this.keywordNIK).subscribe((data: any) => {
      if (Object.keys(data).length) {
        Swal.fire({
          icon: 'question',
          title: 'NIK ditemukan, gunakan data untuk membuat SPK?',
          showCancelButton: true,
          confirmButtonText: 'Ya, gunakan',
        }).then(result => {
          if (result.isConfirmed) {
            this.onChangeUnit({ target: { value: data.productVariantId } })

            this.createSPKOnly = true;
            this.formModalCreate.patchValue({
              nik: data.nik,
              fullname: data.fullname,
              email: data.email,
              whatsapp: data.whatsapp,
              fatherName: data.fatherName,
              productVariantId: data.productVariantId,
              unitType: data.unitType,
              dealerId: data.dealerId,
              address: data.address,
            });

            this.disableFormInvoice()
            this.createNewSPK = true;
          }
        })
      } else {
        Swal.fire(
          '',
          `User dengan NIK ${this.keywordNIK} tidak ditemukan!`,
          'question'
        )
      }
    });
  }

  async getUnits(getAllStock=false) {
    await this._SPKRest.getUnitType(getAllStock).subscribe((data: any) => {
      this.unitItems = data;
    });
  }

  getVariantDealers(productVariantId: string) {
    this.formModalCreate.patchValue({ dealerId: '' });
    this._SPKRest.getVariantDealers(productVariantId).subscribe((data: any) => {
      this.dealerItems = data;
    });
  }

  onChangeUnit(e) {
    const productVariantId = e.target.value
    const selectedUnit = this.unitItems.find(item => item.productVariantId === productVariantId);
    this.formModalCreate.patchValue({ unitType: selectedUnit.name });
    this.getVariantDealers(productVariantId);
  }

  onChangeRegion(e) {
    const dealerId = e.target.value;
    this.formModalCreate.patchValue({ dealerId });
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      reader.onload = () => {
        this.invoiceImageBase64 = reader.result as string;
      };

      reader.readAsDataURL(file);

      this.formModalCreate.patchValue({
        invoice: { file }
      });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event: Event) {
    event.preventDefault();
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formModalCreate.patchValue({
      invoice: { file: null }
    });
  }

  resetFileImg() {
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  enableFormInvoice() {
    this.formModalCreate.get('nik')?.enable();
    this.formModalCreate.get('fullname')?.enable();
    this.formModalCreate.get('email')?.enable();
    this.formModalCreate.get('whatsapp')?.enable();
    this.formModalCreate.get('fatherName')?.enable();
    this.formModalCreate.get('productVariantId')?.enable();
    this.formModalCreate.get('unitType')?.enable();
    this.formModalCreate.get('dealerId')?.enable();
    this.formModalCreate.get('address')?.enable();
  }

  disableFormInvoice() {
    this.formModalCreate.get('nik')?.disable();
    this.formModalCreate.get('fullname')?.disable();
    this.formModalCreate.get('email')?.disable();
    this.formModalCreate.get('whatsapp')?.disable();
    this.formModalCreate.get('fatherName')?.disable();
    this.formModalCreate.get('productVariantId')?.disable();
    this.formModalCreate.get('unitType')?.disable();
    this.formModalCreate.get('dealerId')?.disable();
    this.formModalCreate.get('address')?.disable();
  }

  async createSPK() {
    if (this.createSPKOnly) {
      this.enableFormInvoice()
    }

    const values = this.formModalCreate.value;
    const payload = new FormData();

    Object.keys(values).forEach(item => {
      if (item === 'invoice') {
        payload.append('invNumber', values[item]?.invNumber);
        payload.append('invFile', values[item]?.file);
      } else {
        payload.append(item, values[item]);
      }
    })
    payload.append('createSPKOnly', this.createSPKOnly.toString());
    // payload.append('units', JSON.stringify([{
    //   unit: values.unitType,
    //   total: 1,
    //   productVariantId: values.productVariantId
    // }]));

    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
    } else {
      this.loadingCreate = true;
      await this._SPKRest
      .createSPK(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Create SPK successfully!',
            '',
            'success'
          )

          this.spkListComponent.callHookDirectly('init');
          this.openCreateSPKModal(false);
        }
      })
      .catch(error => {
        const errorData = { ...error };
        Swal.fire(
          'Gagal',
          errorData?.response?.data?.message,
          'error'
        )
      });
      this.loadingCreate = false;
    }
  }

  initFormSPK() {
    this.formModalCreate = new FormGroup({
      nik: new FormControl('', [Validators.required]),
      fullname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      whatsapp: new FormControl('', [Validators.required]),
      fatherName: new FormControl('', [Validators.required]),
      productVariantId: new FormControl('', [Validators.required]),
      unitType: new FormControl('', [Validators.required]),
      dealerId: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      invoice: new FormGroup({
        invNumber: new FormControl(''),
        file: new FormControl(''),
      }, { validators: this.invoiceValidation })
    });
  }

  invoiceValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const invNumberControl = control.get('invNumber')?.value;
    const fileControl = control.get('file')?.value;

    if ((fileControl && !invNumberControl) || (!fileControl && invNumberControl)) {
      return { requiredField: true };
    }

    return null;
  }


  openCreateSPKModal(val) {
    this.showModalCreate = val;
    this.formModalCreate.reset();
    this.createSPKOnly = false;
    this.enableFormInvoice();
    this.isCustomerExist = null;
  }
}
