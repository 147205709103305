import { Injectable } from '@angular/core';
import { Table } from 'primeng/table';

import { ApiQueryOption } from '../http/api-query-option.model';
import { GridTablePagerConfig } from './grid-table-config.model';
import { GridTableFilterService } from './grid-table-filter.service';

@Injectable()
export class GridTableService {

  constructor(
    private _gridTableFilter: GridTableFilterService,
  ) { }

  generateApiQueryOptionFromGridInfo(
    pager: GridTablePagerConfig,
    includeTotalCount: boolean,
    dataTable: Table,
    customFilters?: { [key: string]: IApiQueryOptionFilterValue[] }
  ): ApiQueryOption {

    const qOption = new ApiQueryOption();
    qOption.includeTotalCount = includeTotalCount;

    if (pager) {
      qOption.take = pager.itemsPerPage;
      qOption.skip = (pager.pageNum - 1) * pager.itemsPerPage;
      qOption.page = pager.pageNum;
    }

    if (dataTable) {
      if (dataTable.sortField) {
        const so = (dataTable.sortOrder === 1) ? 'asc' : 'desc';
        qOption.sortBy = dataTable.sortField;
        qOption.sortDir = so;
        // qOption.sorts?.push({ field: dataTable.sortField, direction: so });
      }

      this._gridTableFilter.appendQBodyFilters(qOption, customFilters);
    }

    return qOption;
  }

  generateCombinedApiFilters(
    query: string,
    filterMap: any
  ): {
      [key: string]: IApiQueryOptionFilterValue[]
    } {
    const queries = {
      default: query,
    };

    const { qBody } = this._gridTableFilter.parse(queries, filterMap);

    return qBody;
  }
}
