import { Component, Output, EventEmitter } from '@angular/core';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { BannerRestService } from '../banner-rest.service';

@Component({
  selector: 'app-banner-create',
  templateUrl: 'banner-create.component.html'
})
export class BannerCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(private _bannerRest: BannerRestService) {
    super();
    this.componentId = 'BannerComponent';
    this.routeURL = '/banner';
    this.entrySuccessI18n = 'success.banner.create';
    this.headerTitle = 'ui.banner.create.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._bannerRest.create(formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'BannerFormComponent:workflowBuildForm'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
