import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss']
})
export class ChipComponent {
  @Input() backgroundColor: string = '#1F95450F';
  @Input() textColor: string = '#fff';
}
