import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-volta-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() type: string = '';

  get chipClass() {
    const classMap = {
      'primary': 'bg-primary',
      'secondary': 'bg-secondary',
      'success': 'bg-success',
      'danger': 'bg-danger',
      'warning': 'bg-warning'
    };

    return classMap[this.type] || '';
  }
}
