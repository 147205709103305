import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { AdminLayoutComponent } from './admin-layout.component';
import { AdminLayoutHeaderComponent } from './header/admin-layout-header.component';
import { AdminLayoutSidebarComponent } from './sidebar/admin-layout-sidebar.component';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    BsDropdownModule,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    LibrariesModule,
    SharedModule,
    TranslateModule.forChild(),
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  declarations: [
    AdminLayoutComponent,
    AdminLayoutHeaderComponent,
    AdminLayoutSidebarComponent
  ],
  exports: [AdminLayoutComponent]
})
export class AdminLayoutModule {}
