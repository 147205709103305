import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { MarketingSupportRestService } from '../../marketing-support-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: 'list.component.html'
})
export class GeneralDesignListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.column.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.generalDesign.entity.title',
      field: 'title'
    },
    {
      i18nLabel: 'ui.generalDesign.entity.link',
      field: 'link'
    },
    {
      i18nLabel: 'ui.generalDesign.entity.updateAt',
      field: 'updatedAt'
    }
  ];

  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  isUpdateMode: boolean = false;
  formModalCreate: FormGroup;
  breadcrumbs: { label: string; url: string }[];

  constructor(private _RestService: MarketingSupportRestService) {
    super();
    this.createFormProduct();
    this.componentId = 'GeneralDesignListComponent';
    this.headerTitle = 'ui.generalDesign.list.title';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.generalDesign.list.button.create',
        color: 'primary-outline',
        permissions: ['generalDesign.create'],
        onClick: () => this.openDialogCreate()
      }
    ];

    this.breadcrumbs = [
      {
        label: 'ui.generalDesign.list.title',
        url: '/product'
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        if (qParams.keyword == '') {
          delete qParams['keyword'];
        }

        return this._RestService.findAllGeneralDesign(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY - HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'GeneralDesignListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  openDialogUpdate(data: any) {
    this.formModalCreate = new FormGroup({
      _id: new FormControl(data._id),
      title: new FormControl(data.title, [Validators.required]),
      link: new FormControl(data.link, [Validators.required])
    });
    this.showModalCreate = true;
    this.isUpdateMode = true;
  }

  createFormProduct() {
    this.formModalCreate = new FormGroup({
      title: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required])
    });
  }

  actionDeleteData(id: string) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteProductData(id);
      } else {
        return;
      }
    });
  }

  async createGeneralDesignData(value: any) {
    await this._RestService
      .createGeneralDesign(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.generalDesign.create'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant('error.generalDesign.save'),
          type: 'error',
          scroll: true
        });
      });
  }

  async updateGeneralDesignData(value: any) {
    await this._RestService
      .updateGeneralDesign(value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.generalDesign.update'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });

          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant('error.generalDesign.save'),
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductData(id: string) {
    await this._RestService
      .deleteGeneralDesign(id)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.generalDesign.delete'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(_ => {
        this.comp._systemMessage.log({
          message: this.comp._translate.instant('error.generalDesign.delete'),
          type: 'error',
          scroll: true
        });
      });
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    if (formValue._id === undefined || formValue._id === null) {
      this.createGeneralDesignData(formValue);
    } else {
      this.updateGeneralDesignData(formValue);
    }
    this.showModalCreate = false;
    this.isUpdateMode = false;
    this.formModalCreate.reset();
  }

  closeModalCreate() {
    this.showModalCreate = false;
    this.isUpdateMode = false;
    this.formModalCreate.reset();
  }
}
