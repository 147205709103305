import { Component } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../libraries/common/common.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-update-product',
  templateUrl: 'update-product.component.html'
})
export class ProductUpdateComponent extends BaseListBComponent<any> {
  formUpdate: FormGroup;
  dataUpdate: any;
  breadcrumbs: { label: string; url: string }[];
  pramd: string = 'oke';
  idParams: any;
  prodId: any;
  typeSparepart: boolean;
  nameProduct: any;

  constructor(
    private _ProductRest: ProductRestService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    super();
    this.updateFormProduct();
    this.componentId = 'ProductUpdateComponent';
    this.headerTitle = 'ui.product.update.titleProduct';
    this.titlePage = '';
    this.route.params.subscribe(res => {
      this.prodId = res.id;
    });
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/motorcycle'
      }
      // {
      //   label: 'ui.product.update.titleProduct',
      //   url: `/product/${this.prodId}/update-product`
      // }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._ProductRest.loadProduct(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.dataUpdate = res;
        //
        this.nameProduct = res.name;
        // Check if titlePage is empty before assigning a new value
        if (!this.titlePage) {
          this.titlePage = this.nameProduct;
        }
        // add to breadcrumbs
        const productName = {
          label: res.name,
          url: `/motorcycle/${this.prodId}/list-variant`
        };
        // Check if productName already exists in the breadcrumbs array
        const existingProduct = this.breadcrumbs.find(
          breadcrumb => breadcrumb.label === productName.label
        );
        if (!existingProduct) {
          this.breadcrumbs.push(productName);
        }
        //===============
        if (this.dataUpdate.type === 'sparepart') {
          this.typeSparepart = true;
        }
        this.formUpdate.patchValue({
          code: this.dataUpdate.code,
          name: this.dataUpdate.name,
          type: this.dataUpdate.type,
          title: this.dataUpdate.title,
          caption: this.dataUpdate.caption,
          isPublish: this.dataUpdate.isPublish
        });
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ProductUpdateComponent:loadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  updateFormProduct() {
    this.formUpdate = new FormGroup({
      code: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      title: new FormControl(''),
      caption: new FormControl(''),
      isPublish: new FormControl(true)
    });
  }

  setProductCode(event) {
    const valueFormatted = event.target.value.toLowerCase();
    const codeProduct = valueFormatted.replace(/\s+/g, '-');
    this.formUpdate.controls['code'].setValue(codeProduct);
  }

  getSelect(event) {
    const typeSelect = event.target.value;
    if (typeSelect === 'sparepart') {
      this.typeSparepart = true;
    } else {
      this.typeSparepart = false;
    }
  }

  async updateProductData(value) {
    const result = await this._ProductRest
      .updateProduct(this.comp.routeParams.id, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant('success.product.update'),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  saveUpdate() {
    const formValue = this.formUpdate.value;
    if (!this.formUpdate.valid) {
      CommonService.markAsDirty(this.formUpdate);
      return;
    }
    this.updateProductData(formValue);
    this.callHookDirectly('init');
  }

  cancelUpdate() {
    this.location.back();
  }
}
