import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { AffiliatorRestService } from '../affiliator-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { AclService } from '../../../libraries/auth/acl.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliator-list',
  templateUrl: 'affiliator-list.component.html'
})
export class AffiliatorListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.affiliator.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.affiliator.entity.memberId',
      field: 'memberId',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.status',
      name: 'status',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.name',
      field: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.phone',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.ktpNumber',
      field: 'ktpNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.ktpFile',
      field: '',
      name: 'ktpFile',
      sort: false
    },
    {
      i18nLabel: 'ui.affiliator.entity.address',
      field: 'address',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.province',
      field: 'province.provName',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.city',
      field: 'city.cityName',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.dealer',
      field: '',
      name: 'dealer',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.bankName',
      field: 'bankName',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.bankAccount',
      field: 'bankAccount',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.bankBranch',
      field: 'bankBranch',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.photo',
      field: '',
      name: 'photo',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    },
    {
      i18nLabel: 'ui.affiliator.entity.statusHistory',
      field: '',
      name: 'statusHistory',
      sort: false
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isDealerModalOpen: boolean = false;
  dealerOpened: any = {};
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';
  isstatusHistoryModalOpen: boolean = false;
  statusHistoryOpened: any = {};

  affiliatorStatuses = [
    {
      text: 'Semua Status',
      value: ''
    },
    {
      text: 'Waiting',
      value: 'waiting'
    },
    {
      text: 'Approved',
      value: 'approved'
    },
    {
      text: 'Rejected',
      value: 'rejected'
    }
  ];

  constructor(
    public _authentication: AuthenticationService,
    private _AffiliatorRest: AffiliatorRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    super();
    this.componentId = 'AffiliatorListComponent';
    this.headerTitle = 'ui.affiliator.list.title';

    this.qParams.status = '';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._AffiliatorRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['AffiliatorListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  openDealerModal(data) {
    this.isDealerModalOpen = true;
    this.dealerOpened = data;
  }

  closeDealerModal() {
    this.isDealerModalOpen = false;
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  showstatusHistoryModal(data: any) {
    this.isstatusHistoryModalOpen = true;
    this.statusHistoryOpened = data;
  }

  closestatusHistoryModal() {
    this.isstatusHistoryModalOpen = false;
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._AffiliatorRest.getPresignedUrl(payload).subscribe(
      data => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);

        if (photoPreview) {
          photoPreview.src = data.url;
          photoPreview.href = data.url;
        }
      },
      error => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);
        if (photoPreview) {
          photoPreview.src = '';
        }
      }
    );
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(file, title) {
    const payload = { storageFileName: file?.storageFileName };
    await this.renderPresignedUrlImage(payload, '#js-photoPreview');
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;
  }

  onApproval(id, action) {
    Swal.fire({
      title: `${action == 'approved' ? 'Approve' : 'Reject'} Affiliator?`,
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.approveAffiliator(id, action);
      } else {
        return;
      }
    });
  }

  async approveAffiliator(id, action) {
    await this._AffiliatorRest
      .approval({ _id: id, status: action })
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire('Status updated successfully!', '', 'success');
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._AffiliatorRest.findAll({}).toPromise();
      const mapOption = {
        memberId: { header: 'Member ID' },
        name: { header: 'Nama' },
        phone: { header: 'No HP' },
        email: { header: 'Email' },
        ktpNumber: { header: 'No KTP' },
        address: { header: 'Alamat Tinggal' },
        province: { header: 'Provinsi' },
        city: { header: 'Kota' },
        dealer: { header: 'Dealer' },
        bankName: { header: 'Nama Bank' },
        bankAccount: { header: 'No Rekening' },
        status: { header: 'Status' },
        createdAt: { header: 'Tanggal Submit' }
      };
      const payload = result.data.map(item => ({
        ...item,
        province: item?.province?.provName,
        city: item.city?.cityName,
        dealer: item.dealer?.name,
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'Affiliator',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  getStatusColor(status: string) {
    if (status == 'waiting') return 'warning';
    if (status == 'approved') return 'success';
    if (status == 'rejected') return 'danger';
  }

  formatDate(date: string) {
    return moment(date).format('DD MMMM YYYY HH:mm');
  }
}
