import { Component } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { ContactRestService } from '../contact-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
@Component({
  selector: 'app-contact-list',
  templateUrl: 'contact-list.component.html'
})
export class ContactListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.contact.entity.name',
      field: 'name',
      sort: true
    },
    {
      i18nLabel: 'ui.contact.entity.customerNo',
      field: 'phone',
      sort: true
    },
    {
      i18nLabel: 'ui.contact.entity.email',
      field: 'email',
      sort: true
    },
    {
      i18nLabel: 'ui.contact.entity.message',
      field: 'message',
      sort: true
    },
    {
      i18nLabel: 'ui.contact.entity.createdDate',
      field: 'createdAt',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  isLoadingExportExcel: boolean = false;

  constructor(
    private _ContactRest: ContactRestService,
    private _export: ExportDataTableService
  ) {
    super();
    this.componentId = 'ContactListComponent';
    this.headerTitle = 'ui.contact.list.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._ContactRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'ContactListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._ContactRest.findAll({}).toPromise();
      const mapOption = {
        name: { header: 'Nama' },
        phone: { header: 'No WA' },
        email: { header: 'Email' },
        message: { header: 'Pesan' },
        createdAt: { header: 'Tanggal Dibuat' }
      };
      this._export.export({
        extension: 'xlsx',
        fileName: 'Kontak',
        records: result.data,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
