import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class BuybackRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/buyback-tradein`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(``, { params: queryParams });
  }

  create(payload) {
    return this.request.post('', payload);
  }

  saveDraft(payload) {
    return this.request.post('/save-draft', payload);
  }

  update(payload) {
    return this.request.patch('/update', payload);
  }

  delete(id: string) {
    return this.request.delete<any>(`/${id}`);
  }

  changeStatusBuyback(payload) {
    return this.request.patch('/approval', payload);
  }

  getPresignedUrl(payload) {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.post<any>('/admin/spk/pre-signed-url', payload)
  }

  getMotorcycles(queryParams: any = {}) {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.get<any>('/admin/products/buyback?type=motorcycle', { params: queryParams });
  }

  getMotorcyclesVariant(queryParams: any = {}, id: string) {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.get<any>(`/admin/products/${id}/variant-buyback`, { params: queryParams });
  }
}
