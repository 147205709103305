import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class SubsidiRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/user-subsidy`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  deleteSubsidy(subsidyId: string) {
    return this.request.delete<any>(`${subsidyId}`);
  }

  updateSubsidy(subsidyId: string, payload: any) {
    return this.request.put<any>(`${subsidyId}`, payload);
  }

  saveDraftSubsidy(subsidyId: string) {
    return this.request.post<any>(`/save-draft/${subsidyId}`);
  }

  getSourceList() {
    return this.request.get<any>(`/source-list`);
  }

  getSummary(queryParams: any = {}) {
    return this.request.get<any>(`/summary`, { params: queryParams });
  }

  getPaymentData(queryParams: any = {}) {
    return this.request.get<any>(`/payment-data`, { params: queryParams });
  }

  getVisitors(queryParams: any = {}) {
    const request = this._request.new(APP_CONST.API_MASTER);
    return request.get<any>(`/admin/visitor`, { params: queryParams });
  }
}
