import { Component } from '@angular/core';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';

@Component({
  selector: 'app-location-form',
  templateUrl: 'location-form.component.html'
})
export class LocationFormComponent extends BaseFormBComponent {
  constructor() {
    super();

    this.componentId = 'LocationFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [
          {
            label: 'ui.location.entity.category',
            name: 'category',
            field: 'select',
            validations: {
              required: true
            },
            templateConfig: {
              select: {
                options: [
                  {
                    directLabel: 'MAIN DEALER',
                    value: 'MAIN DEALER'
                  },
                  {
                    directLabel: 'DEALER',
                    value: 'DEALER'
                  },
                  {
                    directLabel: 'SGB',
                    value: 'SGB'
                  },
                  {
                    directLabel: 'SHOWROOM',
                    value: 'SHOWROOM'
                  }
                ]
              }
            }
          },
          {
            label: 'ui.location.entity.name',
            name: 'name',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.location.entity.phone',
            name: 'phoneNo',
            field: 'text',
            templateConfig: {
              type: 'number'
            }
          },
          {
            label: 'ui.location.entity.address',
            name: 'address',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.location.entity.latitude',
            name: 'latitude',
            field: 'text',
            validations: {
              required: true
            }
          },
          {
            label: 'ui.location.entity.longitude',
            name: 'longitude',
            field: 'text',
            validations: {
              required: true
            }
          }
        ];
      }
    });
  }
}
