import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { UserRestService } from '../user-rest.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-update',
  templateUrl: 'user-update.component.html'
})
export class UserUpdateComponent extends BaseUpdateBComponent {
  constructor(private _userRest: UserRestService) {
    super();
    this.componentId = 'UserUpdateComponent';
    this.routeURL = '/users';
    this.entrySuccessI18n = 'success.user.update';
    this.headerTitle = 'ui.user.update.title';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._userRest.load(this.comp.routeParams.id).pipe(
          switchMap(doc => {
            doc.password = null;

            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;
        delete formValue.password;
        delete formValue.confirmPassword;
        delete formValue.dealer;

        return this._userRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'UserFormComponent:workflowBuildForm',
          'UserUpdateComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
