import { Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../../libraries/base/list/base-list.bcomponent';
import { ProductRestService } from '../../product-rest.service';
import { ActivatedRoute } from '@angular/router';
import { APP_CONST } from '../../../../app.const';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonService } from '../../../../libraries/common/common.service';
@Component({
  selector: 'app-photo-list',
  templateUrl: 'photo-list.component.html'
})
export class PhotoListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.product.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.product.entity.photo',
      field: '',
      name: 'urlImage'
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  showModalCreate: boolean = false;
  breadcrumbs: { label: string; url: string, nameColor?: boolean }[];
  prodId: any;
  varId: any;
  nameProduct: any;
  photoDetail: string;
  imageModal: boolean = false;
  formModalCreate: any;

  cardImageBase64: any;
  selectedFileName: string;
  fileSize: any;
  loadingPhoto: boolean = false;

  constructor(
    private _ProductRest: ProductRestService,
    private route: ActivatedRoute
  ) {
    super();
    this.createFormProductPhoto();
    this.componentId = 'PhotoListComponent';
    this.headerTitle = 'ui.product.update.updatePhoto';
    this.deleteRecordI18nLabel = 'confirm.any.delete.label';
    this.deleteRecordI18nDescription = 'confirm.any.delete.description';

    this.route.params.subscribe(res => {
      this.varId = res.idVariant;
      this.prodId = res.idProd;
    });

    this.headerButtons = [
      {
        type: 'custom',
        label: 'ui.product.create.photo',
        color: 'primary-outline',
        onClick: () => this.openDialogCreate()
      }
    ];
    this.breadcrumbs = [
      {
        label: 'ui.product.list.title',
        url: '/motorcycle'
      },
      {
        label: '',
        url: `/motorcycle/${this.prodId}/list-variant`,
        nameColor: true,
      },
      {
        label: 'ui.product.update.updatePhoto',
        url: `/motorcycle/${this.prodId}/list-variant/list-photo/${this.varId}/idProd/${this.prodId}`
      }
    ];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._ProductRest.findVariantById(this.prodId, this.varId, {}).pipe(
          switchMap(doc => {
            this.breadcrumbs.forEach(item => {
              if (item.nameColor) {
                item.label = doc?.nameColor || '-';
              }
            })
            const dataFormatted = doc.photo?.map(photo => ({
              _id: photo._id,
              url: photo.url
            }))

            return of({ data: dataFormatted });
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'loadDataProduct',
      handle: () => {
        return this._ProductRest.loadProduct(this.prodId).pipe(
          switchMap(doc => {
            return of(doc);
          })
        );
      },
      success: res => {
        this.nameProduct = res.name;
        const productName = {
          label: `${this.nameProduct}`,
          url: `/product/${this.prodId}/list-variant`
        };
        this.breadcrumbs.splice(1, 0, productName);
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'PhotoListComponent:loadDataProduct',
          'PhotoListComponent:workflowLoadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  addNumber(number) {
    return number + 1;
  }

  openDialogCreate() {
    this.showModalCreate = true;
  }

  convertImg(url) {
    if (url.includes('/images/')) {
      const imgUrl = `${APP_CONST.API_COMPANY}${url}`;
      return imgUrl;
    } else {
      return url;
    }
  }

  showImageDialog(value) {
    if (!this.photoDetail) {
      this.loadingPhoto = true;
    }
    this.photoDetail = this.convertImg(value);
    this.imageModal = true;
  }

  onImageLoad() {
    this.loadingPhoto = false;
  }

  onImageError() {
    this.loadingPhoto = false;
  }

  createFormProductPhoto() {
    this.formModalCreate = new FormGroup({
      photo: new FormControl('', [Validators.required])
    });
  }

  actionDeletePhoto(value) {
    Swal.fire({
      title: this.comp._translate.instant(this.deleteRecordI18nLabel),
      text: this.comp._translate.instant(this.deleteRecordI18nDescription),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteProductPhotoData(value);
      } else {
        return;
      }
    });
  }

  async createProductPhotoData(valueData) {
    const formData = new FormData();
    formData.append('photo', valueData.photo);
    const result = await this._ProductRest
      .createProductPhoto(this.prodId, this.varId, formData)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.createPhoto'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async deleteProductPhotoData(value) {
    const result = await this._ProductRest
      .deleteProductPhoto(this.prodId, this.varId, value)
      .toPromise()
      .then(response => {
        if (response) {
          this.comp._globalSystemMessage.log({
            message: this.comp._translate.instant(
              'success.product.deletePhoto'
            ),
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
            scroll: true
          });
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._systemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      const sizeLimit = 1048576;

      if (event.target.files[0].size > sizeLimit) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.comp._translate.instant('error.image.maxLimitProduct')
        });
        this.resetFileImg();
        return false;
      }

      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      reader.onload = () => {
        this.cardImageBase64 = reader.result as string;
      };

      reader.readAsDataURL(file);

      this.formModalCreate.patchValue({
        photo: file
      });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event: Event) {
    event.preventDefault();
    this.cardImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formModalCreate.patchValue({
      photo: null
    });
  }

  resetFileImg() {
    this.cardImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  saveModalCreate() {
    const formValue = this.formModalCreate.value;
    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
      return;
    }
    this.createProductPhotoData(formValue);
    this.showModalCreate = false;
    this.formModalCreate.reset();
  }

  closeModalCreate() {
    this.showModalCreate = false;
    this.resetFileImg();
    this.formModalCreate.reset();
  }
}
