import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss']
})
export class AccordionComponent {
  @Input() icon: string = '';
  @Input() title: string = '-';
  @Input() description: string = '-';

  isOpen: boolean = false;

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
