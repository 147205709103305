import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { TranslatorService } from '../../../libraries/common/translator.service';
import { AdminLayoutService } from '../admin-layout.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService, Breadcrumb } from './breadcrumb/breadcrumb.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CommonService } from '../../../libraries/libraries.module';
import { UserRestService } from '../../../main/user/user-rest.service';

@Component({
  selector: 'app-admin-layout-header',
  templateUrl: 'admin-layout-header.component.html',
  styleUrls: [
    'admin-layout-header.component.scss',
    'admin-layout-header.menu-links.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutHeaderComponent implements OnInit {
  sidebarVisible: true;
  sidebarOffcanvasVisible: boolean;
  breadcrumbs: Breadcrumb[] = [];
  showChangePasswordModal: boolean = false;
  formChangePassword: FormGroup;
  loadingChangePassword: boolean = false;
  showUpdateProfileDealer: boolean = false;
  formProfileDealer: FormGroup;
  loadingUpdateProfileDealer: boolean = false;


  constructor(
    public _authentication: AuthenticationService,
    public _adminLayout: AdminLayoutService,
    public _translate: TranslateService,
    public _translator: TranslatorService,
    private breadcrumbService: BreadcrumbService,
    private _userRest: UserRestService
  ) {}

  ngOnInit() {
    this.initFormChangePassword();
    this.initFormProfileDealer();
    this.breadcrumbService.breadcrumbs$.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  isDealership() {
    return this._authentication?.user?.role?.name?.toLowerCase() === 'dealership';
  }

  async changePassword() {
    try {
      const userId: string = this._authentication.user?._id;
      const payload = {
        password: this.formChangePassword.get('password')?.value,
        newPassword: this.formChangePassword.get('newPassword')?.value,
      };
      this.loadingChangePassword = true;
      await this._userRest.changePassword(userId, payload)
        .toPromise()
        .then(res => {
          this.loadingChangePassword = false;
          this.openChangePasswordModal(false);

          Swal.fire(
            'Change password successfully!',
            '',
            'success'
          )
        });
    } catch (error) {
      this.loadingChangePassword = false;
      const err = {...error}
      Swal.fire(
        'Oops..',
        err?.response?.data?.message || 'Something went wrong!',
        'error'
      )
    }
  }

  async updateProfileDealer() {
    try {
      this.loadingUpdateProfileDealer = true;
      const userId: string = this._authentication.user?._id;
      const payload = {
        ...this.formProfileDealer.value,
        updatedBy: userId,
      };
      await this._userRest.updateProfileDealer(userId, payload)
        .toPromise()
        .then(res => {
          Swal.fire(
            'Update profile successfully!',
            '',
            'success'
          );
        });
    } catch (error) {
      const err = {...error};
      Swal.fire(
        'Oops..',
        err?.response?.data?.message || 'Something went wrong!',
        'error'
      )
    } finally {
      this.loadingUpdateProfileDealer = false;
    }
  }

  onChangePassword() {
    if (!this.formChangePassword.valid) {
      CommonService.markAsDirty(this.formChangePassword);
    } else {
      Swal.fire({
        title: 'Ubah Password?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.changePassword();
        } else {
          return;
        }
      });
    }
  }

  onUpdateProfileDealer() {
    if (!this.formProfileDealer.valid) {
      CommonService.markAsDirty(this.formProfileDealer);
    } else {
      Swal.fire({
        title: 'Update Profil Dealer?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateProfileDealer();
        } else {
          return;
        }
      });
    }
  }

  onGetDealerByUser() {
    this.loadingUpdateProfileDealer = true;
    const userId: string = this._authentication.user?._id;
    this._userRest.getDealerByUser(userId).subscribe(data => {
      this.formProfileDealer.patchValue({
        name: data.name,
        email: data.email,
        phone: data.phone,
        cp: data.cp,
        address: data.address,
        longitude: data.longitude,
        latitude: data.latitude,
        bankAccount: data.bankAccount,
        bankName: data.bankName,
      });
      this.loadingUpdateProfileDealer = false;
    }, error => {
      this.loadingUpdateProfileDealer = false;
    });
  }

  initFormChangePassword() {
    this.formChangePassword = new FormGroup({
      password: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmNewPassword: new FormControl('', [Validators.required])
    }, { validators: this.passwordMatchValidator })
  }

  initFormProfileDealer() {
    this.formProfileDealer = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      cp: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      longitude: new FormControl('', [Validators.required]),
      latitude: new FormControl('', [Validators.required]),
      bankAccount: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
    });
  }

  passwordMatchValidator(control: AbstractControl) {
    const newPassword = control.get('newPassword')?.value;
    const confirmNewPassword = control.get('confirmNewPassword')?.value;

    if (newPassword !== confirmNewPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  openChangePasswordModal(val) {
    this.formChangePassword.reset();
    this.showChangePasswordModal = val;
  }

  async openUpdateProfileDealer(val) {
    if (val) {
      this.onGetDealerByUser();
    }

    this.formProfileDealer.reset();
    this.showUpdateProfileDealer = val;
  }

  toggleSidebarOffcanvasVisible() {
    this._adminLayout.sidebar.offcanvasVisible = !this._adminLayout.sidebar
      .offcanvasVisible;
  }

  toggleSidebar(state) {
    this._adminLayout.sidebar.visible =
      typeof state !== 'undefined' ? state : !this._adminLayout.sidebar.visible;
  }
}
